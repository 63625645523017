import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    height: 300,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  grid: {
    outline: 'none',
  },
}));

export default useStyles;
