import * as actionTypes from './actionTypes';

export const fetchProductList = (params) => ({
  type: actionTypes.FETCH_PRODUCT_LIST,
  params,
});

export const fetchProductListSuccess = (data) => ({
  type: actionTypes.FETCH_PRODUCT_LIST_SUCCESS,
  data,
});

export const fetchProduct = (id) => ({
  type: actionTypes.FETCH_PRODUCT,
  id,
});

export const fetchProductSuccess = (data) => ({
  type: actionTypes.FETCH_PRODUCT_SUCCESS,
  data,
});

export const fetchEanOptions = (actId, params) => ({
  type: actionTypes.FETCH_EAN_OPTIONS,
  actId,
  params,
});

export const fetchEanOptionsSuccess = (data) => ({
  type: actionTypes.FETCH_EAN_OPTIONS_SUCCESS,
  data,
});

export const fetchPligOptions = (actId, params, id) => ({
  type: actionTypes.FETCH_PLIG_OPTIONS,
  id,
  actId,
  params,
});

export const fetchPligOptionsSuccess = (data, id) => ({
  type: actionTypes.FETCH_PLIG_OPTIONS_SUCCESS,
  id,
  data,
});

export const updateEans = (productId, message, data) => ({
  type: actionTypes.UPDATE_EANS,
  productId,
  message,
  data,
});
