import React, { memo } from 'react';

import TextField from './TextField';

const LabelField = memo((props) => <TextField {...props} />);

LabelField.propTypes = {
  ...TextField.propTypes,
};

LabelField.defaultProps = {
  ...TextField.defaultProps,
  name: 'label',
  label: 'app:label',
};

export default LabelField;
