import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: '1.2em',
  },
  star: {
    fontSize: '1.2em',
    marginLeft: 5,
    cursor: 'default',
  },
}));

export default useStyles;
