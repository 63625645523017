import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { RestoreButton } from '../../buttons';
import ManipulationRowAction from './ManipulationRowAction';

const RestoreRowAction = memo((props) => <ManipulationRowAction {...props} />);

RestoreRowAction.propTypes = {
  ...ManipulationRowAction.propTypes,
  component: PropTypes.object,
};

RestoreRowAction.defaultProps = {
  ...ManipulationRowAction.defaultProps,
  component: RestoreButton,
  toFirstPage: true,
};

export default RestoreRowAction;
