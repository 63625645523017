import React, { memo } from 'react';

import TextField from './TextField';

const CountryField = memo((props) => <TextField {...props} />);

CountryField.propTypes = {
  ...TextField.propTypes,
};

CountryField.defaultProps = {
  ...TextField.defaultProps,
  name: 'country',
  label: 'app:country',
};

export default CountryField;
