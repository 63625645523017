import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Form } from 'formik';

import { useAction } from 'app/hooks';

import { SaveButton, RedirectButton } from 'components';
import { actions as campaignsActions } from 'ducks/campaigns';
import { promotionFormValidationSchema } from 'ducks/campaigns/validation';
import { mapPromotionToValues } from 'ducks/campaigns/helpers';
import paths from 'app/paths';

import { PromotionEditGeneral, PromotionEditAction, PromotionEditRules } from '../PromotionEdit';

const PromotionForm = memo(({ promotion }) => {
  return (
    <Form>
      <PromotionEditGeneral promotion={promotion} />
      <PromotionEditRules promotion={promotion} />
      <PromotionEditAction promotion={promotion} />
      <SaveButton label="app:create" />
      <RedirectButton marginLeft label="app:cancel" path={paths.promotions.path} />
    </Form>
  );
});

PromotionForm.propTypes = {
  promotion: PropTypes.object.isRequired,
};

const EnhancedPromotionForm = withFormik({
  displayName: 'PromotionForm',
  validationSchema: promotionFormValidationSchema,
  enableReinitialize: true,
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues: mapPromotionToValues,
  handleSubmit: (values, { props }) => {
    const { onSubmit, promotion } = props;
    onSubmit(promotion.id, values);
  },
})(PromotionForm);

EnhancedPromotionForm.propTypes = {
  promotion: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const PromotionCreate = memo((props) => {
  const createPromotion = useAction(campaignsActions.createPromotion);

  return <EnhancedPromotionForm {...props} onSubmit={createPromotion} />;
});

PromotionCreate.propTypes = {
  promotion: PropTypes.object.isRequired,
};

export default PromotionCreate;
