export default {
  login: {
    path: '/login',
    name: '',
    roles: [],
  },
  logout: {
    path: '/logout',
    name: '',
    roles: [],
  },
  reset: {
    path: '/users/reset',
    name: '',
    roles: [],
  },
};
