import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useAction } from 'app/hooks';
import { actions as clientsActions } from 'ducks/clients';
import { actions as modalsActions, modalTypes } from 'ducks/modals';
import { Button } from 'components';

const ResetClientPassword = memo((props) => {
  const {
    client: {
      user: { id: userId },
    },
  } = props;
  const resetClientPassword = useAction(clientsActions.resetClientPassword);
  const showModal = useAction(modalsActions.showModal);

  const onClick = useCallback(() => {
    showModal(modalTypes.CONFIRMATION_MODAL, {
      titleText: 'auth:resetPassword',
      onOk: () => resetClientPassword(userId),
    });
  }, [userId, resetClientPassword, showModal]);

  return (
    <Button
      variant="outlined"
      size="small"
      label="auth:resetPassword"
      onClick={onClick}
      marginRight
    />
  );
});

ResetClientPassword.propTypes = {
  client: PropTypes.object.isRequired,
};

export default ResetClientPassword;
