import { call, put, all, takeLatest } from 'redux-saga/effects';

import { handleError, startLoading, finishLoading } from 'ducks/app/actions';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as services from './services';

function* fetchTransactionList({ params }) {
  try {
    yield put(startLoading());
    const data = yield call(services.fetchTransactionList, params);
    yield put(actions.fetchTransactionListSuccess(data));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* fetchTransaction({ id }) {
  try {
    yield put(startLoading());
    const data = yield call(services.fetchTransaction, id);
    yield put(actions.fetchTransactionSuccess(data));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

export default function* () {
  yield all([
    yield takeLatest(actionTypes.FETCH_TRANSACTION_LIST, fetchTransactionList),
    yield takeLatest(actionTypes.FETCH_TRANSACTION, fetchTransaction),
  ]);
}
