import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Form } from 'formik';

import {
  TextHeader,
  NameField,
  NumberField,
  PhoneNumberField,
  FirstNameField,
  LastNameField,
  HouseNumberField,
  StreetField,
  PostalCodeField,
  CityField,
  CountryField,
  PriceGroupField,
  SaveButton,
  Panel,
  IntegerField,
} from 'components';

const StoreForm = memo(() => {
  return (
    <Panel defaultExpanded title="app:edit">
      <Form>
        <TextHeader text="app:information" />
        <NameField />
        <NumberField />
        <PriceGroupField />
        <PhoneNumberField />
        <IntegerField label="stores:posAmount" name="newPosAmount" />
        <TextHeader text="app:contact" />
        <FirstNameField />
        <LastNameField />
        <TextHeader text="app:address" />
        <HouseNumberField />
        <StreetField />
        <PostalCodeField />
        <CityField />
        <CountryField />
        <SaveButton />
      </Form>
    </Panel>
  );
});

const EnhancedStoreForm = withFormik({
  displayName: 'StoreForm',
  validationSchema: ({ validationSchema }) => validationSchema,
  enableReinitialize: true,
  validateOnBlur: false,
  mapPropsToValues: ({
    store: {
      name = '',
      priceGroup = '',
      phoneNumber = '',
      number = '',
      posAmount = '',
      newPosAmount = posAmount,
      contact: { firstName = '', lastName = '' } = {},
      address: { houseNumber = '', street = '', postalCode = '', city = '', country = '' } = {},
    },
  }) => ({
    name,
    number,
    posAmount,
    newPosAmount,
    priceGroup,
    phoneNumber,
    firstName,
    lastName,
    houseNumber,
    street,
    postalCode,
    city,
    country,
  }),
  handleSubmit: (values, { props: { onSubmit } }) => {
    onSubmit(values);
  },
})(StoreForm);

EnhancedStoreForm.propTypes = {
  store: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

EnhancedStoreForm.defaultProps = {
  store: {},
};

export default EnhancedStoreForm;
