import React, { memo } from 'react';

import { Filter, CardNumberField, CampaignNameField } from 'components';
import { BarcodeField } from '../fields';

const CouponFilter = memo(() => {
  return (
    <Filter
      initialValues={{
        barcode: '',
        cardNumber: '',
        campaignName: '',
      }}
      mainField={<BarcodeField />}
    >
      <BarcodeField />
      <CardNumberField />
      <CampaignNameField />
    </Filter>
  );
});

export default CouponFilter;
