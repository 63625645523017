import { Map } from 'immutable';

import { createObjectSelector, createPaginatedListSelector } from 'app/selectors';

export const campaignList = createPaginatedListSelector((state) =>
  state.campaigns.get('campaignList', Map()),
);

export const promotionList = createPaginatedListSelector((state) =>
  state.campaigns.get('promotionList', Map()),
);

export const viewingPromotion = createObjectSelector((state) =>
  state.campaigns.get('viewingPromotion', Map()),
);

export const selectNextPromotionListPage = (state) => {
  const { meta } = promotionList(state);
  const { limit, total } = meta;

  const newTotal = total + 1;
  const newPage = Math.ceil(newTotal / limit);

  return newPage;
};
