import React, { memo, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { useAction } from 'app/hooks';
import { actions as modalsActions, modalTypes } from 'ducks/modals';
import { actions as campaignsActions } from 'ducks/campaigns';
import { Panel, SecondaryButton, Caption } from 'components';

const PromotionEditDeactivate = memo(() => {
  const {
    params: { promotionId, campaignId },
  } = useRouteMatch();
  const deactivatePromotion = useAction(campaignsActions.deactivatePromotion);
  const showModal = useAction(modalsActions.showModal);

  const handleDeactivate = useCallback(() => {
    showModal(modalTypes.CONFIRMATION_MODAL, {
      onOk: () => deactivatePromotion(promotionId, campaignId),
    });
  }, [promotionId, campaignId, deactivatePromotion, showModal]);

  return (
    <Panel title="app:deactivate" defaultExpanded>
      <Caption text="app:actionIsNotReversible" color="secondary" />
      <SecondaryButton label="app:deactivate" onClick={handleDeactivate} />
    </Panel>
  );
});

export default PromotionEditDeactivate;
