import React, { memo, useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import intersection from 'lodash/intersection';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import HomeIcon from '@material-ui/icons/Home';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';

import paths from 'app/paths';
import { usePushAction } from 'app/hooks';
import { selectors as authSelectors } from 'ducks/auth';
import BarcodeIcon from 'components/shared/icons/BarcodeIcon';
import useStyles from './Sidebar.styles';

const items = [
  {
    ...paths.clients,
    icon: PersonIcon,
  },
  {
    ...paths.admins,
    icon: GroupIcon,
  },
  {
    ...paths.stores,
    icon: HomeIcon,
  },
  {
    ...paths.campaigns,
    icon: LocalOfferIcon,
  },
  {
    ...paths.products,
    icon: BarcodeIcon,
  },
  {
    ...paths.transactions,
    icon: CreditCardIcon,
  },
  {
    ...paths.coupons,
    icon: ConfirmationNumberIcon,
  },
];

const Sidebar = memo(() => {
  const classes = useStyles();
  const userRoles = useSelector(authSelectors.userRoles);
  const [open, setOpen] = React.useState(false);
  const push = usePushAction();
  const { t } = useTranslation();

  const toggle = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const renderItem = useCallback(
    ({ name, path, icon: Icon }) => {
      return (
        <ListItem key={name} className={classes.item} onClick={() => push(path)} button>
          <ListItemIcon className={classes.itemIcon}>
            <Icon />
          </ListItemIcon>
          <ListItemText primary={t(name)} />
        </ListItem>
      );
    },
    [classes, push, t],
  );

  const allowedItems = useMemo(() => {
    return items.filter((item) => {
      return item.roles.length === 0 || intersection(item.roles, userRoles).length > 0;
    });
  }, [userRoles]);

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx(classes.paper, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.header} onClick={toggle}>
        <IconButton>
          <MenuIcon />
        </IconButton>
        <Typography variant="button" className={classes.headerText}>
          Back-Office {t('app:appName')}
        </Typography>
      </div>
      <List>{allowedItems.map(renderItem)}</List>
    </Drawer>
  );
});

export default Sidebar;
