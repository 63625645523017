import { call, put, all, takeLatest } from 'redux-saga/effects';

import { showSuccess, handleError, startLoading, finishLoading } from 'ducks/app/actions';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as services from './services';

function* fetchCouponList({ params }) {
  try {
    yield put(startLoading());
    const data = yield call(services.fetchCouponList, params);
    yield put(actions.fetchCouponListSuccess(data));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* deactivateCoupon(action) {
  const {
    params: { id },
    onDeactivated,
  } = action;
  try {
    yield put(startLoading());
    yield call(services.deactivateCoupon, id);
    yield put(showSuccess('app:deactivated'));
    yield call(onDeactivated);
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* createCoupon({ data }) {
  try {
    yield put(startLoading());
    yield call(services.createCoupon, data);
    yield put(showSuccess('app:added'));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

export default function* () {
  yield all([
    yield takeLatest(actionTypes.FETCH_COUPON_LIST, fetchCouponList),
    yield takeLatest(actionTypes.DEACTIVATE_COUPON, deactivateCoupon),
    yield takeLatest(actionTypes.CREATE_COUPON, createCoupon),
  ]);
}
