import React, { memo } from 'react';

import { TextField } from 'components';

const CardNumbersField = memo((props) => <TextField {...props} />);

CardNumbersField.propTypes = {
  ...TextField.propTypes,
};

CardNumbersField.defaultProps = {
  ...TextField.defaultProps,
  name: 'cardNumbers',
  label: 'clients:cardNumbers',
  multiline: true,
  rows: 2,
};

export default CardNumbersField;
