import React, { memo, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import map from 'lodash/map';

import { useAction } from 'app/hooks';
import { actions as storesActions, selectors as storesSelectors } from 'ducks/stores';
import { DropdownField } from 'components';

const PriceGroupField = memo((props) => {
  const priceGroups = useSelector(storesSelectors.priceGroups);
  const fetchPriceGroups = useAction(storesActions.fetchPriceGroups);

  useEffect(() => {
    fetchPriceGroups();
  }, [fetchPriceGroups]);

  const items = useMemo(() => {
    return map(priceGroups, (item) => ({ value: item, label: item }));
  }, [priceGroups]);

  return <DropdownField {...props} items={items} />;
});

PriceGroupField.propTypes = {
  ...DropdownField.propTypes,
};

PriceGroupField.defaultProps = {
  ...DropdownField.defaultProps,
  name: 'priceGroup',
  label: 'stores:priceGroup',
};

export default PriceGroupField;
