import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import values from 'lodash/values';
import MaterialBreadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

import paths from 'app/paths';
import { generatePath } from 'app/utils';
import Link from './Link';
import useStyles from './Breadcrumbs.styles';

const generateItems = (match) => {
  return values(paths)
    .filter(({ path }) => match.path.includes(path))
    .map(({ path, ...rest }) => ({
      path: generatePath(path, match.params),
      ...rest,
    }));
};

const Breadcrumbs = memo((props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const match = useRouteMatch();

  const items = props.items ? props.items : generateItems(match);
  const lastItem = items[items.length - 1];
  const restItems = items.slice(0, -1);

  const renderItem = useCallback(
    ({ path, name }) => {
      return (
        <Link key={path} to={path} color="inherit">
          {t(name)}
        </Link>
      );
    },
    [t],
  );

  return (
    <MaterialBreadcrumbs className={classes.root}>
      {restItems.map(renderItem)}
      <Typography color="textPrimary">{t(lastItem.name)}</Typography>
    </MaterialBreadcrumbs>
  );
});

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
};

Breadcrumbs.defaultProps = {
  items: undefined,
};

export default Breadcrumbs;
