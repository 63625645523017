import React, { memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { selectors as clientsSelectors } from 'ducks/clients';
import { Panel, Caption } from 'components';
import EntryTable from './EntryTable';
import EntryDetail from './EntryDetail';

const BalanceHistory = memo(() => {
  const accounts = useSelector(clientsSelectors.clientFirstCardStoreAccounts);
  const [detailEntry, setDetailEntry] = useState(null);

  const renderAccountHistory = useCallback(
    (account) => {
      return (
        <Panel key={account.id} title={account.store.name} defaultExpanded={false}>
          <EntryTable
            account={account}
            setDetailEntry={setDetailEntry}
            show={detailEntry !== null}
          />
          {detailEntry && <EntryDetail detailEntry={detailEntry} setDetailEntry={setDetailEntry} />}
        </Panel>
      );
    },
    [detailEntry],
  );

  return (
    <Panel title="clients:balanceHistory">
      {accounts.map(renderAccountHistory)}
      {isEmpty(accounts) && <Caption text="app:noData" strong={false} />}
    </Panel>
  );
});

export default BalanceHistory;
