export const promotionRuleTypes = {
  ORDER_TOTAL: 'order_total',
  HAS_ACCOUNT: 'has_account',
  CART_HAS_ITEMS: 'cart_has_items',
  ITEM_GROUP_TOTAL: 'item_group_total',
  PER_PLIG_USAGE_LIMIT: 'per_plig_usage_limit',
  PER_CARD_USAGE_LIMIT: 'per_card_usage_limit',
  ITEMS_HAVE_REFERENCE_PRICE: 'items_have_reference_price',
  PER_CARD_DISCOUNT_LIMIT: 'per_card_discount_limit',
};

export const promotionTargetTypes = {
  CARDHOLDERS: 'cardholders',
  NON_CARDHOLDERS: 'non_cardholders',
  ALL_CLIENTS: 'all_clients',
};

export const promotionActionTypes = {
  FIXED_DISCOUNT: 'fixed_discount',
  FIXED_TOTAL_DISCOUNT: 'fixed_total_discount',
  PERCENTAGE_DISCOUNT: 'percentage_discount',
  FIN_DISCOUNT: 'first_item_neutral_discount',
  DYNAMIC_DISCOUNT: 'dynamic_discount',
};

export const promotionDiscountTypes = {
  DELAYED: 'delayed',
  IMMEDIATE: 'immediate',
};

export const promotionTemplateTypes = {
  BASIC1: 'BASIC1',
  BASIC2: 'BASIC2',
  SPECIAL1: 'SPECIAL1',
  TOTAL_DEPENDENT: 'TOTAL_DEPENDENT',
};

export const cartHasItemsMethods = {
  ONE_OF: 'one_of',
  ALL: 'all',
  COUNT: 'count',
};

export const promotionTemplateTypeValues = Object.values(promotionTemplateTypes);
