import React, { forwardRef, useCallback } from 'react';
import * as yup from 'yup';
import { Form, Formik } from 'formik';

import { dateValidator, endDateValidator } from 'app/validators';
import { useAction } from 'app/hooks';
import { actions as storeActions } from 'ducks/stores/index';
import { EndDateField, StartDateField } from 'components/index';
import PropTypes from 'prop-types';

const validationSchema = yup.object().shape({
  startDate: dateValidator().required('app:required'),
  endDate: endDateValidator(),
});

export const GenerateDailyReportForm = forwardRef((props, ref) => {
  const generateReport = useAction(storeActions.generateDailyReport);

  const onSubmit = useCallback(
    (values) => {
      generateReport(props.storeNumber, values);
    },
    [props.storeNumber],
  );

  return (
    <Formik
      initialValues={{
        startDate: '',
        endDate: '',
      }}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnBlur
      innerRef={ref}
      validationSchema={validationSchema}
    >
      {() => (
        <Form>
          <StartDateField />
          <EndDateField label="app:endDateOptional" />
        </Form>
      )}
    </Formik>
  );
});

GenerateDailyReportForm.defaultProps = {
  storeNumber: undefined,
};

GenerateDailyReportForm.propTypes = {
  storeNumber: PropTypes.string,
};

export default React.memo(GenerateDailyReportForm);
