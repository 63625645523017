import React, { memo, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import qs from 'query-string';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import pickBy from 'lodash/pickBy';
import Paper from '@material-ui/core/Paper';

import { usePushAction } from 'app/hooks';
import { selectors as appSelectors, sortTypes } from 'ducks/app';
import { ListContext } from './_constants';
import Table from './Table';
import Pagination from './Pagination';

const paramsToQueryString = ({ filter, sort, page }) => {
  const params = {
    ...pickBy(filter, (value) => value),
  };
  if (page && page > 1) {
    params.page = page;
  }
  if (!isEmpty(sort)) {
    params.sort = map(sort, (value, key) => {
      return value === sortTypes.ASC ? key : `-${key}`;
    }).join(',');
  }
  const queryString = qs.stringify({ ...params });
  return queryString ? `?${queryString}` : '';
};

const queryStringToParams = (queryString) => {
  const queryParams = qs.parse(queryString);
  const { sort = '', page = '1', ...filter } = queryParams;
  const parsedSort = {};
  if (sort) {
    sort.split(',').forEach((item) => {
      if (item[0] === '-') {
        parsedSort[item.substr(1)] = sortTypes.DESC;
      } else {
        parsedSort[item] = sortTypes.ASC;
      }
    });
  }
  return {
    filter,
    sort: parsedSort,
    page: parseInt(page),
  };
};

const List = memo(
  ({
    filterComponent: FilterComponent,
    defaultFilter,
    defaultSort,
    defaultPage,
    onFetchData,
    meta,
    data,
    rowIdSource,
    multiSort,
    rowConfig,
    batchActions,
    toolbarActions,
    fetchWithoutFilter,
  }) => {
    const currentPath = useSelector(appSelectors.currentPath);
    const queryString = useSelector(appSelectors.queryString);
    const push = usePushAction();

    const paramsRef = useRef({
      filter: {},
      sort: {},
      page: meta.page,
    });

    const defaultParamsRef = useRef({
      filter: defaultFilter,
      sort: defaultSort,
      page: defaultPage,
      notEmpty: !isEmpty(defaultFilter) || !isEmpty(defaultSort) || defaultPage > 1,
    });

    const pushDefaultParams = useCallback(() => {
      const newQueryString = paramsToQueryString(defaultParamsRef.current);
      push(`${currentPath}${newQueryString}`);
    }, [currentPath, push]);

    const updateParams = useCallback(
      (key, value) => {
        const params = paramsRef.current;
        params[key] = value;
        params.page = ['filter', 'sort'].includes(key) ? 1 : params.page;
        const newQueryString = paramsToQueryString(params);
        push(`${currentPath}${newQueryString}`);
      },
      [currentPath, push],
    );

    const updateFilter = useCallback(
      (value) => {
        updateParams('filter', value);
      },
      [updateParams],
    );

    const updateSort = useCallback(
      (value) => {
        updateParams('sort', value);
      },
      [updateParams],
    );

    const updatePage = useCallback(
      (value) => {
        updateParams('page', value);
      },
      [updateParams],
    );

    const refreshData = useCallback(() => {
      paramsRef.current = queryStringToParams(queryString);
      onFetchData(paramsRef.current);
    }, [queryString, onFetchData]);

    const forceFirstPage = useCallback(() => {
      if (meta.page === 1) {
        refreshData();
      } else {
        updatePage(1);
      }
    }, [meta, refreshData, updatePage]);

    useEffect(() => {
      if (!queryString && defaultParamsRef.current.notEmpty) {
        pushDefaultParams();
      } else if (fetchWithoutFilter || queryString) {
        refreshData();
      }
    }, [queryString, pushDefaultParams, refreshData, fetchWithoutFilter]);

    const contextData = {
      data,
      meta,
      rowConfig,
      rowIdSource,
      filter: paramsRef.current.filter,
      sort: paramsRef.current.sort,
      multiSort,
      batchActions,
      toolbarActions,
      updateFilter,
      updateSort,
      updatePage,
      refreshData,
      forceFirstPage,
    };

    return (
      <ListContext.Provider value={contextData}>
        <div>
          {FilterComponent && <FilterComponent />}
          <Paper>
            <Table />
            <Pagination />
          </Paper>
        </div>
      </ListContext.Provider>
    );
  },
);

List.propTypes = {
  filterComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  defaultFilter: PropTypes.object,
  defaultSort: PropTypes.object,
  defaultPage: PropTypes.number,
  onFetchData: PropTypes.func.isRequired,
  meta: PropTypes.shape({
    page: PropTypes.number,
    limit: PropTypes.number,
    total: PropTypes.number,
  }),
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowIdSource: PropTypes.string,
  multiSort: PropTypes.bool,
  rowConfig: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string,
      source: PropTypes.string,
      render: PropTypes.func,
      component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
      sortable: PropTypes.bool,
    }),
  ).isRequired,
  batchActions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string,
      confirm: PropTypes.bool,
      callback: PropTypes.func.isRequired,
    }),
  ),
  toolbarActions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string,
      confirm: PropTypes.bool,
      callback: PropTypes.func.isRequired,
      icon: PropTypes.func.object,
    }),
  ),
  fetchWithoutFilter: PropTypes.bool,
};

List.defaultProps = {
  filterComponent: undefined,
  defaultFilter: {},
  defaultSort: {},
  defaultPage: 1,
  meta: {
    page: 1,
    limit: 10,
    total: 0,
  },
  rowIdSource: 'id',
  multiSort: false,
  batchActions: null,
  toolbarActions: null,
  fetchWithoutFilter: true,
};

export default List;
