import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    top: 60,
    right: 15,
    whiteSpace: 'pre-wrap',
  },
  success: {
    backgroundColor: '#93c54b',
  },
  error: {
    backgroundColor: '#d9534f',
  },
  close: {
    padding: theme.spacing(0.5),
  },
}));

export default useStyles;
