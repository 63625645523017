import { Map } from 'immutable';
import { createSelector } from 'reselect';

import { createObjectSelector, createPaginatedListSelector } from 'app/selectors';

export const clientList = createPaginatedListSelector((state) =>
  state.clients.get('clientList', Map()),
);

export const viewingClient = createObjectSelector((state) =>
  state.clients.get('viewingClient', Map()),
);

export const balanceHistory = createObjectSelector((state) =>
  state.clients.get('balanceHistory', Map()),
);

export const accountBalanceHistory = (state) => {
  return (accountId) => {
    const history = balanceHistory(state)[accountId];
    return history || { meta: { page: 1, pages: 1 }, items: [] };
  };
};

export const clientStoreAccounts = createSelector(
  [(state) => state.clients.get('viewingClient', Map())],
  (data) => {
    const { cards } = data.toJS();
    return cards.reduce((result, card) => {
      const accounts = card.accounts.filter(({ store }) => store.id);
      return result.concat(accounts);
    }, []);
  },
);

export const clientFirstCardStoreAccounts = createSelector(
  [(state) => state.clients.get('viewingClient', Map())],
  (data) => {
    const { cards } = data.toJS();
    return cards[0] ? cards[0].accounts.filter(({ store }) => store.id) : [];
  },
);

export const expiredBalance = createObjectSelector((state) =>
  state.clients.get('expiredBalance', Map()),
);

export const accountExpiredBalance = (state) => {
  return (accountId) => {
    const allExpiredBalances = expiredBalance(state);
    return allExpiredBalances[accountId] ? allExpiredBalances[accountId].amount : null;
  };
};
