import { Map } from 'immutable';

import { createObjectSelector } from 'app/selectors';

export const currentPath = (state) => {
  return state.router.location.pathname || '';
};

export const queryString = (state) => {
  return state.router.location.search || '';
};

export const queryParams = (state) => {
  return state.router.location.query || {};
};

export const notification = createObjectSelector((state) => state.app.get('notification', Map()));

export const loading = (state) => {
  return state.app.get('loading', false);
};

export const actLoadingId = (state) => {
  return state.app.get('actLoadingId', '');
};
