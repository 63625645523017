import React, { memo } from 'react';

import TextField from './TextField';

const NumberField = memo((props) => <TextField {...props} />);

NumberField.propTypes = {
  ...TextField.propTypes,
};

NumberField.defaultProps = {
  ...TextField.defaultProps,
  name: 'number',
  label: 'app:number',
};

export default NumberField;
