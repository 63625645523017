import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { useAction } from 'app/hooks';
import { formatEuro } from 'app/formatters';
import { actions as modalsActions, modalTypes } from 'ducks/modals';
import { selectors as authSelectors } from 'ducks/auth';
import { selectors as clientsSelectors } from 'ducks/clients';
import { Panel, EditButton } from 'components/index';

const BalanceEdit = memo((props) => {
  const { t } = useTranslation();
  const {
    client: { id: clientId },
  } = props;
  const isAppAdmin = useSelector(authSelectors.isAppAdmin);
  const accounts = useSelector(clientsSelectors.clientStoreAccounts);
  const showModal = useAction(modalsActions.showModal);

  const handleUpdate = useCallback(
    (accountId) => {
      showModal(modalTypes.UPDATE_BALANCE_MODAL, { accountId, clientId });
    },
    [clientId, showModal],
  );

  const renderItem = useCallback(
    (data) => {
      const { id, cardNumber, store, balance } = data;
      return (
        <TableRow key={id}>
          <TableCell>{cardNumber}</TableCell>
          <TableCell>{store.name}</TableCell>
          <TableCell>{formatEuro(balance)}</TableCell>
          <TableCell>
            {isAppAdmin && <EditButton onClick={() => handleUpdate(id)} size="small" />}
          </TableCell>
        </TableRow>
      );
    },
    [handleUpdate, isAppAdmin],
  );

  return (
    <Panel title="clients:editBalance" defaultExpanded={false}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t('clients:cardNumber')}</TableCell>
            <TableCell>{t('stores:store')}</TableCell>
            <TableCell>{t('clients:balance')}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>{accounts.map(renderItem)}</TableBody>
      </Table>
    </Panel>
  );
});

BalanceEdit.propTypes = {
  client: PropTypes.object.isRequired,
};

export default BalanceEdit;
