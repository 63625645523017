import React from 'react';
import { useSelector } from 'react-redux';

import { selectors as authSelectors } from 'ducks/auth';
import ProtectedRoute from './ProtectedRoute';

const NonAuthRoute = function (props) {
  const { isAuthenticated } = useSelector(authSelectors.authStatus);

  if (isAuthenticated === false) {
    return <ProtectedRoute {...props} />;
  }

  if (isAuthenticated === true) {
    return <ProtectedRoute {...props} canShow={false} />;
  }

  return null;
};

NonAuthRoute.propTypes = {
  ...ProtectedRoute.propTypes,
};

export default NonAuthRoute;
