import { Map } from 'immutable';

import { createObjectSelector, createPaginatedListSelector } from 'app/selectors';

export const transactionList = createPaginatedListSelector((state) =>
  state.transactions.get('transactionList', Map()),
);

export const viewingTransaction = createObjectSelector((state) =>
  state.transactions.get('viewingTransaction', Map()),
);
