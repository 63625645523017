export const mapPlig = (data) => {
  const {
    id,
    product: { label } = {},
    value,
    eansCount,
    updatedAt,
    referencePrices = [],
    eans = [],
  } = data;

  return {
    id,
    label,
    value,
    eansCount,
    updatedAt,
    referencePrices,
    eans: eans.map(({ value: cValue }) => ({ value: cValue })),
  };
};

export const mapEan = (data) => {
  const { id, value } = data;
  return {
    id,
    value,
  };
};
