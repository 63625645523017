import paths from 'app/paths';
import ProductView from 'components/products/ProductView/ProductView';
import ProductList from 'components/products/ProductList/ProductList';

export default [
  {
    path: paths.productsView.path,
    roles: paths.productsView.roles,
    component: ProductView,
  },
  {
    path: paths.products.path,
    roles: paths.products.roles,
    component: ProductList,
  },
];
