import { createTheme } from '@material-ui/core';

const theme = createTheme({
  palette: {
    background: {
      default: '#e5e5e5',
    },
  },
  typography: {
    fontSize: 12,
    h5: {
      marginTop: 10,
      marginBottom: 15,
    },
  },
});

export default theme;
