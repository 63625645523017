import qs from 'query-string';
import cloneDeep from 'lodash/cloneDeep';
import isObject from 'lodash/isObject';
import isPlainObject from 'lodash/isPlainObject';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import keys from 'lodash/keys';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';

export const toCamelCase = (object) => {
  if (!isObject(object)) {
    return object;
  }

  let camelCaseObject = cloneDeep(object);

  if (isArray(camelCaseObject)) {
    return map(camelCaseObject, toCamelCase);
  }
  camelCaseObject = mapKeys(camelCaseObject, (value, key) => {
    return camelCase(key);
  });

  // Recursively apply throughout object
  return mapValues(camelCaseObject, (value) => {
    if (isPlainObject(value)) {
      return toCamelCase(value);
    }
    if (isArray(value)) {
      return map(value, toCamelCase);
    }
    return value;
  });
};

export const toSnakeCase = (object) => {
  if (!isObject(object)) {
    return object;
  }

  let snakeCaseObject = cloneDeep(object);

  if (isArray(snakeCaseObject)) {
    return map(snakeCaseObject, toSnakeCase);
  }
  snakeCaseObject = mapKeys(snakeCaseObject, (value, key) => {
    return snakeCase(key);
  });

  // Recursively apply throughout object
  return mapValues(snakeCaseObject, (value) => {
    if (isPlainObject(value)) {
      return toSnakeCase(value);
    }
    if (isArray(value)) {
      return map(value, toSnakeCase);
    }
    return value;
  });
};

export const randomString = () => {
  return `f${(~~(Math.random() * 1e8)).toString(16)}`;
};

export const joinNotEmpty = (items, delimiter = ' ') => {
  return items.filter(Boolean).join(delimiter);
};

export const generatePath = (path, params = {}, queryParams = {}) => {
  let calculatedPath = path;

  if (!isEmpty(params)) {
    calculatedPath = keys(params).reduce((result, param) => {
      return result.replace(`:${param}`, params[param]);
    }, calculatedPath);
  }

  if (!isEmpty(queryParams)) {
    calculatedPath += `?${qs.stringify(queryParams)}`;
  }

  return calculatedPath;
};
