import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

import paths from 'app/paths';

const ProtectedRoute = function ({ component: Component, canShow, redirectTo, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (canShow ? <Component {...props} /> : <Redirect to={redirectTo} />)}
    />
  );
};

ProtectedRoute.propTypes = {
  canShow: PropTypes.bool,
  redirectTo: PropTypes.string,
  ...Route.propTypes,
};

ProtectedRoute.defaultProps = {
  canShow: true,
  redirectTo: paths.stores.path,
};

export default ProtectedRoute;
