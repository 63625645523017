import * as actionTypes from './actionTypes';

export const fetchCouponList = (params) => ({
  type: actionTypes.FETCH_COUPON_LIST,
  params,
});

export const fetchCouponListSuccess = (data) => ({
  type: actionTypes.FETCH_COUPON_LIST_SUCCESS,
  data,
});

export const deactivateCoupon = (params, onDeactivated) => ({
  type: actionTypes.DEACTIVATE_COUPON,
  params,
  onDeactivated,
});

export const createCoupon = (data) => ({
  type: actionTypes.CREATE_COUPON,
  data,
});
