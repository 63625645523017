import paths from 'app/paths';
import CouponAdd from 'components/coupons/CouponView/CouponAdd';
import CouponList from 'components/coupons/CouponList/CouponList';

export default [
  {
    path: paths.couponsAdd.path,
    roles: paths.couponsAdd.roles,
    component: CouponAdd,
  },
  {
    path: paths.coupons.path,
    roles: paths.coupons.roles,
    component: CouponList,
  },
];
