import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import TextField from './TextField';

const EmailField = memo(({ isUserRelated, ...rest }) => {
  const { t } = useTranslation();
  return (
    <TextField
      {...rest}
      label="auth:email"
      helperText={
        isUserRelated !== undefined
          ? t(isUserRelated ? 'clients:userEmailChange' : 'clients:clientEmailChange')
          : ''
      }
    />
  );
});

EmailField.propTypes = {
  ...TextField.propTypes,
  isUserRelated: PropTypes.bool.isRequired,
};

EmailField.defaultProps = {
  ...TextField.defaultProps,
  name: 'email',
  label: 'auth:email',
};

export default EmailField;
