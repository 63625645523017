import React, { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import MuiButton from '@material-ui/core/Button';

import useStyles from './Button.styles';

const Button = memo((props) => {
  const { children, label, marginLeft, marginRight, ...rest } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const content = label ? t(label) : children;

  return (
    <MuiButton
      className={clsx(
        classes.root,
        marginLeft && classes.marginLeft,
        marginRight && classes.marginRight,
      )}
      {...rest}
    >
      {content}
    </MuiButton>
  );
});

Button.propTypes = {
  ...Button.propTypes,
  children: PropTypes.node,
  marginLeft: PropTypes.bool,
  marginRight: PropTypes.bool,
};

Button.defaultProps = {
  ...Button.defaultProps,
  children: undefined,
  label: undefined,
  marginLeft: false,
  marginRight: false,
};

export default Button;
