import PropTypes from 'prop-types';
import React from 'react';

// eslint-disable-next-line no-unused-vars
import { getRuleName } from 'ducks/campaigns/helpers';

import { promotionActionTypes, promotionRuleTypes } from 'ducks/campaigns/enums';
import { Panel, AmountField } from 'components';

import PerCardUsageLimitRule from './SpecialRules/PerCardUsageLimitRule';
import CartHasItemsRule from './SpecialRules/CartHasItemsRule';

const PromotionEditRules = function ({ promotion }) {
  return (
    <>
      {promotion.rules[promotionRuleTypes.ORDER_TOTAL] && (
        <Panel
          title={`campaigns:promotionRuleTypesBrief:${promotionRuleTypes.ORDER_TOTAL}`}
          defaultExpanded
        >
          <AmountField name="orderTotal" label="campaigns:orderTotalAmount" />
        </Panel>
      )}
      {promotion.rules[promotionRuleTypes.PER_CARD_DISCOUNT_LIMIT] && (
        <Panel
          title={`campaigns:promotionRuleTypesBrief:${promotionRuleTypes.PER_CARD_DISCOUNT_LIMIT}`}
          defaultExpanded
        >
          <AmountField
            name={`rules.${promotionRuleTypes.PER_CARD_DISCOUNT_LIMIT}.configuration.limit`}
            label="campaigns:limitPerCard"
          />
        </Panel>
      )}
      <CartHasItemsRule />
      {!promotion.actions[promotionActionTypes.DYNAMIC_DISCOUNT] && <PerCardUsageLimitRule />}
    </>
  );
};

PromotionEditRules.propTypes = {
  promotion: PropTypes.object.isRequired,
};

export default React.memo(PromotionEditRules);
