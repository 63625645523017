import { fromJS } from 'immutable';

import * as actionTypes from './actionTypes';

const initialState = fromJS({
  adminList: {
    order: [],
    items: {},
    meta: { page: 1, limit: 10, total: 0 },
  },
  editingAdmin: {},
  adminGroups: {
    availableGroups: {},
    allGroups: {},
  },
});

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ADMIN_LIST_SUCCESS:
      return state.merge(
        fromJS({
          adminList: action.data,
        }),
      );

    case actionTypes.FETCH_ADMIN_SUCCESS:
      return state.merge(
        fromJS({
          editingAdmin: action.data,
        }),
      );

    case actionTypes.FETCH_ADMIN_GROUPS_SUCCESS:
      return state.merge(
        fromJS({
          adminGroups: action.data,
        }),
      );

    default:
      return state;
  }
};
