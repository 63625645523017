import { fromJS } from 'immutable';

import * as actionTypes from './actionTypes';
import { notificationTypes } from './enums';

const initialState = fromJS({
  notification: {
    type: notificationTypes.SUCCESS,
    message: '',
  },
  loading: false,
  actLoadingId: '',
});

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SHOW_SUCCESS:
      return state.mergeDeep(
        fromJS({
          notification: {
            type: notificationTypes.SUCCESS,
            message: action.message || 'app:success',
          },
        }),
      );

    case actionTypes.SHOW_ERROR:
      return state.mergeDeep(
        fromJS({
          notification: {
            type: notificationTypes.ERROR,
            message: action.message || 'app:error',
          },
        }),
      );

    case actionTypes.CLEAR_NOTIFICATION:
      return state.mergeDeep(
        fromJS({
          notification: { message: '' },
        }),
      );

    case actionTypes.START_LOADING:
      return state.merge({ loading: true });

    case actionTypes.FINISH_LOADING:
      return state.merge({ loading: false });

    case actionTypes.START_ACT_LOADING:
      return state.merge({ actLoadingId: action.actId });

    case actionTypes.FINISH_ACT_LOADING:
      return state.merge({ actLoadingId: '' });

    default:
      return state;
  }
}
