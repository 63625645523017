import React, { memo, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { useAction } from 'app/hooks';
import { actions as modalsActions, modalTypes } from 'ducks/modals';
import { ListContext } from './_constants';

const BatchActions = memo(({ selectedItems }) => {
  const { batchActions } = useContext(ListContext);
  const showModal = useAction(modalsActions.showModal);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();

  const handleOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const performAction = useCallback(
    ({ confirm, label, callback }) => {
      if (confirm === false) {
        callback(selectedItems);
      } else {
        showModal(modalTypes.CONFIRMATION_MODAL, {
          titleText: label,
          hideAfterOk: true,
          onOk: () => callback(selectedItems),
        });
      }
      handleClose();
    },
    [selectedItems, showModal, handleClose],
  );

  const renderItem = useCallback(
    (action) => {
      const { name, label } = action;
      return (
        <MenuItem key={name} onClick={() => performAction(action)}>
          {t(label)}
        </MenuItem>
      );
    },
    [performAction, t],
  );

  return (
    <div>
      <Button onClick={handleOpen} variant="outlined">
        {t('app:selected')}
      </Button>
      <Menu
        id="batch-actions"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {batchActions.map(renderItem)}
      </Menu>
    </div>
  );
});

BatchActions.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default BatchActions;
