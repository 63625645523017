import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

import { entryTypes } from 'ducks/clients/enums';
import { ArrowBackButton, TransactionInfo } from 'components';

const getTransactionId = (entry) => {
  const { type, item } = entry;
  switch (type) {
    case entryTypes.ORDER:
      return parseInt(item.id);
    case entryTypes.ENTRY:
      return parseInt(item.orders[0].id);
    default:
      return null;
  }
};

const EntryDetail = memo((props) => {
  const { detailEntry, setDetailEntry } = props;
  return (
    <>
      <Box mb={2}>
        <ArrowBackButton onClick={() => setDetailEntry(null)} />
      </Box>
      <TransactionInfo transactionId={getTransactionId(detailEntry)} entry={detailEntry} />
    </>
  );
});

EntryDetail.propTypes = {
  detailEntry: PropTypes.object.isRequired,
  setDetailEntry: PropTypes.func.isRequired,
};

export default EntryDetail;
