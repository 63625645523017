import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    margin: '0 auto 30px auto',
  },
  head: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    width: 500,
  },
  iconButton: {
    padding: 10,
  },
  arrowButton: {
    marginLeft: 4,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  body: {
    position: 'absolute',
    marginTop: 1,
    zIndex: 100,
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.175)',
    padding: theme.spacing(1),
    width: 500,
    visibility: 'hidden',
  },
  bodyVisible: {
    visibility: 'visible',
  },
}));

export default useStyles;
