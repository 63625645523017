import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Panel } from 'components';
import RulesCell from '../PromotionList/RulesCell';

const PromotionDetailsRules = memo((props) => {
  const { promotion } = props;
  return (
    <Panel title="campaigns:rules" defaultExpanded>
      <RulesCell item={promotion} />
    </Panel>
  );
});

PromotionDetailsRules.propTypes = {
  promotion: PropTypes.object.isRequired,
};

export default PromotionDetailsRules;
