import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';

const RemoveButton = memo(({ title, color, ...rest }) => {
  const { t } = useTranslation();
  return (
    <IconButton title={t(title)} {...rest}>
      <CancelIcon color={color} />
    </IconButton>
  );
});

RemoveButton.propTypes = {
  ...IconButton.propTypes,
};

RemoveButton.defaultProps = {
  ...IconButton.defaultProps,
  title: 'app:remove',
  color: 'error',
};

export default RemoveButton;
