import React, { memo } from 'react';

import TextField from './TextField';

const UsernameField = memo((props) => <TextField {...props} />);

UsernameField.propTypes = {
  ...TextField.propTypes,
};

UsernameField.defaultProps = {
  ...TextField.defaultProps,
  name: 'username',
  label: 'app:username',
};

export default UsernameField;
