import React from 'react';
import PropTypes from 'prop-types';

import { Breadcrumbs } from 'components';
import {
  promotionActionTypes,
  promotionRuleTypes,
  promotionTargetTypes,
  promotionTemplateTypes,
  promotionTemplateTypeValues,
} from 'ducks/campaigns/enums';

import PromotionCreate from './PromotionCreate';
import PromotionCreateSpecial from './PromotionCreateSpecial';
import PromotionCreateTotalDependent from './PromotionCreateTotalDependent';

const getRulesByPromotionType = (type) => {
  switch (type) {
    case promotionTemplateTypes.BASIC1:
    case promotionTemplateTypes.SPECIAL1:
      return {
        [promotionRuleTypes.HAS_ACCOUNT]: {
          configuration: {},
          type: promotionRuleTypes.HAS_ACCOUNT,
        },
        [promotionRuleTypes.CART_HAS_ITEMS]: {
          id: 0,
          type: promotionRuleTypes.CART_HAS_ITEMS,
          pligs: [],
          configuration: {
            method: 'one_of',
            threshold: 1,
          },
        },
        [promotionRuleTypes.ITEMS_HAVE_REFERENCE_PRICE]: {
          configuration: {},
          type: promotionRuleTypes.ITEMS_HAVE_REFERENCE_PRICE,
        },
      };
    case promotionTemplateTypes.BASIC2:
      return {
        [promotionRuleTypes.HAS_ACCOUNT]: {
          configuration: {},
          type: promotionRuleTypes.HAS_ACCOUNT,
        },
        [promotionRuleTypes.ORDER_TOTAL]: {
          configuration: {},
          type: promotionRuleTypes.ORDER_TOTAL,
        },
      };
    case promotionTemplateTypes.TOTAL_DEPENDENT:
      return {
        [promotionRuleTypes.PER_CARD_DISCOUNT_LIMIT]: {
          configuration: {},
          type: promotionRuleTypes.PER_CARD_DISCOUNT_LIMIT,
        },
        [promotionRuleTypes.CART_HAS_ITEMS]: {
          configuration: {
            method: 'one_of',
            threshold: 1,
          },
          type: promotionRuleTypes.CART_HAS_ITEMS,
        },
      };
    default:
      return {};
  }
};

const getActionByPromotionType = (type) => {
  switch (type) {
    case promotionTemplateTypes.BASIC1:
      return {
        [promotionActionTypes.FIXED_DISCOUNT]: {
          configuration: {},
          type: promotionActionTypes.FIXED_DISCOUNT,
        },
      };
    case promotionTemplateTypes.BASIC2:
      return {
        [promotionActionTypes.FIXED_TOTAL_DISCOUNT]: {
          configuration: {},
          type: promotionActionTypes.FIXED_TOTAL_DISCOUNT,
        },
      };
    default:
      return {};
  }
};

export const PromotionAdd = function ({ match }) {
  const { type, campaignId } = match.params || {};

  const [promotion] = React.useState(() => ({
    id: +campaignId,
    startDate: '',
    endDate: '',
    target: '',
    rules: getRulesByPromotionType(type),
    actions: getActionByPromotionType(type),
  }));

  if (!promotionTemplateTypeValues.includes(type)) {
    return <Breadcrumbs />;
  }

  switch (type) {
    case promotionTemplateTypes.SPECIAL1:
      return (
        <div>
          <Breadcrumbs />
          <PromotionCreateSpecial promotion={promotion} />
        </div>
      );
    case promotionTemplateTypes.TOTAL_DEPENDENT:
      promotion.target = promotionTargetTypes.CARDHOLDERS;
      return (
        <div>
          <Breadcrumbs />
          <PromotionCreateTotalDependent promotion={promotion} />
        </div>
      );
    default:
      return (
        <div>
          <Breadcrumbs />
          <PromotionCreate promotion={promotion} />
        </div>
      );
  }
};

PromotionAdd.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
};

export default React.memo(PromotionAdd);
