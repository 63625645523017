import React, { memo } from 'react';

import { Filter, NameField, NumberField, PostalCodeField, PriceGroupField } from 'components';

const StoreFilter = memo(() => {
  return (
    <Filter
      initialValues={{
        name: '',
        number: '',
        priceGroup: '',
        postalCode: '',
      }}
      mainField={<NameField />}
    >
      <NameField />
      <NumberField />
      <PriceGroupField allowEmpty />
      <PostalCodeField />
    </Filter>
  );
});

export default StoreFilter;
