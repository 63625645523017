import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import values from 'lodash/values';

import { formatEuro, formatPercent } from 'app/formatters';
import { promotionActionTypes } from 'ducks/campaigns/enums';

const filterAction = (action) => {
  return [
    promotionActionTypes.FIXED_DISCOUNT,
    promotionActionTypes.PERCENTAGE_DISCOUNT,
    promotionActionTypes.FIXED_TOTAL_DISCOUNT,
    promotionActionTypes.FIN_DISCOUNT,
  ].includes(action.type);
};

const DiscountCell = memo((props) => {
  const {
    item: { actions },
  } = props;

  const renderAction = useCallback((data) => {
    const {
      id,
      configuration: { amount },
      type,
    } = data;

    let formattedAmount = formatEuro(amount);
    if (type === promotionActionTypes.PERCENTAGE_DISCOUNT) {
      formattedAmount = formatPercent(amount);
    }

    return <div key={id}>{formattedAmount}</div>;
  }, []);

  return <>{values(actions).filter(filterAction).map(renderAction)}</>;
});

DiscountCell.propTypes = {
  item: PropTypes.object.isRequired,
};

export default DiscountCell;
