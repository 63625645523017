import React from 'react';

import { Panel, Caption, AmountField } from 'components';

const ActionEdit = function () {
  return (
    <>
      <Panel title="campaigns:totalDependent:panel" defaultExpanded>
        <Caption text="campaigns:totalDependent:limit1" />
        <AmountField name="total1" label="campaigns:orderTotalAmount" />
        <AmountField name="discount1" label="campaigns:discountAmountEuro" />

        <Caption text="campaigns:totalDependent:limit2" />
        <AmountField name="total2" label="campaigns:orderTotalAmount" />
        <AmountField name="discount2" label="campaigns:discountAmountEuro" />

        <Caption text="campaigns:totalDependent:limit3" />
        <AmountField name="total3" label="campaigns:orderTotalAmount" />
        <AmountField name="discount3" label="campaigns:discountAmountEuro" />

        <Caption text="campaigns:totalDependent:limit4" />
        <AmountField name="total4" label="campaigns:orderTotalAmount" />
        <AmountField name="discount4" label="campaigns:discountAmountEuro" />

        <Caption text="campaigns:totalDependent:limit5" />
        <AmountField name="total5" label="campaigns:orderTotalAmount" />
        <AmountField name="discount5" label="campaigns:discountAmountEuro" />

        <Caption text="campaigns:totalDependent:limit6" />
        <AmountField name="total6" label="campaigns:orderTotalAmount" />
        <AmountField name="discount6" label="campaigns:discountAmountEuro" />
      </Panel>
    </>
  );
};

const PromotionEditAction = function (promotion) {
  return <ActionEdit promotion={promotion} />;
};

export default React.memo(PromotionEditAction);
