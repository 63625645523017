import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';

import { formatEuro } from 'app/formatters';
import Typography from '@material-ui/core/Typography';

const calculateAdjustmentsTotal = (items, property) => {
  return items.reduce((itemRes, item) => {
    return (
      itemRes +
      item[property].reduce((adjRes, adj) => {
        return adjRes + adj.amount;
      }, 0)
    );
  }, 0);
};

const Adjustments = memo((props) => {
  const { items, neutral } = props;
  const property = neutral ? 'neutralAdjustments' : 'notNeutralAdjustments';
  const { t } = useTranslation();

  const total = useMemo(() => {
    return calculateAdjustmentsTotal(items, property);
  }, [items, property]);

  const renderItem = useCallback(
    (item) => {
      return item[property].map((adjustment) => {
        return (
          <TableRow key={adjustment.id}>
            <TableCell>{item.plig.value}</TableCell>
            <TableCell>{item.label}</TableCell>
            <TableCell>{adjustment.description}</TableCell>
            <TableCell>{formatEuro(adjustment.amount)}</TableCell>
          </TableRow>
        );
      });
    },
    [property],
  );

  const renderedItems = useMemo(() => {
    const result = items.map(renderItem).filter((item) => !isEmpty(item));
    return isEmpty(result) ? null : result;
  }, [items, renderItem]);

  if (!renderedItems) {
    return (
      <Typography variant="h5" align="center" color="textSecondary">
        {t('app:noData')}
      </Typography>
    );
  }

  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t('products:plig')}</TableCell>
            <TableCell>{t('transactions:article')}</TableCell>
            <TableCell>{t('campaigns:promotion')}</TableCell>
            <TableCell>{t('campaigns:discount')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{renderedItems}</TableBody>
      </Table>
      <Box align="right" m={1}>
        {t('app:total')}: {formatEuro(total)}
      </Box>
    </>
  );
});

Adjustments.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  neutral: PropTypes.bool.isRequired,
};

Adjustments.defaultProps = {
  items: [],
};

export default Adjustments;
