import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { promotionDiscountTypes } from 'ducks/campaigns/enums';
import { DropdownField } from 'components';

const DiscountTypeDropdownField = memo((props) => {
  const { t } = useTranslation();

  const items = useMemo(() => {
    return [
      {
        value: promotionDiscountTypes.DELAYED,
        label: t('campaigns:delayed'),
      },
      {
        value: promotionDiscountTypes.IMMEDIATE,
        label: t('campaigns:immediate'),
      },
    ];
  }, [t]);

  return <DropdownField {...props} items={items} />;
});

DiscountTypeDropdownField.propTypes = {
  ...DropdownField.propTypes,
};

DiscountTypeDropdownField.defaultProps = {
  ...DropdownField.defaultProps,
  name: 'discountType',
  label: 'campaigns:discountType',
};

export default DiscountTypeDropdownField;
