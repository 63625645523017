export const FETCH_CLIENT_LIST = 'clients/FETCH_CLIENT_LIST';
export const FETCH_CLIENT_LIST_SUCCESS = 'clients/FETCH_CLIENT_LIST_SUCCESS';

export const FETCH_BEST_CLIENTS = 'clients/FETCH_BEST_CLIENTS';

export const FETCH_CLIENT = 'clients/FETCH_CLIENT';
export const FETCH_CLIENT_SUCCESS = 'clients/FETCH_CLIENT_SUCCESS';

export const RESET_CLIENT_PASSWORD = 'clients/RESET_CLIENT_PASSWORD';

export const CHANGE_CLIENT_EMAIL = 'clients/CHANGE_CLIENT_EMAIL';

export const CHANGE_CLIENT_EMAIL_WITHOUT_USER = 'clients/CHANGE_CLIENT_EMAIL_WITHOUT_USER';

export const UPDATE_CLIENT_PROFILE = 'clients/UPDATE_CLIENT_PROFILE';

export const ACTIVATE_CLIENT = 'clients/ACTIVATE_CLIENT';

export const DEACTIVATE_CLIENT = 'clients/DEACTIVATE_CLIENT';

export const ACTIVATE_CARD = 'clients/ACTIVATE_CARD';

export const DEACTIVATE_CARD = 'clients/DEACTIVATE_CARD';

export const FETCH_BALANCE_HISTORY = 'clients/FETCH_BALANCE_HISTORY';

export const FETCH_BALANCE_HISTORY_SUCCESS = 'clients/FETCH_BALANCE_HISTORY_SUCCESS';

export const UPDATE_BALANCE = 'clients/UPDATE_BALANCE';

export const EXPORT_PERSONAL_DATA = 'clients/EXPORT_PERSONAL_DATA';
export const EXPORT_PERSONAL_DATA_SUCCESS = 'clients/EXPORT_PERSONAL_DATA_SUCCESS';
export const EXPORT_PERSONAL_DATA_FAILURE = 'clients/EXPORT_PERSONAL_DATA/FAILURE';

export const REMOVE_PERSONAL_DATA = 'clients/REMOVE_PERSONAL_DATA';
export const REMOVE_PERSONAL_DATA_SUCCESS = 'clients/REMOVE_PERSONAL_DATA_SUCCESS';
export const REMOVE_PERSONAL_DATA_FAILURE = 'clients/REMOVE_PERSONAL_DATA/FAILURE';
export const UPLOAD_CARDS_FILE = 'clients/UPLOAD_CARDS_FILE';
export const GET_EXPIRED_BALANCE = 'clients/GET_EXPIRED_BALANCE';
export const GET_EXPIRED_BALANCE_SUCCESS = 'clients/GET_EXPIRED_BALANCE_SUCCESS';
export const SUBTRACT_EXPIRED_BONUSES = 'clients/SUBTRACT_EXPIRED_BONUSES';
