import * as actionTypes from './actionTypes';

export const fetchCampaignList = (params) => ({
  type: actionTypes.FETCH_CAMPAIGN_LIST,
  params,
});

export const fetchCampaignListSuccess = (data) => ({
  type: actionTypes.FETCH_CAMPAIGN_LIST_SUCCESS,
  data,
});

export const fetchPromotionList = (params) => ({
  type: actionTypes.FETCH_PROMOTION_LIST,
  params,
});

export const fetchPromotionListSuccess = (data) => ({
  type: actionTypes.FETCH_PROMOTION_LIST_SUCCESS,
  data,
});

export const updateCouponBased = (params, onUpdated) => ({
  type: actionTypes.UPDATE_COUPON_BASED,
  params,
  onUpdated,
});

export const fetchPromotion = (id) => ({
  type: actionTypes.FETCH_PROMOTION,
  id,
});

export const fetchPromotionSuccess = (data) => ({
  type: actionTypes.FETCH_PROMOTION_SUCCESS,
  data,
});

export const deactivatePromotion = (id, campaignId) => ({
  type: actionTypes.DEACTIVATE_PROMOTION,
  id,
  campaignId,
});

export const updatePromotion = (id, data) => ({
  type: actionTypes.UPDATE_PROMOTION,
  id,
  data,
});

export const uploadSpecialCampaignFile = (file) => ({
  type: actionTypes.UPLOAD_SPECIAL_CAMPAIGN_FILE,
  file,
});

export const createCampaign = (fields) => ({
  type: actionTypes.CREATE_CAMPAIGN,
  fields,
});

export const createPromotion = (id, data) => ({
  type: actionTypes.CREATE_PROMOTION,
  data,
  id,
});

export const createSpecialPromotion = (data) => ({
  type: actionTypes.CREATE_SPECIAL_PROMOTION,
  data,
});

export const removeCampaign = (params, onRemoved) => ({
  type: actionTypes.REMOVE_CAMPAIGN,
  params,
  onRemoved,
});

export const removeCampaignSuccess = () => ({
  type: actionTypes.REMOVE_CAMPAIGN_SUCCESS,
});

export const createTotalDependentPromotion = (data) => ({
  type: actionTypes.CREATE_TOTAL_DEPENDENT_PROMOTION,
  data,
});
