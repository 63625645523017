import * as yup from 'yup';

import get from 'lodash/get';
import last from 'lodash/last';
import mapValues from 'lodash/mapValues';

import {
  dateValidator,
  endDateValidator,
  positiveNumberValidator,
  percentValidator,
  integerNumberValidator,
} from 'app/validators';
import { promotionActionTypes, promotionRuleTypes } from './enums';

/**
 * Required If method
 */
yup.addMethod(yup.number, 'r', function (path) {
  return this.test('conditional-test', 'app:required', function (input) {
    if (input !== undefined) {
      return true;
    }

    const { from } = this;
    const { value: promotion } = last(from);

    if (promotion) {
      const value = get(promotion, path);
      return !value;
    }

    return true;
  });
});

/**
 * Validation schema base for promotion creation or edit forms
 */
export const promotionFormValidationSchema = yup.object({
  rules: yup.object({
    [promotionRuleTypes.PER_CARD_USAGE_LIMIT]: yup.object({
      configuration: yup.object({
        limit: integerNumberValidator().r(`rules.${promotionRuleTypes.PER_CARD_USAGE_LIMIT}`),
      }),
    }),
  }),
  pligs: yup.lazy((obj) =>
    yup.object(mapValues(obj, () => yup.array().min(1, 'campaigns:minPligs'))),
  ),
  startDate: dateValidator().required('app:required'),
  endDate: endDateValidator().required('app:required'),
  // Rules
  cartHasItemsConfig: yup.object({
    threshold: integerNumberValidator().r(`rules.${promotionRuleTypes.CART_HAS_ITEMS}`),
  }),
  orderTotal: positiveNumberValidator().r(`rules.${promotionRuleTypes.ORDER_TOTAL}`),
  // Actions
  fixedDiscount: yup.object({
    amount: positiveNumberValidator().r(`actions.${promotionActionTypes.FIXED_DISCOUNT}`),
    limit: integerNumberValidator(),
    cycle: integerNumberValidator(),
  }),
  percentageDiscount: yup.object({
    amount: percentValidator().r(`actions.${promotionActionTypes.PERCENTAGE_DISCOUNT}`),
    limit: integerNumberValidator(),
    cycle: integerNumberValidator(),
  }),
  finDiscount: yup.object({
    amount: positiveNumberValidator().r(`actions.${promotionActionTypes.FIN_DISCOUNT}`),
    limit: integerNumberValidator(),
    cycle: integerNumberValidator(),
  }),
  fixedTotalDiscount: yup.object({
    amount: positiveNumberValidator().r(`actions.${promotionActionTypes.FIXED_TOTAL_DISCOUNT}`),
    limit: integerNumberValidator(),
    cycle: integerNumberValidator(),
  }),
});
