import React, { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { pathToRegexp } from 'path-to-regexp';
import find from 'lodash/find';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { IS_DEV } from 'app/config';
import { useAction, usePushAction } from 'app/hooks';
import paths from 'app/paths';
import { selectors as appSelectors } from 'ducks/app';
import { selectors as authSelectors } from 'ducks/auth';
import { actions as modalsActions, modalTypes } from 'ducks/modals';
import useStyles from './Header.styles';

export function getPathName(path) {
  const pathConfig = find(paths, (item) => {
    return pathToRegexp(item.path).exec(path) !== null;
  });
  return pathConfig ? pathConfig.name : '';
}

const Header = memo(() => {
  const currentPath = useSelector(appSelectors.currentPath);
  const { isAuthorized } = useSelector(authSelectors.authStatus);
  const { username } = useSelector(authSelectors.user);
  const showModal = useAction(modalsActions.showModal);
  const push = usePushAction();
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const setEn = useCallback(() => {
    i18n.changeLanguage('en');
  }, [i18n]);

  const setFr = useCallback(() => {
    i18n.changeLanguage('fr');
  }, [i18n]);

  const pushToLogout = useCallback(() => {
    push(paths.logout.path);
  }, [push]);

  const showChangePasswordModal = useCallback(() => {
    showModal(modalTypes.CHANGE_PASSWORD_MODAL);
  }, [showModal]);

  const pathName = useMemo(() => {
    return t(getPathName(currentPath));
  }, [currentPath, t]);

  return (
    <AppBar className={classes.root}>
      <Toolbar variant="dense">
        <Typography className={classes.title} align="center" variant="h6">
          {pathName}
        </Typography>
        <Typography className={classes.username}>{username}</Typography>
        {IS_DEV && (
          <>
            <Button onClick={setEn} className={classes.button}>
              EN
            </Button>
            <Button onClick={setFr} className={classes.button}>
              FR
            </Button>
          </>
        )}
        {isAuthorized && (
          <Button className={classes.button} onClick={showChangePasswordModal}>
            {t('auth:changePassword')}
          </Button>
        )}
        <Button className={classes.button} onClick={pushToLogout}>
          {t('auth:logOut')}
        </Button>
      </Toolbar>
    </AppBar>
  );
});

export default Header;
