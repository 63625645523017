import React, { memo } from 'react';

import TextField from './TextField';

const AmountField = memo((props) => <TextField {...props} />);

AmountField.propTypes = {
  ...TextField.propTypes,
};

AmountField.defaultProps = {
  ...TextField.defaultProps,
  name: 'amount',
  label: 'app:amount',
  type: 'number',
  inputProps: { step: '0.01' },
};

export default AmountField;
