import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import AdminGroupSearchInput from './AdminGroupSearchInput';
import useStyles from './AdminGroupsHeader.styles';

const AdminGroupsHeader = memo(({ removeAll, onSearchChange }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Typography className={classes.title} variant="h5">
          {t('admins:groups')}
        </Typography>
        <Button className={classes.button} onClick={removeAll}>
          {t('app:removeAll')}
        </Button>
      </Grid>
      <Grid item xs={6}>
        <AdminGroupSearchInput onChange={onSearchChange} />
      </Grid>
    </Grid>
  );
});

AdminGroupsHeader.propTypes = {
  removeAll: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
};

export default AdminGroupsHeader;
