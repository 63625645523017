import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';
import values from 'lodash/values';

import {
  showSuccess,
  handleError,
  startLoading,
  finishLoading,
  exportRawCsv,
  startActLoading,
  finishActLoading,
} from 'ducks/app/actions';
import { hideModal } from 'ducks/modals/actions';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as services from './services';
import {
  createLoyaltyDisabledPeriodFailure,
  createLoyaltyDisabledPeriodSuccess,
  removeLoyaltyDisabledPeriodFailed,
  removeLoyaltyDisabledPeriodSuccess,
  updateLoyaltyDisabledPeriodFailed,
  updateLoyaltyDisabledPeriodSuccess,
} from './actions';

function* fetchStoreList({ params }) {
  try {
    yield put(startLoading());
    const data = yield call(services.fetchStoreList, params);
    yield put(actions.fetchStoreListSuccess(data));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* fetchOnlinePercentage({ params }) {
  try {
    yield put(startLoading());
    const { items, columns } = yield call(services.fetchOnlinePercentage, params);
    yield put(exportRawCsv('online_percentage', items, columns));
    yield put(hideModal());
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* fetchStore({ id }) {
  try {
    yield put(startLoading());
    const data = yield call(services.fetchStore, id);
    yield put(actions.fetchStoreSuccess(data));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* updateStore({ id, data }) {
  try {
    yield put(startLoading());
    yield call(services.updateStore, id, data);
    yield put(showSuccess('app:updated'));
    yield put(actions.fetchStore(id));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* addStore({ data }) {
  try {
    yield put(startLoading());
    yield call(services.addStore, data);
    yield put(showSuccess('app:added'));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* activateStore({ id }) {
  try {
    yield put(startLoading());
    yield call(services.activateStore, id);
    yield put(showSuccess('app:activated'));
    yield put(actions.fetchStore(id));
  } catch (error) {
    yield put(handleError(error));
  } finally {
    yield put(finishLoading());
  }
}

function* deactivateStore({ id }) {
  try {
    yield put(startLoading());
    yield call(services.deactivateStore, id);
    yield put(showSuccess('app:deactivated'));
    yield put(actions.fetchStore(id));
  } catch (error) {
    yield put(handleError(error));
  } finally {
    yield put(finishLoading());
  }
}

function* enableStoreReporting({ id }) {
  try {
    yield put(startLoading());
    yield call(services.enableStoreReporting, id);
    yield put(showSuccess('app:activated'));
    yield put(actions.fetchStore(id));
  } catch (error) {
    yield put(handleError(error));
  } finally {
    yield put(finishLoading());
  }
}

function* disableStoreReporting({ id }) {
  try {
    yield put(startLoading());
    yield call(services.disableStoreReporting, id);
    yield put(showSuccess('app:deactivated'));
    yield put(actions.fetchStore(id));
  } catch (error) {
    yield put(handleError(error));
  } finally {
    yield put(finishLoading());
  }
}

function* fetchPriceGroups() {
  try {
    yield put(startLoading());
    const data = yield call(services.fetchPriceGroups);
    yield put(actions.fetchPriceGroupsSuccess(data));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* fetchStoreOptions({ actId, params }) {
  try {
    yield put(startActLoading(actId));
    const { items } = yield call(services.fetchStoreList, { filter: params });
    yield put(actions.fetchStoreOptionsSuccess(values(items)));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishActLoading());
  }
}

function* generateDailyReport({ storeNumber, data }) {
  try {
    yield put(startLoading());
    yield call(services.generateDailyReport, { ...data, storeNumber });
    yield put(showSuccess('stores:reportRequestSent'));
    yield put(hideModal());
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* generateMonthlyReport({ storeNumber, data }) {
  try {
    yield put(startLoading());
    yield call(services.generateMonthlyReport, { ...data, storeNumber });
    yield put(showSuccess('stores:reportRequestSent'));
    yield put(hideModal());
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* createLoyaltyDisabledPeriod({ storeId, startsAt, endsAt }) {
  try {
    yield put(startLoading());
    const { data } = yield call(services.createLoyaltyDisabledPeriod, {
      store: storeId,
      startsAt,
      endsAt,
    });
    yield put(createLoyaltyDisabledPeriodSuccess(data));
  } catch (e) {
    yield put(createLoyaltyDisabledPeriodFailure(e));
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* updateLoyaltyDisabledPeriod({ periodId, startsAt, endsAt }) {
  try {
    yield put(startLoading());
    const { data } = yield call(services.updateLoyaltyDisabledPeriod, {
      periodId,
      startsAt,
      endsAt,
    });
    yield put(updateLoyaltyDisabledPeriodSuccess(data));
  } catch (e) {
    yield put(updateLoyaltyDisabledPeriodFailed(e));
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* removeLoyaltyDisabledPeriod({ periodId }) {
  try {
    yield put(startLoading());
    yield call(services.removeLoyaltyDisabledPeriod, periodId);
    yield put(removeLoyaltyDisabledPeriodSuccess(periodId));
  } catch (e) {
    yield put(removeLoyaltyDisabledPeriodFailed(e));
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* uploadCardsFileForStore({ file, storeNumber }) {
  try {
    yield put(startLoading());
    yield call(services.uploadCardsFileForStore, file, storeNumber);
    yield put(showSuccess('clients:createCardsRequestSent'));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(hideModal());
    yield put(finishLoading());
  }
}

export default function* () {
  yield all([
    yield takeLatest(actionTypes.FETCH_STORE_LIST, fetchStoreList),
    yield takeLatest(actionTypes.FETCH_ONLINE_PERCENTAGE, fetchOnlinePercentage),
    yield takeLatest(actionTypes.FETCH_STORE, fetchStore),
    yield takeLatest(actionTypes.UPDATE_STORE, updateStore),
    yield takeLatest(actionTypes.ADD_STORE, addStore),
    yield takeLatest(actionTypes.FETCH_PRICE_GROUPS, fetchPriceGroups),
    yield takeLatest(actionTypes.FETCH_STORE_OPTIONS, fetchStoreOptions),
    yield takeLatest(actionTypes.GENERATE_DAILY_REPORT, generateDailyReport),
    yield takeLatest(actionTypes.GENERATE_MONTHLY_REPORT, generateMonthlyReport),
    yield takeLatest(actionTypes.ACTIVATE_STORE, activateStore),
    yield takeLatest(actionTypes.DEACTIVATE_STORE, deactivateStore),
    yield takeLatest(actionTypes.ENABLE_STORE_REPORTING, enableStoreReporting),
    yield takeLatest(actionTypes.DISABLE_STORE_REPORTING, disableStoreReporting),
    yield takeEvery(actionTypes.CREATE_LOYALTY_DISABLED_PERIOD, createLoyaltyDisabledPeriod),
    yield takeEvery(actionTypes.UPDATE_LOYALTY_DISABLED_PERIOD, updateLoyaltyDisabledPeriod),
    yield takeEvery(actionTypes.REMOVE_LOYALTY_DISABLED_PERIOD, removeLoyaltyDisabledPeriod),
    yield takeEvery(actionTypes.UPLOAD_CARDS_FILE_FOR_STORE, uploadCardsFileForStore),
  ]);
}
