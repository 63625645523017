import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Publish from '@material-ui/icons/Publish';

import { useAction } from 'app/hooks';
import { formatDate } from 'app/formatters';
import { actions as campaignsActions, selectors as campaignsSelectors } from 'ducks/campaigns';
import { actions as modalsActions, modalTypes } from 'ducks/modals';
import { selectors as authSelectors } from 'ducks/auth';
import { List, Breadcrumbs } from 'components';

import CampaignFilter from './CampaignFilter';
import CampaignActionsCell from './CampaignActionsCell';

const rowConfig = [
  {
    name: 'name',
    label: 'app:name',
    source: 'name',
    sortable: true,
  },
  {
    name: 'description',
    label: 'app:description',
    source: 'description',
    sortable: true,
  },
  {
    name: 'promotionCount',
    label: 'campaigns:promotionCount',
    render: ({ promotions }) => promotions.length,
    sortable: true,
  },
  {
    name: 'startDate',
    label: 'app:startDate',
    render: ({ promotions }) => promotions[0] && formatDate(promotions[0].startDate),
    sortable: true,
  },
  {
    name: 'endDate',
    label: 'app:endDate',
    render: ({ promotions }) => promotions[0] && formatDate(promotions[0].endDate),
    sortable: true,
  },
  {
    name: 'actions',
    label: 'app:actions',
    component: CampaignActionsCell,
  },
];

const CampaignList = memo(() => {
  const { items, meta } = useSelector(campaignsSelectors.campaignList);
  const isSuperAdmin = useSelector(authSelectors.isSuperAdmin);
  const fetchCampaignList = useAction(campaignsActions.fetchCampaignList);
  const showModal = useAction(modalsActions.showModal);

  const toolbarActions = useMemo(
    () => [
      {
        name: 'upload',
        label: 'app:upload',
        icon: Publish,
        show: isSuperAdmin,
        confirm: false,
        callback: () => showModal(modalTypes.UPLOAD_CAMPAIGN_MODAL),
      },
      {
        name: 'add',
        label: 'app:add',
        icon: AddCircleIcon,
        show: isSuperAdmin,
        confirm: false,
        callback: () => showModal(modalTypes.CREATE_CAMPAIGN_MODAL),
      },
    ],
    [showModal, isSuperAdmin],
  );

  return (
    <>
      <Breadcrumbs />
      <List
        data={items}
        meta={meta}
        onFetchData={fetchCampaignList}
        filterComponent={CampaignFilter}
        toolbarActions={toolbarActions}
        rowConfig={rowConfig}
      />
    </>
  );
});

export default CampaignList;
