import { Map, List } from 'immutable';
import { createSelector } from 'reselect';

import { createObjectSelector, createPaginatedListSelector } from 'app/selectors';

export const storeList = createPaginatedListSelector((state) =>
  state.stores.get('storeList', Map()),
);

export const editingStore = createObjectSelector((state) =>
  state.stores.get('editingStore', Map()),
);

export const priceGroups = createObjectSelector((state) => state.stores.get('priceGroups', List()));

export const storeOptions = createObjectSelector((state) =>
  state.stores.get('storeOptions', List()),
);

export const storeOptionNumbers = createSelector([storeOptions], (data) =>
  data.map(({ id, number }) => ({ id, name: number })),
);
