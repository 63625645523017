import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import IconButton from '@material-ui/core/IconButton';

const CheckButton = memo(({ title, classNameIcon, ...rest }) => {
  const { t } = useTranslation();

  return (
    <IconButton title={t(title)} {...rest}>
      <CheckCircleIcon className={classNameIcon} />
    </IconButton>
  );
});

CheckButton.propTypes = {
  ...IconButton.propTypes,
  classNameIcon: PropTypes.string,
};

CheckButton.defaultProps = {
  ...IconButton.defaultProps,
  title: 'app:submit',
  classNameIcon: '',
};

export default CheckButton;
