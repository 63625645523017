import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { formatDate } from 'app/formatters';
import { Panel, PropertyRow } from 'components';
import TargetCell from '../PromotionList/TargetCell';

const PromotionDetailsGeneral = memo((props) => {
  const { promotion } = props;
  return (
    <Panel title="app:general" defaultExpanded>
      <PropertyRow label="app:description">{promotion.description}</PropertyRow>
      <PropertyRow label="campaigns:target">
        <TargetCell item={promotion} />
      </PropertyRow>
      <PropertyRow label="app:startDate">{formatDate(promotion.startDate)}</PropertyRow>
      <PropertyRow label="app:endDate">{formatDate(promotion.endDate)}</PropertyRow>
    </Panel>
  );
});

PromotionDetailsGeneral.propTypes = {
  promotion: PropTypes.object.isRequired,
};

export default PromotionDetailsGeneral;
