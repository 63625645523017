import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import values from 'lodash/values';

import { promotionTargetTypes } from 'ducks/campaigns/enums';
import { DropdownField } from 'components';

const TargetDropdownField = memo((props) => {
  const { t } = useTranslation();

  const items = useMemo(() => {
    return values(promotionTargetTypes).map((item) => ({
      value: item,
      label: t(`campaigns:promotionTargetTypes.${item}`),
    }));
  }, [t]);

  return <DropdownField {...props} items={items} />;
});

TargetDropdownField.propTypes = {
  ...DropdownField.propTypes,
};

TargetDropdownField.defaultProps = {
  ...DropdownField.defaultProps,
  name: 'target',
  label: 'campaigns:target',
};

export default TargetDropdownField;
