import React from 'react';
import MaterialPaper from '@material-ui/core/Paper';

import useStyles from './Paper.styles';

const Paper = function ({ children, ...rest }) {
  const classes = useStyles();

  return (
    <MaterialPaper className={classes.root} {...rest}>
      {children}
    </MaterialPaper>
  );
};

Paper.propTypes = {
  ...MaterialPaper.propTypes,
};

Paper.defaultProps = {
  ...MaterialPaper.defaultProps,
};

export default Paper;
