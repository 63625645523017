import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

import { useAction } from 'app/hooks';
import { actions as modalsActions, modalTypes } from 'ducks/modals';
import { actions as storeActions } from 'ducks/stores';
import { Panel, SecondaryButton } from 'components';

const StoreReporting = function ({ id: storeId, store }) {
  const enableStoreReporting = useAction(storeActions.enableStoreReporting);
  const disableStoreReporting = useAction(storeActions.disableStoreReporting);
  const showModal = useAction(modalsActions.showModal);
  const { t } = useTranslation();

  const handleDeactivate = useCallback(() => {
    showModal(modalTypes.CONFIRMATION_MODAL, {
      onOk: () => disableStoreReporting(storeId),
    });
  }, [storeId, disableStoreReporting, showModal]);

  const handleActivate = useCallback(() => {
    showModal(modalTypes.CONFIRMATION_MODAL, {
      onOk: () => enableStoreReporting(storeId),
    });
  }, [storeId, enableStoreReporting, showModal]);

  return (
    <Panel title="stores:reporting" defaultExpanded={false}>
      <p>
        <Typography variant="subtitle1" component="span">
          {t('stores:currentStatus')}
          {': '}
          {t(store.reportingEnabled ? 'app:enabled' : 'app:disabled')}
        </Typography>
      </p>
      {store.reportingEnabled ? (
        <SecondaryButton label="app:deactivate" onClick={handleDeactivate} />
      ) : (
        <SecondaryButton label="app:activate" onClick={handleActivate} />
      )}
    </Panel>
  );
};

StoreReporting.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  store: PropTypes.object.isRequired,
};

export default React.memo(StoreReporting);
