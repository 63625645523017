import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { selectors as appSelectors } from 'ducks/app';
import { selectors as authSelectors } from 'ducks/auth';
import Sidebar from './Sidebar';
import Header from './Header';
import Loader from './Loader';
import Notification from './Notification';
import useStyles from './Layout.styles';

const Layout = function ({ children }) {
  const loading = useSelector(appSelectors.loading);
  const { isAuthenticated } = useSelector(authSelectors.authStatus);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {isAuthenticated && <Sidebar />}
      {isAuthenticated && <Header />}
      {loading && <Loader />}
      <Notification />
      <div className={classes.main}>
        <div className={clsx(classes.content, loading && 'blocked')}>{children}</div>
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Layout;
