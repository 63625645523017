import React, { memo } from 'react';

import TextField from './TextField';

const DescriptionField = memo((props) => <TextField {...props} />);

DescriptionField.propTypes = {
  ...TextField.propTypes,
};

DescriptionField.defaultProps = {
  ...TextField.defaultProps,
  name: 'description',
  label: 'app:description',
};

export default DescriptionField;
