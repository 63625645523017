import { G20_ADMIN, STORE_ADMIN, SUPER_ADMIN } from 'app/roles';

export default {
  transactions: {
    path: '/transactions',
    name: 'transactions:transactions',
    roles: [SUPER_ADMIN, G20_ADMIN, STORE_ADMIN],
  },
  transactionView: {
    path: '/transactions/:id',
    name: 'transactions:transactionDetails',
    roles: [SUPER_ADMIN, G20_ADMIN, STORE_ADMIN],
  },
};
