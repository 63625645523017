import React, { memo, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import keys from 'lodash/keys';
import values from 'lodash/values';
import filter from 'lodash/filter';
import Grid from '@material-ui/core/Grid';

import { useAction } from 'app/hooks';
import { actions as adminsActions, selectors as adminsSelectors } from 'ducks/admins';
import { actions as modalsActions, modalTypes } from 'ducks/modals';
import AdminGroupsHeader from './AdminGroupsHeader';
import AdminGroupTable from './AdminGroupTable';

const AdminGroups = memo(({ adminId }) => {
  const { availableGroups, allGroups } = useSelector(adminsSelectors.adminGroups);
  const fetchAdminGroups = useAction(adminsActions.fetchAdminGroups);
  const [selectedGroups, setSelectedGroups] = useState({});
  const [searchString, setSearchString] = useState('');
  const { setFieldValue } = useFormikContext();
  const showModal = useAction(modalsActions.showModal);

  useEffect(() => {
    fetchAdminGroups(adminId);
  }, [adminId, fetchAdminGroups]);

  useEffect(() => {
    setSelectedGroups({
      ...availableGroups,
    });
  }, [availableGroups]);

  useEffect(() => {
    setFieldValue('groups', keys(selectedGroups));
  }, [selectedGroups, setFieldValue]);

  const filterGroups = useCallback(() => {
    return filter(allGroups, ({ id, name }) => {
      if (selectedGroups[id]) {
        return false;
      }
      if (searchString) {
        return (
          `${id}`.indexOf(searchString) !== -1 ||
          `${name}`.toLowerCase().indexOf(searchString) !== -1
        );
      }
      return true;
    });
  }, [selectedGroups, allGroups, searchString]);

  const addGroup = useCallback(
    (group) => {
      if (group.admins !== null) {
        showModal(modalTypes.ADD_ANOTHER_ONE_ADMIN_FOR_STORE_MODAL, {
          group,
          onOk: () =>
            setSelectedGroups({
              ...selectedGroups,
              [group.id]: group,
            }),
        });
      } else {
        setSelectedGroups({
          ...selectedGroups,
          [group.id]: group,
        });
      }
    },
    [selectedGroups],
  );

  const removeGroup = useCallback(
    (group) => {
      const newSelectedGroups = { ...selectedGroups };
      delete newSelectedGroups[group.id];
      setSelectedGroups({
        ...newSelectedGroups,
      });
    },
    [selectedGroups],
  );

  const removeAll = useCallback(() => {
    setSelectedGroups({});
  }, []);

  const onSearchChange = useCallback((e) => {
    setSearchString(e.target.value.toLowerCase());
  }, []);

  return (
    <>
      <AdminGroupsHeader removeAll={removeAll} onSearchChange={onSearchChange} />
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <AdminGroupTable groups={values(selectedGroups)} removeGroup={removeGroup} />
        </Grid>
        <Grid item xs={6}>
          <AdminGroupTable groups={filterGroups()} addGroup={addGroup} />
        </Grid>
      </Grid>
    </>
  );
});

AdminGroups.propTypes = {
  adminId: PropTypes.number,
};

AdminGroups.defaultProps = {
  adminId: undefined,
};

export default AdminGroups;
