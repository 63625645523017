import { createSelector } from 'reselect';

export const createObjectSelector = (selector) => {
  return createSelector([selector], (data) => data.toJS());
};

export const createPaginatedListSelector = (selector) => {
  return createSelector([selector], (dataMap) => {
    const { order, items, meta } = dataMap.toJS();
    return {
      items: order.map((id) => items[id]),
      meta,
    };
  });
};
