import { G20_ADMIN, STORE_ADMIN, SUPER_ADMIN } from 'app/roles';

export default {
  campaigns: {
    path: '/campaigns',
    name: 'campaigns:campaigns',
    roles: [SUPER_ADMIN, G20_ADMIN, STORE_ADMIN],
  },
  promotions: {
    path: '/campaigns/:campaignId/promotions',
    name: 'campaigns:promotions',
    roles: [SUPER_ADMIN, G20_ADMIN, STORE_ADMIN],
  },
  promotionView: {
    path: '/campaigns/:campaignId/promotions/:promotionId',
    name: 'campaigns:promotionDetails',
    roles: [SUPER_ADMIN, G20_ADMIN, STORE_ADMIN],
  },
  promotionAdd: {
    path: '/campaigns/:campaignId/promotions/add/:type',
    name: 'campaigns:promotionCreate',
    roles: [SUPER_ADMIN],
  },
};
