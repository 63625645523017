import React, { memo } from 'react';

import DropdownField from './DropdownField';

const items = [
  {
    value: '0',
    label: 'app:no',
  },
  {
    value: '1',
    label: 'app:yes',
  },
];

const RoleField = memo((props) => {
  return <DropdownField {...props} />;
});

RoleField.propTypes = {
  ...DropdownField.propTypes,
};

RoleField.defaultProps = {
  ...DropdownField.defaultProps,
  name: 'locked',
  label: 'admin:locked',
  items,
};

export default RoleField;
