import React, { memo } from 'react';

import TextField from './TextField';

const CityField = memo((props) => <TextField {...props} />);

CityField.propTypes = {
  ...TextField.propTypes,
};

CityField.defaultProps = {
  ...TextField.defaultProps,
  name: 'city',
  label: 'app:city',
};

export default CityField;
