import React, { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { Switch, Redirect } from 'react-router-dom';

import paths from 'app/paths';
import { useAction } from 'app/hooks';
import { actions, selectors } from 'ducks/auth';
import { Layout, AuthRoute, NonAuthRoute } from 'components';
import routes from './routes';

export const history = createBrowserHistory();

const Router = function () {
  const { isAuthenticated, isAuthorized } = useSelector(selectors.authStatus);
  const checkAuth = useAction(actions.checkAuth);
  const fetchUser = useAction(actions.fetchUser);

  useEffect(() => {
    if (isAuthenticated === null) {
      checkAuth();
    } else if (isAuthenticated === true && isAuthorized === false) {
      fetchUser();
    }
  }, [isAuthenticated, isAuthorized, checkAuth, fetchUser]);

  const renderRoute = useCallback((config) => {
    const { path, component, roles, auth, exact = true, ...restConfig } = config;
    const routeProps = { key: path, path, component, roles, exact, ...restConfig };
    return auth === false ? <NonAuthRoute {...routeProps} /> : <AuthRoute {...routeProps} />;
  }, []);

  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Switch>
          {routes.map(renderRoute)}
          <Redirect to={paths.stores.path} />
        </Switch>
      </Layout>
    </ConnectedRouter>
  );
};

export default Router;
