import { useCallback, useEffect, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push as pushAction } from 'connected-react-router';
import values from 'lodash/values';

import { ListContext } from 'components/shared/list/_constants';

export const useAction = (action) => {
  const dispatch = useDispatch();
  return useCallback((...args) => dispatch(action(...args)), [dispatch, action]);
};

export const usePushAction = () => {
  return useAction(pushAction);
};

export const useClickOutside = (ref, callback) => {
  const handleClickOutside = useCallback(
    (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    },
    [ref, callback],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);
};

export const useListContext = () => {
  return useContext(ListContext);
};

export const useListSelector = (selector) => {
  const items = useSelector(selector);
  return useMemo(() => {
    return values(items);
  }, [items]);
};
