import React from 'react';
import { useFormikContext } from 'formik';

import { promotionRuleTypes, promotionTargetTypes } from 'ducks/campaigns/enums';
import { IntegerField, Button, Panel } from 'components/shared';

const PerCardUsageLimitRule = function () {
  const { values: promotion, setFieldValue } = useFormikContext();

  const addPerCardUsageLimit = () => {
    const rule = {
      type: promotionRuleTypes.PER_CARD_USAGE_LIMIT,
      configuration: {},
    };
    setFieldValue(`rules.${promotionRuleTypes.PER_CARD_USAGE_LIMIT}`, rule, false);
  };

  const removePerCardUsageLimit = () => {
    setFieldValue(`rules.${promotionRuleTypes.PER_CARD_USAGE_LIMIT}`, undefined, false);
  };

  if (promotion.target !== promotionTargetTypes.CARDHOLDERS || promotion.campaign !== undefined) {
    return null;
  }

  return (
    <Panel
      title={`campaigns:promotionRuleTypesBrief:${promotionRuleTypes.PER_CARD_USAGE_LIMIT}`}
      defaultExpanded
    >
      {promotion.rules[promotionRuleTypes.PER_CARD_USAGE_LIMIT] ? (
        <>
          <Button
            variant="outlined"
            label="campaigns:removeRule"
            onClick={removePerCardUsageLimit}
          />
          <IntegerField
            name={`rules.${promotionRuleTypes.PER_CARD_USAGE_LIMIT}.configuration.limit`}
            label="campaigns:limitPerCard"
          />
        </>
      ) : (
        <Button variant="outlined" label="campaigns:addRule" onClick={addPerCardUsageLimit} />
      )}
    </Panel>
  );
};

export default React.memo(PerCardUsageLimitRule);
