import { fromJS } from 'immutable';

import * as actionTypes from './actionTypes';

const initialState = fromJS({
  campaignList: {
    order: [],
    items: {},
    meta: { page: 1, limit: 10, total: 0 },
  },
  promotionList: {
    order: [],
    items: {},
    meta: { page: 1, limit: 10, total: 0 },
  },
  viewingPromotion: {
    actions: [],
    rules: [],
  },
});

export default (state = initialState, { type, data }) => {
  switch (type) {
    case actionTypes.FETCH_CAMPAIGN_LIST_SUCCESS:
      return state.merge(
        fromJS({
          campaignList: data,
        }),
      );

    case actionTypes.FETCH_PROMOTION_LIST_SUCCESS:
      return state.merge(
        fromJS({
          promotionList: data,
        }),
      );

    case actionTypes.FETCH_PROMOTION_SUCCESS:
      return state.merge(
        fromJS({
          viewingPromotion: data,
        }),
      );

    default:
      return state;
  }
};
