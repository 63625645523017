import React from 'react';
import { useFormikContext } from 'formik';

import { Panel, AmountField, Caption } from 'components';
import { promotionTargetTypes } from 'ducks/campaigns/enums';

import { DiscountTypeDropdownField } from '../fields';

const PromotionEditSpecial = function () {
  const { values: promotion } = useFormikContext();

  return (
    <Panel title="campaigns:special:panel" defaultExpanded>
      {promotion.target === promotionTargetTypes.CARDHOLDERS && (
        <>
          <Caption text="campaigns:discountType" />
          <DiscountTypeDropdownField name="discountType" />
        </>
      )}

      <Caption text="campaigns:special:oneItem" />
      <AmountField name="y1" label="campaigns:discountAmountEuro" />

      <Caption text="campaigns:special:twoItems" />
      <AmountField name="y2" label="campaigns:discountAmountEuro" />

      <Caption text="campaigns:special:threeAndMoreItems" />
      <AmountField name="y3" label="campaigns:discountAmountEuro" />
    </Panel>
  );
};

export default React.memo(PromotionEditSpecial);
