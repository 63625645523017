import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { formatDate } from 'app/formatters';
import { PropertyRow } from 'components';

const ClientProfileDetails = memo((props) => {
  const { client } = props;
  return (
    <>
      <PropertyRow label="clients:gender">{client.gender.shortTitle}</PropertyRow>
      <PropertyRow label="app:firstName">{client.firstName}</PropertyRow>
      <PropertyRow label="app:lastName">{client.lastName}</PropertyRow>
      <PropertyRow label="auth:email">{client.user.email || client.email}</PropertyRow>
      <PropertyRow label="clients:birthday">
        {client.birthday && formatDate(new Date(client.birthday))}
      </PropertyRow>
      <PropertyRow label="app:phoneNumber">{client.phoneNumber}</PropertyRow>
      <PropertyRow label="app:houseNumber">{client.address.houseNumber}</PropertyRow>
      <PropertyRow label="app:street">{client.address.street}</PropertyRow>
      <PropertyRow label="app:postalCode">{client.address.postalCode}</PropertyRow>
      <PropertyRow label="app:city">{client.address.city}</PropertyRow>
      <PropertyRow label="app:country">{client.address.country}</PropertyRow>
    </>
  );
});

ClientProfileDetails.propTypes = {
  client: PropTypes.object.isRequired,
};

export default ClientProfileDetails;
