import { G20_ADMIN, STORE_ADMIN, SUPER_ADMIN } from 'app/roles';

export default {
  clients: {
    path: '/clients',
    name: 'clients:clients',
    roles: [SUPER_ADMIN, G20_ADMIN, STORE_ADMIN],
  },
  clientView: {
    path: '/clients/:id',
    name: 'clients:clientDetails',
    roles: [SUPER_ADMIN, G20_ADMIN, STORE_ADMIN],
  },
};
