import React, { memo } from 'react';

import TextField from './TextField';

const StreetField = memo((props) => <TextField {...props} />);

StreetField.propTypes = {
  ...TextField.propTypes,
};

StreetField.defaultProps = {
  ...TextField.defaultProps,
  name: 'street',
  label: 'app:street',
};

export default StreetField;
