import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormikContext, Field } from 'formik';
import TextField from '@material-ui/core/TextField';
import MaterialAutocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import get from 'lodash/get';

import { selectors as appSelectors } from 'ducks/app';
import { randomString } from 'app/utils';

const AutocompleteField = memo((props) => {
  const { name, label, valueSource, data, onFetch, onChange, minLength, defaultValue, ...rest } =
    props;
  const { t } = useTranslation();
  const { setFieldValue, errors } = useFormikContext();
  const [open, setOpen] = useState(false);
  const [actId] = useState(randomString());
  const actLoadingId = useSelector(appSelectors.actLoadingId);
  const loading = actId === actLoadingId;

  const onOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const getOptionSelected = useCallback((option, value) => {
    return option.name === value.name;
  }, []);

  const getOptionLabel = useCallback((option) => option.name, []);

  const handleChange = useCallback(
    (e, item) => {
      const value = item ? item[valueSource] : '';
      if (onChange) {
        onChange(value);
      } else {
        setFieldValue(name, value);
      }
    },
    [name, valueSource, onChange, setFieldValue],
  );

  const onInputChange = useCallback(
    (e, value) => {
      if (value.length >= minLength) {
        onFetch(actId, value);
      }
    },
    [minLength, onFetch, actId],
  );

  const renderInput = useCallback(
    (params) => {
      const error = get(errors, name);

      return (
        <TextField
          {...params}
          {...rest}
          label={t(label)}
          error={!!error}
          helperText={t(error)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      );
    },
    [t, label, errors, name, loading, rest],
  );

  return (
    <>
      <MaterialAutocomplete
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        getOptionSelected={getOptionSelected}
        getOptionLabel={getOptionLabel}
        options={data}
        loading={loading}
        onChange={handleChange}
        onInputChange={onInputChange}
        noOptionsText={t('app:noOptions')}
        defaultValue={defaultValue}
        renderInput={renderInput}
      />
      <Field name={name} type="hidden" />
    </>
  );
});

AutocompleteField.propTypes = {
  ...TextField.propTypes,
  name: PropTypes.string.isRequired,
  valueSource: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  onFetch: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  minLength: PropTypes.number,
};

AutocompleteField.defaultProps = {
  ...TextField.defaultProps,
  variant: 'outlined',
  margin: 'dense',
  fullWidth: true,
  data: [],
  valueSource: 'id',
  onChange: undefined,
  minLength: 3,
};

export default AutocompleteField;
