import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

const AddCouponButton = memo(({ title, ...rest }) => {
  const { t } = useTranslation();
  return (
    <IconButton title={t(title)} {...rest}>
      <PersonAddIcon />
    </IconButton>
  );
});

AddCouponButton.propTypes = {
  ...IconButton.propTypes,
};

AddCouponButton.defaultProps = {
  ...IconButton.defaultProps,
  title: 'coupons:addCoupon',
};

export default AddCouponButton;
