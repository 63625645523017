import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  title: {
    float: 'left',
    marginTop: 10,
  },
  button: {
    float: 'right',
    marginTop: 10,
  },
}));

export default useStyles;
