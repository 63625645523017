import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import Publish from '@material-ui/icons/Publish';

const UploadCardsFileButton = memo(({ title, ...rest }) => {
  const { t } = useTranslation();
  return (
    <IconButton title={t('clients:uploadCardsFile')} {...rest}>
      <Publish />
    </IconButton>
  );
});

UploadCardsFileButton.propTypes = {
  ...IconButton.propTypes,
};
export default UploadCardsFileButton;
