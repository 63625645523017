import React, { memo } from 'react';
import clsx from 'clsx';

import useStyles from './Loader.styles';

const Loader = memo(() => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.first} />
      <div className={clsx(classes.first, classes.second)} />
    </div>
  );
});

export default Loader;
