import React, { memo } from 'react';

import TextField from './TextField';

const PasswordField = memo((props) => <TextField {...props} />);

PasswordField.propTypes = {
  ...TextField.propTypes,
};

PasswordField.defaultProps = {
  ...TextField.defaultProps,
  name: 'password',
  label: 'auth:password',
  type: 'password',
};

export default PasswordField;
