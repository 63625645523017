import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Breadcrumbs, Paper } from 'components';
import TransactionInfo from './TransactionInfo';

const TransactionView = memo((props) => {
  const {
    match: {
      params: { id },
    },
  } = props;
  const transactionId = parseInt(id);
  return (
    <>
      <Breadcrumbs />
      <Paper>
        <TransactionInfo transactionId={transactionId} />
      </Paper>
    </>
  );
});

TransactionView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default TransactionView;
