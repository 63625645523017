import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { EditButton } from '../buttons';
import LinkButton from './LinkButton';

const EditLinkButton = memo((props) => <LinkButton {...props} />);

EditLinkButton.propTypes = {
  ...LinkButton.propTypes,
  component: PropTypes.object,
};

EditLinkButton.defaultProps = {
  ...LinkButton.defaultProps,
  component: EditButton,
};

export default EditLinkButton;
