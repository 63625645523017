import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { withFormik, Form } from 'formik';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import { useAction } from 'app/hooks';
import { actions as authActions } from 'ducks/auth';
import { actions as modalsActions, modalTypes } from 'ducks/modals';
import { EmailField, PasswordField, PrimaryButton, SecondaryButton } from 'components';
import useStyles from './Login.styles';

const LoginForm = function () {
  const { t } = useTranslation();
  const classes = useStyles();
  const showModal = useAction(modalsActions.showModal);

  const showResetPasswordModal = useCallback(() => {
    showModal(modalTypes.RESET_PASSWORD_MODAL);
  }, [showModal]);

  return (
    <div className={classes.root}>
      <Card>
        <CardContent>
          <Typography className={classes.appName}>{t('app:appName')}</Typography>
          <Typography className={classes.adminInterface}>{t('app:adminInterface')}</Typography>
          <Form>
            <EmailField margin="normal" />
            <PasswordField margin="normal" />
            <PrimaryButton
              className={classes.button}
              label="auth:logIn"
              type="submit"
              size="large"
            />
          </Form>
          <SecondaryButton
            className={classes.button}
            label="auth:resetPassword"
            size="large"
            onClick={showResetPasswordModal}
          />
        </CardContent>
      </Card>
    </div>
  );
};

const validationSchema = yup.object().shape({
  email: yup.string().email('auth:invalidEmail').required('app:required'),
  password: yup.string().required('app:required'),
});

const EnhancedLoginForm = withFormik({
  displayName: 'LoginForm',
  validationSchema,
  enableReinitialize: true,
  validateOnBlur: false,
  mapPropsToValues: () => ({
    email: '',
    password: '',
  }),
  handleSubmit: (values, { props: { login } }) => {
    login(values);
  },
})(LoginForm);

EnhancedLoginForm.propTypes = {
  login: PropTypes.func.isRequired,
};

const Login = function (props) {
  const login = useAction(authActions.login);
  return <EnhancedLoginForm {...props} login={login} />;
};

export default Login;
