import React, { memo } from 'react';

import { TextField } from 'components';

const PligField = memo((props) => <TextField {...props} />);

PligField.propTypes = {
  ...TextField.propTypes,
};

PligField.defaultProps = {
  ...TextField.defaultProps,
  name: 'plig',
  label: 'products:plig',
};

export default PligField;
