import paths from 'app/paths';
import CampaignList from 'components/campaigns/CampaignList/CampaignList';
import PromotionView from 'components/campaigns/PromotionView';
import PromotionList from 'components/campaigns/PromotionList/PromotionList';
import PromotionAdd from 'components/campaigns/PromotionAdd/PromotionAdd';

export default [
  {
    path: paths.campaigns.path,
    roles: paths.campaigns.roles,
    component: CampaignList,
  },
  {
    path: paths.promotionAdd.path,
    roles: paths.promotionAdd.roles,
    component: PromotionAdd,
  },
  {
    path: paths.promotionView.path,
    roles: paths.promotionView.roles,
    component: PromotionView,
  },
  {
    path: paths.promotions.path,
    roles: paths.promotions.roles,
    component: PromotionList,
  },
];
