import { G20_ADMIN, STORE_ADMIN, SUPER_ADMIN } from 'app/roles';

export default {
  stores: {
    path: '/stores',
    name: 'stores:stores',
    roles: [SUPER_ADMIN, G20_ADMIN, STORE_ADMIN],
  },
  storesEdit: {
    path: '/stores/edit/:id',
    name: 'stores:editStore',
    roles: [SUPER_ADMIN, G20_ADMIN, STORE_ADMIN],
  },
  storesAdd: {
    path: '/stores/add',
    name: 'stores:addStore',
    roles: [SUPER_ADMIN],
  },
};
