import { Map, List } from 'immutable';
import { createSelector } from 'reselect';

import { createObjectSelector } from 'app/selectors';
import * as roles from 'app/roles';

export const authStatus = createObjectSelector((state) => state.auth.get('authStatus', Map()));

export const user = createObjectSelector((state) => state.auth.get('user', Map()));

export const userRoles = createObjectSelector((state) =>
  state.auth.getIn(['user', 'roles'], List()),
);

export const isSuperAdmin = createSelector([userRoles], (data) => data.includes(roles.SUPER_ADMIN));

export const isAppAdmin = createSelector(
  [userRoles],
  (data) => data.includes(roles.SUPER_ADMIN) || data.includes(roles.G20_ADMIN),
);
