import React, { memo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Form } from 'formik';

import { useAction } from 'app/hooks';
import Modal from 'components/modals/Modal';
import { actions } from 'ducks/stores';

const UploadCardsFileForStoreForm = memo(({ modalProps }) => {
  const uploadAction = useAction(actions.uploadCardsFileForStore);
  const file = useRef(null);

  const handleFileSelect = useCallback(
    ({ target }) => {
      [file.current] = target.files;
    },
    [file],
  );

  const handleFileSubmit = useCallback(() => {
    file.current && uploadAction(file.current, modalProps.number);
  }, [file, uploadAction]);

  return (
    <Modal
      titleText="clients:uploadCardsFile"
      okText="campaigns:uploadButton"
      onOk={handleFileSubmit}
      {...modalProps}
    >
      <Form>
        <input type="file" accept=".xls,.xlsx,.csv" onChange={handleFileSelect} />
      </Form>
    </Modal>
  );
});

UploadCardsFileForStoreForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  modalProps: PropTypes.shape(Modal.propTypes).isRequired,
};

const EnhancedUploadCardsFileForStoreForm = withFormik({
  enableReinitialize: true,
  validateOnBlur: true,
  mapPropsToValues: () => ({
    storeId: '',
  }),
  handleSubmit: (values, { props: { onSubmit, header } }) => {
    onSubmit(values, header);
  },
})(UploadCardsFileForStoreForm);

EnhancedUploadCardsFileForStoreForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

const UploadCardsFileForStoreModal = memo((props) => {
  return <EnhancedUploadCardsFileForStoreForm modalProps={props} />;
});

UploadCardsFileForStoreModal.propTypes = {
  ...Modal.propTypes,
};

export default UploadCardsFileForStoreModal;
