import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { useAction } from 'app/hooks';
import { formatDateTime } from 'app/formatters';
import { actions as productsActions, selectors as productsSelectors } from 'ducks/products';
import { List, Breadcrumbs } from 'components';
import ProductFilter from './ProductFilter';
import ProductActionsCell from './ProductActionsCell';

const rowConfig = [
  {
    name: 'plig',
    label: 'products:plig',
    source: 'value',
    sortable: true,
  },
  {
    name: 'label',
    label: 'app:label',
    source: 'label',
    sortable: true,
  },
  {
    name: 'eansCount',
    label: 'products:eansCount',
    source: 'eansCount',
    sortable: true,
  },
  {
    name: 'updatedAt',
    label: 'app:lastModification',
    render: ({ updatedAt }) => formatDateTime(updatedAt),
    sortable: true,
  },
  {
    name: 'actions',
    label: 'app:actions',
    component: ProductActionsCell,
  },
];

const ProductList = memo(() => {
  const { items, meta } = useSelector(productsSelectors.productList);
  const fetchProductList = useAction(productsActions.fetchProductList);

  return (
    <>
      <Breadcrumbs />
      <List
        data={items}
        meta={meta}
        onFetchData={fetchProductList}
        filterComponent={ProductFilter}
        rowConfig={rowConfig}
      />
    </>
  );
});

export default ProductList;
