export const SHOW_SUCCESS = 'app/SHOW_SUCCESS';
export const SHOW_ERROR = 'app/SHOW_ERROR';

export const HANDLE_ERROR = 'app/HANDLE_ERROR';

export const CLEAR_NOTIFICATION = 'app/CLEAR_NOTIFICATION';

export const START_LOADING = 'app/START_LOADING';
export const FINISH_LOADING = 'app/FINISH_LOADING';

export const START_ACT_LOADING = 'app/START_ACT_LOADING';
export const FINISH_ACT_LOADING = 'app/FINISH_ACT_LOADING';

export const EXPORT_PREPARED_CSV = 'app/EXPORT_PREPARED_CSV';
export const EXPORT_RAW_CSV = 'app/EXPORT_RAW_CSV';
