import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const ArrowBackButton = memo(({ title, ...rest }) => {
  const { t } = useTranslation();
  return (
    <IconButton title={t(title)} {...rest}>
      <ArrowBackIcon />
    </IconButton>
  );
});

ArrowBackButton.propTypes = {
  ...IconButton.propTypes,
};

ArrowBackButton.defaultProps = {
  ...IconButton.defaultProps,
};

export default ArrowBackButton;
