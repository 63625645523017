import { call, put, all, takeLatest } from 'redux-saga/effects';
import values from 'lodash/values';

import {
  showSuccess,
  handleError,
  startLoading,
  finishLoading,
  startActLoading,
  finishActLoading,
} from 'ducks/app/actions';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as services from './services';

function* fetchProductList({ params }) {
  try {
    yield put(startLoading());
    const data = yield call(services.fetchProductList, params);
    yield put(actions.fetchProductListSuccess(data));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* fetchProduct({ id }) {
  try {
    yield put(startLoading());
    const data = yield call(services.fetchProduct, id);
    yield put(actions.fetchProductSuccess(data));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* fetchEanOptions({ actId, params }) {
  try {
    yield put(startActLoading(actId));
    const data = yield call(services.fetchEans, params);
    yield put(actions.fetchEanOptionsSuccess(data));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishActLoading());
  }
}

function* fetchPligOptions({ actId, params, id }) {
  try {
    yield put(startActLoading(actId));
    const { items } = yield call(services.fetchProductList, params);
    const pligOptions = values(items);
    yield put(actions.fetchPligOptionsSuccess(pligOptions, id));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishActLoading());
  }
}

function* updateEans({ productId, message, data }) {
  try {
    yield put(startLoading());
    yield call(services.updateEans, productId, data);
    yield fetchProduct({ id: productId });
    yield put(showSuccess(message));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

export default function* () {
  yield all([
    yield takeLatest(actionTypes.FETCH_PRODUCT_LIST, fetchProductList),
    yield takeLatest(actionTypes.FETCH_PRODUCT, fetchProduct),
    yield takeLatest(actionTypes.FETCH_EAN_OPTIONS, fetchEanOptions),
    yield takeLatest(actionTypes.FETCH_PLIG_OPTIONS, fetchPligOptions),
    yield takeLatest(actionTypes.UPDATE_EANS, updateEans),
  ]);
}
