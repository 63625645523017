import React, { memo, useCallback } from 'react';

import { useAction, useListSelector } from 'app/hooks';
import { actions as productsActions, selectors as productsSelectors } from 'ducks/products';
import { AutocompleteField } from 'components';

const EanActField = memo(() => {
  const fetchEanOptions = useAction(productsActions.fetchEanOptions);
  const data = useListSelector(productsSelectors.eanOptionValues);

  const onFetch = useCallback(
    (actId, value) => {
      fetchEanOptions(actId, { ean: value });
    },
    [fetchEanOptions],
  );

  return (
    <AutocompleteField
      name="ean"
      label="products:ean"
      data={data}
      onFetch={onFetch}
      valueSource="name"
    />
  );
});

export default EanActField;
