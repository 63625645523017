import { fromJS } from 'immutable';

import * as actionTypes from './actionTypes';

const initialState = fromJS({
  couponList: {
    order: [],
    items: {},
    meta: { page: 1, limit: 10, total: 0 },
  },
});

export default (state = initialState, { type, data }) => {
  switch (type) {
    case actionTypes.FETCH_COUPON_LIST_SUCCESS:
      return state.merge(
        fromJS({
          couponList: data,
        }),
      );

    default:
      return state;
  }
};
