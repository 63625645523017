import React, { memo } from 'react';
import PropTypes from 'prop-types';

import paths from 'app/paths';
import { actions as adminsActions } from 'ducks/admins';
import { EditLinkButton, RemoveRowAction, RestoreRowAction } from 'components';

const AdminActionsCell = memo((props) => {
  const {
    // eslint-disable-next-line react/prop-types
    item: { id, locked },
  } = props;

  return (
    <>
      <EditLinkButton to={paths.adminsEdit.path} params={{ id }} />
      {locked && <RestoreRowAction action={adminsActions.restoreAdmin} params={{ id }} />}
      {!locked && <RemoveRowAction action={adminsActions.removeAdmin} params={{ id }} />}
    </>
  );
});

AdminActionsCell.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

export default AdminActionsCell;
