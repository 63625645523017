import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import paths from 'app/paths';
import { useAction, usePushAction } from 'app/hooks';
import { formatDate } from 'app/formatters';
import { actions as couponsActions, selectors as couponsSelectors } from 'ducks/coupons';
import { List, Breadcrumbs } from 'components';
import CouponFilter from './CouponFilter';
import PromotionNameCell from './PromotionNameCell';
import CouponActionsCell from './CouponActionsCell';

const CouponList = memo(() => {
  const { t } = useTranslation();
  const { items, meta } = useSelector(couponsSelectors.couponList);
  const fetchCouponList = useAction(couponsActions.fetchCouponList);
  const push = usePushAction();

  const toolbarActions = useMemo(() => {
    return [
      {
        name: 'add',
        label: 'app:add',
        icon: AddCircleIcon,
        confirm: false,
        callback: () => push(paths.couponsAdd.path),
      },
    ];
  }, [push]);

  const rowConfig = useMemo(() => {
    return [
      {
        name: 'barcode',
        label: 'coupons:barcode',
        source: 'barcode',
        sortable: true,
      },
      {
        name: 'cardNumber',
        label: 'clients:cardNumber',
        source: 'account.cardNumber',
        sortable: true,
      },
      {
        name: 'storeNumber',
        label: 'stores:store',
        source: 'account.storeNumber',
      },
      {
        name: 'promotionName',
        label: 'campaigns:promotion',
        component: PromotionNameCell,
        sortable: true,
      },
      {
        name: 'isCashed',
        label: 'coupons:isUsed',
        render: ({ isCashed }) => t(isCashed ? 'app:yes' : 'app:no'),
      },
      {
        name: 'usableAt',
        label: 'coupons:usableAt',
        render: ({ usableAt }) => formatDate(usableAt),
        sortable: true,
      },
      {
        name: 'expiresAt',
        label: 'coupons:expiresAt',
        render: ({ expiresAt }) => formatDate(expiresAt),
        sortable: true,
      },
      {
        name: 'actions',
        label: 'app:actions',
        component: CouponActionsCell,
      },
    ];
  }, [t]);

  return (
    <>
      <Breadcrumbs />
      <List
        data={items}
        meta={meta}
        onFetchData={fetchCouponList}
        filterComponent={CouponFilter}
        rowConfig={rowConfig}
        toolbarActions={toolbarActions}
      />
    </>
  );
});

export default CouponList;
