import React, { memo } from 'react';

import { TextField } from 'components';

const TransactionNumberField = memo((props) => <TextField {...props} />);

TransactionNumberField.propTypes = {
  ...TextField.propTypes,
};

TransactionNumberField.defaultProps = {
  ...TextField.defaultProps,
  name: 'transactionNumber',
  label: 'transactions:transactionNumber',
};

export default TransactionNumberField;
