import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import qs from 'query-string';
import { withFormik, Form } from 'formik';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import { useAction } from 'app/hooks';
import { actions as authActions } from 'ducks/auth';
import { passwordValidator } from 'app/validators';
import { PasswordField, TextHeader, PrimaryButton } from 'components';
import useStyles from './Login.styles';

const ResetPasswordForm = function () {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card>
        <CardContent>
          <Typography className={classes.appName}>{t('app:appName')}</Typography>
          <Typography className={classes.adminInterface}>{t('app:adminInterface')}</Typography>
          <Form>
            <TextHeader text="auth:resetPassword" />
            <PasswordField name="newPassword" label="auth:newPassword" />
            <PasswordField name="newPasswordConfirmation" label="auth:newPasswordConfirmation" />
            <PrimaryButton className={classes.button} label="app:ok" type="submit" size="large" />
          </Form>
        </CardContent>
      </Card>
    </div>
  );
};

const validationSchema = yup.object().shape({
  newPassword: passwordValidator().required('app:required'),
  newPasswordConfirmation: yup
    .string()
    .required('app:required')
    .oneOf([yup.ref('newPassword')], 'auth:passwordsDoNotMatch'),
});

const EnhancedResetForm = withFormik({
  displayName: 'ResetForm',
  validationSchema,
  enableReinitialize: true,
  validateOnBlur: false,
  mapPropsToValues: () => ({
    newPassword: '',
    newPasswordConfirmation: '',
  }),
  handleSubmit: (values, { props: { reset, token } }) => {
    reset(token, values);
  },
})(ResetPasswordForm);

EnhancedResetForm.propTypes = {
  token: PropTypes.string,
  reset: PropTypes.func.isRequired,
};

const Reset = function (props) {
  const reset = useAction(authActions.confirmReset);
  const { search } = useLocation();
  const { token } = qs.parse(search);

  return <EnhancedResetForm {...props} reset={reset} token={token} />;
};

export default Reset;
