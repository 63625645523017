import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

const PropertyRow = memo((props) => {
  const { children, label, spacing, alignItems, labelColumnSize, contentColumnSize, strongLabel } =
    props;
  const { t } = useTranslation();
  return (
    <Grid container spacing={spacing} alignItems={alignItems}>
      <Grid item xs={labelColumnSize}>
        {label && (strongLabel ? <strong>{t(label)}:</strong> : <span>{t(label)}:</span>)}
      </Grid>
      <Grid item xs={contentColumnSize}>
        {children}
      </Grid>
    </Grid>
  );
});

PropertyRow.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  spacing: PropTypes.number,
  alignItems: PropTypes.string,
  labelColumnSize: PropTypes.number,
  contentColumnSize: PropTypes.number,
  strongLabel: PropTypes.bool,
};

PropertyRow.defaultProps = {
  label: undefined,
  children: undefined,
  spacing: 3,
  alignItems: 'center',
  labelColumnSize: 1,
  contentColumnSize: 11,
  strongLabel: true,
};

export default PropertyRow;
