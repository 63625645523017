import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { formatEuro } from 'app/formatters';
import { PropertyRow } from 'components';

const BalanceRow = memo((props) => {
  const {
    entry: {
      item: {
        entryHistory: { balance, amount },
      },
    },
  } = props;
  const newBalance = amount.reduce((r, v) => r + v, balance);

  return (
    <PropertyRow label="transactions:balanceBeforeAfter">
      {formatEuro(balance)} / {formatEuro(newBalance)}
    </PropertyRow>
  );
});

BalanceRow.propTypes = {
  entry: PropTypes.object.isRequired,
};

export default BalanceRow;
