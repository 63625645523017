import React, { memo } from 'react';
import PropTypes from 'prop-types';

import PromotionDetailsGeneral from './PromotionDetailsGeneral';
import PromotionDetailsRules from './PromotionDetailsRules';
import PromotionDetailsPligs from './PromotionDetailsPligs';
import PromotionDetailsAction from './PromotionDetailsAction';

const PromotionDetails = memo((props) => {
  const { promotion } = props;
  return (
    <>
      <PromotionDetailsGeneral promotion={promotion} />
      <PromotionDetailsRules promotion={promotion} />
      <PromotionDetailsPligs promotion={promotion} />
      <PromotionDetailsAction promotion={promotion} />
    </>
  );
});

PromotionDetails.propTypes = {
  promotion: PropTypes.object.isRequired,
};

export default PromotionDetails;
