import * as yup from 'yup';

import { dateValidator, endDateValidator, integerNumberValidator } from 'app/validators';
import { promotionRuleTypes } from '../../../ducks/campaigns/enums';

export const validationSchema = yup.object({
  pligs: yup.object({
    0: yup.array().min(1, 'campaigns:minPligs'),
  }),
  startDate: dateValidator().required('app:required'),
  endDate: endDateValidator().required('app:required'),
  rules: yup.object({
    [promotionRuleTypes.PER_CARD_DISCOUNT_LIMIT]: yup.object({
      configuration: yup.object({
        limit: integerNumberValidator().r(`rules.${promotionRuleTypes.PER_CARD_DISCOUNT_LIMIT}`),
      }),
    }),
  }),
  total1: greaterThanValidator('total1', () => 0),
  total2: greaterThanValidator('total2', function () {
    return this.parent.total1;
  }),
  total3: greaterThanValidator('total3', function () {
    return this.parent.total2;
  }),
  total4: greaterThanValidator('total4', function () {
    return this.parent.total3;
  }),
  total5: greaterThanValidator('total5', function () {
    return this.parent.total4;
  }),
  total6: greaterThanValidator('total6', function () {
    return this.parent.total5;
  }),
  discount1: greaterThanValidator('discount1', () => 0),
  discount2: greaterThanValidator('discount2', function () {
    return this.parent.discount1;
  }),
  discount3: greaterThanValidator('discount3', function () {
    return this.parent.discount2;
  }),
  discount4: greaterThanValidator('discount4', function () {
    return this.parent.discount3;
  }),
  discount5: greaterThanValidator('discount5', function () {
    return this.parent.discount4;
  }),
  discount6: greaterThanValidator('discount6', function () {
    return this.parent.discount5;
  }),
});

/**
 * Custom yup validation function
 * Uses getComparisonValue function to get value to compare against
 * @param {string} path - checked field path
 * @param {() => number} getComparisonValue - function that return value to compare against
 * @returns yup validator
 */
function greaterThanValidator(path, getComparisonValue) {
  return yup
    .number()
    .required('app:required')
    .typeError('app:invalidNumber')
    .test('maxDecimalPlaces', 'app:invalidNumber', (number) =>
      number ? /^\d+([.,]\d{1,2})?$/.test(number) : true,
    )
    .test({
      name: `${path}Validation`,
      test(value) {
        const comparisonValue = getComparisonValue.call(this);

        if (value < comparisonValue) {
          return this.createError({
            path,
            message: {
              text: 'campaigns:special:error',
              data: { value: comparisonValue },
            },
          });
        }

        return true;
      },
    });
}
