import { makeStyles } from '@material-ui/core/styles';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField } from '@mui/material';
import { formatDate } from 'app/formatters';
import PropTypes from 'prop-types';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr';

const getCurrentLocale = (language) => {
  switch (language) {
    case 'fr':
      return frLocale;
    case 'en':
      return enLocale;
    default:
      return enLocale;
  }
};

const EMPTY_DATE = '__/__/____';

const useStylesInlineEdit = makeStyles({
  span: {
    letterSpacing: 1,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 16,
  },
  textField: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  error: {
    marginTop: 5,
    maxWidth: 265,
    fontSize: 17,
    fontWeight: '400',
    color: 'red',
  },
});

export const EditInlineData = memo(({ index, isEditMode, isStart, isEditable }) => {
  const styles = useStylesInlineEdit();
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const fieldName = `loyaltyPeriods[${index}].${isStart ? 'startsAt' : 'endsAt'}`;

  return (
    <Field name={fieldName} type="date">
      {({ field: { value }, form: { setFieldValue }, meta: { error } }) => {
        const initValue = String(value);
        const [dateValue, setDateValue] = useState(initValue || null);
        const [isEdit, setIsEdit] = useState(isEditMode);
        const [isEditableComponent] = useState(isEditable);

        const handleOnChange = (newValue) => {
          setDateValue(newValue);
          setFieldValue(fieldName, newValue);
        };

        useEffect(() => {
          setDateValue(String(value));
        }, [value]);

        useEffect(() => {
          setIsEdit(isEditMode);
        }, [isEditMode]);

        return isEdit && isEditableComponent ? (
          <LocalizationProvider locale={getCurrentLocale(language)} dateAdapter={AdapterDateFns}>
            <DatePicker
              mask="__/__/____"
              inputFormat="dd/mm/yyyy"
              onChange={handleOnChange}
              value={dateValue}
              renderInput={(props) => {
                const preparedProps = {
                  ...props,
                  inputProps: {
                    placeholder: 'dd/mm/yyyy',
                    sx: {
                      padding: '8px 14px',
                    },
                  },
                };

                return (
                  <div className={styles.textField}>
                    <TextField
                      {...preparedProps}
                      value={formatDate(new Date(dateValue))}
                      helperText={null}
                    />
                    {error && isEditable && <p className={styles.error}>{t(error)}</p>}
                  </div>
                );
              }}
            />
          </LocalizationProvider>
        ) : (
          <div>
            <span className={styles.span}>{formatDate(new Date(dateValue)) || EMPTY_DATE}</span>
            {error && isEditable && <p className={styles.error}>{t(error)}</p>}
          </div>
        );
      }}
    </Field>
  );
});

EditInlineData.propTypes = {
  isStart: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
};
