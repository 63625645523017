import React, { memo, useCallback } from 'react';

import { useAction } from 'app/hooks';
import { actions as storesActions } from 'ducks/stores';
import { Breadcrumbs } from 'components';

import StoreForm from './StoreForm';
import validationSchema from './validationSchema';

const StoreAdd = memo(() => {
  const addStore = useAction(storesActions.addStore);

  const onSubmit = useCallback(
    (values) => {
      addStore(values);
    },
    [addStore],
  );

  return (
    <>
      <Breadcrumbs />
      <StoreForm
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        store={{ newPosAmount: 15 }}
      />
    </>
  );
});

export default StoreAdd;
