/* eslint-disable */
import { schema } from 'normalizr';

import { mapListRequest, mapListResponse } from 'app/mappers';
import { errorTypes as appErrorTypes } from 'ducks/app';
import * as couponsErrorTypes from './errorTypes';
import { mapCoupon } from './entityMappers';

const couponSchema = new schema.Entity('coupons');
const couponListSchema = [couponSchema];

export const mapFetchCouponListRequest = (params) => {
  return mapListRequest(params, {
    scope: 'coupon_full',
  });
};

export const mapFetchCouponListResponse = (data) => {
  return mapListResponse(data, {
    entityName: 'coupons',
    entityMapper: mapCoupon,
    schema: couponListSchema,
  });
};

export const mapDeactivateCouponError = (e) => {
  e.type = appErrorTypes.CANNOT_DEACTIVATE;
  throw e;
};

export const mapCreateCouponRequest = (data) => {
  const { storeNumber, promotionId, quantity, cardNumbers } = data;
  return {
    store: storeNumber,
    promotion: promotionId,
    count: quantity,
    cards: cardNumbers.replace(' ', '').split(','),
  };
};

export const mapCreateCouponError = (e) => {
  const {
    response: {
      data: { children },
    },
  } = e;
  const { store = {}, cards = {}, promotion = {} } = children;

  if (store.errors) {
    e.message = 'stores:invalidStoreNumber';
  } else if (cards.errors) {
    e.message = cards.errors[0];
  } else if (promotion.errors) {
    e.message = 'campaigns:invalidPromotionId';
  }

  e.type = couponsErrorTypes.CANNOT_CREATE_COUPON;
  throw e;
};
