import paths from 'app/paths';
import ClientView from 'components/clients/ClientView';
import ClientList from 'components/clients/ClientList/ClientList';

export default [
  {
    path: paths.clientView.path,
    roles: paths.clientView.roles,
    component: ClientView,
  },
  {
    path: paths.clients.path,
    roles: paths.clients.roles,
    component: ClientList,
  },
];
