import axios from 'axios';

import * as mappers from './serviceMappers';

export function fetchStoreList(params) {
  return axios
    .get('stores/search', { params: mappers.mapFetchStoreListRequest(params) })
    .then((response) => mappers.mapFetchStoreListResponse(response.data));
}

export function fetchOnlinePercentage(params) {
  return axios
    .get('stores/online-percentage', { params: mappers.mapFetchOnlinePercentageRequest(params) })
    .then((response) => mappers.mapFetchOnlinePercentageResponse(response.data));
}

export function fetchStore(id) {
  const scope = 'store_full';

  return axios
    .get(`stores/${id}`, { params: { scope } })
    .then((response) => mappers.mapFetchStoreResponse(response.data));
}

export function updateStore(id, data) {
  return axios
    .patch(`stores/${id}`, mappers.mapStoreRequest(data))
    .catch(mappers.mapUpdateStoreError);
}

export function addStore(data) {
  return axios.post('stores/', mappers.mapStoreRequest(data)).catch(mappers.mapAddStoreError);
}

export function activateStore(id) {
  return axios.post(`stores/${id}/enable`).catch(mappers.mapActivateStoreError);
}

export function deactivateStore(id) {
  return axios.post(`stores/${id}/disable`).catch(mappers.mapDeactivateStoreError);
}

export function enableStoreReporting(id) {
  return axios.put(`stores/${id}/reporting`).catch(mappers.mapActivateStoreError);
}

export function disableStoreReporting(id) {
  return axios.delete(`stores/${id}/reporting`).catch(mappers.mapDeactivateStoreError);
}

export function fetchPriceGroups() {
  return axios
    .get('price-groups', { params: { paginate: false } })
    .then((response) => mappers.mapFetchPriceGroupsResponse(response.data));
}

export function generateDailyReport(data) {
  return axios
    .post(`stores/generate-report-daily`, mappers.mapGenerateDailyReportRequest(data))
    .catch(mappers.mapGenerateReportError);
}

export function generateMonthlyReport(data) {
  return axios
    .post(`stores/generate-report-monthly`, mappers.mapGenerateMonthlyReportRequest(data))
    .catch(mappers.mapGenerateReportError);
}

export function createLoyaltyDisabledPeriod({ store, startsAt, endsAt }) {
  return axios.post('loyalty-disable-period/', { store, startsAt, endsAt });
}

export function updateLoyaltyDisabledPeriod({ periodId, startsAt, endsAt }) {
  return axios.patch(`loyalty-disable-period/${periodId}`, { startsAt, endsAt });
}

export function removeLoyaltyDisabledPeriod(periodId) {
  return axios.delete(`loyalty-disable-period/${periodId}`);
}

export function uploadCardsFileForStore(file, storeNumber) {
  const formData = new FormData();
  formData.append('file', file);

  if (storeNumber) {
    formData.append('storeNumber', storeNumber);
  }

  const config = {
    headers: { 'Content-Type': 'application/json' },
  };

  return axios.post('cards/from-file', formData, config).catch(mappers.mapUploadCardsForStoreError);
}
