import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import values from 'lodash/values';

import { promotionActionTypes } from 'ducks/campaigns/enums';

const getActionName = (action) => {
  const {
    type,
    configuration: { neutral },
  } = action;
  switch (type) {
    case promotionActionTypes.FIXED_DISCOUNT:
    case promotionActionTypes.PERCENTAGE_DISCOUNT:
    case promotionActionTypes.FIXED_TOTAL_DISCOUNT:
      return neutral ? 'campaigns:delayed' : 'campaigns:immediate';
    case promotionActionTypes.FIN_DISCOUNT:
      return 'campaigns:delayed';
    case promotionActionTypes.DYNAMIC_DISCOUNT:
      return 'campaigns:dynamic';
    default:
      return null;
  }
};

const DiscountTypeCell = memo((props) => {
  const {
    item: { actions },
  } = props;
  const { t } = useTranslation();

  const renderAction = useCallback(
    (data) => {
      return <div key={data.id}>{t(getActionName(data))}</div>;
    },
    [t],
  );

  return <>{values(actions).map(renderAction)}</>;
});

DiscountTypeCell.propTypes = {
  item: PropTypes.object.isRequired,
};

export default DiscountTypeCell;
