import { fromJS } from 'immutable';

import * as actionTypes from './actionTypes';

const initialState = fromJS({
  transactionList: {
    order: [],
    items: {},
    meta: { page: 1, limit: 10, total: 0 },
  },
  viewingTransaction: {},
});

export default (state = initialState, { type, data }) => {
  switch (type) {
    case actionTypes.FETCH_TRANSACTION_LIST_SUCCESS:
      return state.merge(fromJS({ transactionList: data }));

    case actionTypes.FETCH_TRANSACTION_SUCCESS:
      return state.merge(fromJS({ viewingTransaction: data }));

    default:
      return state;
  }
};
