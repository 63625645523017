import findIndex from 'lodash/findIndex';

import { mapClient, mapCard } from 'ducks/clients/entityMappers';
import { mapPlig } from 'ducks/products/entityMappers';
import { mapStore, mapPos } from 'ducks/stores/entityMappers';

export const mapAdjustment = (data) => {
  const { id, neutral, description, amount } = data;
  return {
    id,
    neutral,
    description,
    amount: neutral ? Math.abs(amount) : amount,
  };
};

export const mapTransactionItems = (data) => {
  const items = [];

  data.forEach((itemData) => {
    const { id, label, quantity, unitPrice, total, plig = {}, embedded = {} } = itemData;
    const { adjustments = [] } = embedded;

    const { neutralAdjustments, notNeutralAdjustments } = adjustments.reduce(
      (acc, current) => {
        const { neutral } = current;
        const key = neutral ? 'neutralAdjustments' : 'notNeutralAdjustments';
        return {
          ...acc,
          [key]: [...acc[key], mapAdjustment(current)],
        };
      },
      { neutralAdjustments: [], notNeutralAdjustments: [] },
    );

    const item = {
      id,
      label,
      quantity,
      unitPrice,
      total,
      plig: mapPlig(plig),
      neutralAdjustments,
      notNeutralAdjustments,
    };

    const index = findIndex(items, (processedItem) => {
      const currentPlig = item.plig.value;
      const processedPlig = processedItem.plig.value;
      return currentPlig && processedPlig && currentPlig === processedPlig;
    });

    if (index === -1) {
      items.push(item);
    } else {
      items[index].neutralAdjustments = [
        ...items[index].neutralAdjustments,
        ...item.neutralAdjustments,
      ];
      items[index].notNeutralAdjustments = [
        ...items[index].notNeutralAdjustments,
        ...item.notNeutralAdjustments,
      ];
      items[index].quantity += item.quantity;
      items[index].unitPrice += item.unitPrice;
      items[index].total += item.total;
    }
  });

  return items;
};

export const mapTransaction = (data) => {
  const { id, number, total, completedAt, pos = {}, embedded = {} } = data;
  const { store = {} } = pos;
  const { items } = embedded;
  const card = embedded.card ? embedded.card : {};
  const { embedded: { owner: client = {} } = {} } = card;

  return {
    id: parseInt(id),
    number,
    total,
    completedAt,
    pos: mapPos(pos),
    store: mapStore(store),
    client: mapClient(client),
    card: mapCard(card),
    items: items ? mapTransactionItems(items) : [],
  };
};
