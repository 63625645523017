import React, { memo } from 'react';

import { TextField } from 'components';

const CampaignNameField = memo((props) => <TextField {...props} />);

CampaignNameField.propTypes = {
  ...TextField.propTypes,
};

CampaignNameField.defaultProps = {
  ...TextField.defaultProps,
  name: 'campaignName',
  label: 'campaigns:campaignName',
};

export default CampaignNameField;
