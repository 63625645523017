import React, { memo } from 'react';

import { TextField } from 'components';

const CardNumberField = memo((props) => <TextField {...props} />);

CardNumberField.propTypes = {
  ...TextField.propTypes,
};

CardNumberField.defaultProps = {
  ...TextField.defaultProps,
  name: 'cardNumber',
  label: 'clients:cardNumber',
};

export default CardNumberField;
