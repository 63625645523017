import React, { memo, useState, useEffect, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import MaterialTable from '@material-ui/core/Table';
import Typography from '@material-ui/core/Typography';

import { ListContext } from './_constants';
import Toolbar from './Toolbar';
import TableHead from './TableHead';
import TableBody from './TableBody';

const Table = memo(() => {
  const { t } = useTranslation();
  const { data, rowIdSource, batchActions } = useContext(ListContext);
  const [selectedItems, setSelectedItems] = useState(batchActions ? [] : null);
  const [selectAll, setSelectAll] = useState(batchActions ? false : null);

  useEffect(() => {
    if (batchActions) {
      setSelectedItems([]);
      setSelectAll(false);
    }
  }, [data, batchActions]);

  useEffect(() => {
    if (selectAll) {
      setSelectedItems(data.map((item) => item[rowIdSource]));
    } else if (selectAll === false) {
      setSelectedItems([]);
    }
  }, [selectAll, data, rowIdSource]);

  const selectItem = useCallback(
    (id, value) => {
      if (value) {
        setSelectedItems([...selectedItems, id]);
      } else {
        setSelectedItems(selectedItems.filter((item) => item !== id));
      }
    },
    [selectedItems],
  );

  const noData = useMemo(() => {
    return (
      <Typography variant="h5" align="center" color="textSecondary">
        {t('app:noData')}
      </Typography>
    );
  }, [t]);

  return (
    <>
      <Toolbar selectedItems={selectedItems} />
      <MaterialTable size="small">
        <TableHead selectAll={selectAll} onSelectAll={setSelectAll} />
        <TableBody selectedItems={selectedItems} onSelectItem={selectItem} />
      </MaterialTable>
      {isEmpty(data) && noData}
    </>
  );
});

export default Table;
