import React, { memo } from 'react';
import { Filter, UsernameField, EmailField, RoleField, LockedField } from 'components';
import { useSelector } from 'react-redux';
import { selectors as authSelectors } from '../../../ducks/auth';

const AdminFilter = memo(() => {
  return (
    <Filter
      initialValues={{
        username: '',
        email: '',
        role: '',
        locked: false,
      }}
      mainField={<UsernameField />}
    >
      <UsernameField />
      <EmailField />
      <RoleField allowEmpty />
      {useSelector(authSelectors.isSuperAdmin) && <LockedField />}
    </Filter>
  );
});

export default AdminFilter;
