import * as yup from 'yup';

import { dateValidator, endDateValidator } from 'app/validators';

export const validationSchema = yup.object({
  pligs: yup.object({
    0: yup.array().min(1, 'campaigns:minPligs'),
  }),
  startDate: dateValidator().required('app:required'),
  endDate: endDateValidator().required('app:required'),
  y1: greaterThanValidator('y1', () => 0),
  y2: greaterThanValidator('y2', function () {
    return this.parent.y1;
  }),
  y3: greaterThanValidator('y3', function () {
    return this.parent.y2;
  }),
});

/**
 * Custom yup validation function
 * Uses getComparisonValue function to get value to compare against
 * @param {string} path - checked field path
 * @param {() => number} getComparisonValue - function that return value to compare against
 * @returns yup validator
 */
function greaterThanValidator(path, getComparisonValue) {
  return yup
    .number()
    .required('app:required')
    .typeError('app:invalidNumber')
    .test('maxDecimalPlaces', 'app:invalidNumber', (number) =>
      number ? /^\d+([.,]\d{1,2})?$/.test(number) : true,
    )
    .test({
      name: `${path}Validation`,
      test(value) {
        const comparisonValue = getComparisonValue.call(this);

        if (value < comparisonValue) {
          return this.createError({
            path,
            message: {
              text: 'campaigns:special:error',
              data: { value: comparisonValue },
            },
          });
        }

        return true;
      },
    });
}
