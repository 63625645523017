import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';

const AdminGroupSearchInput = memo((props) => {
  const { t } = useTranslation();
  return (
    <TextField label={t('app:search')} variant="outlined" margin="dense" fullWidth {...props} />
  );
});

export default AdminGroupSearchInput;
