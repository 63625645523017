import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import map from 'lodash/map';
import filter from 'lodash/filter';
import Grid from '@material-ui/core/Grid';

import { useAction } from 'app/hooks';
import { selectors as authSelectors } from 'ducks/auth';
import { actions as modalsActions, modalTypes } from 'ducks/modals';
import { actions as productsActions } from 'ducks/products';
import { EanActField, AddButton, RemoveButton } from 'components';
import useStyles from './ProductEans.styles';

const validationSchema = yup.object().shape({
  ean: yup.string().required('app:required'),
});

const ProductEans = memo(({ productId, productEans }) => {
  const classes = useStyles();
  const isAppAdmin = useSelector(authSelectors.isAppAdmin);
  const showModal = useAction(modalsActions.showModal);
  const updateEans = useAction(productsActions.updateEans);

  const addEan = useCallback(
    ({ ean: newEan }) => {
      updateEans(productId, 'app:added', {
        newEan,
        existingEans: productEans,
      });
    },
    [updateEans, productId, productEans],
  );

  const removeEan = useCallback(
    (ean) => {
      showModal(modalTypes.CONFIRMATION_MODAL, {
        onOk: () =>
          updateEans(productId, 'app:removed', {
            existingEans: filter(productEans, ({ value }) => value !== ean),
          }),
      });
    },
    [showModal, updateEans, productId, productEans],
  );

  const renderEan = useCallback(
    ({ value: ean }) => {
      return (
        <div className={classes.eanRow} key={ean}>
          {ean}
          {isAppAdmin && (
            <RemoveButton
              className={classes.removeButton}
              onClick={() => removeEan(ean)}
              size="small"
            />
          )}
        </div>
      );
    },
    [removeEan, classes, isAppAdmin],
  );

  return (
    <div>
      {isAppAdmin && (
        <Formik
          initialValues={{ ean: '' }}
          validationSchema={validationSchema}
          onSubmit={addEan}
          enableReinitialize
          validateOnBlur={false}
        >
          {() => (
            <Form>
              <Grid container>
                <Grid item xs={2}>
                  <EanActField />
                </Grid>
                <Grid item xs={10}>
                  <AddButton className={classes.addButton} type="submit" />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
      {map(productEans, renderEan)}
    </div>
  );
});

ProductEans.propTypes = {
  productId: PropTypes.number,
  productEans: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
    }),
  ),
};

ProductEans.defaultProps = {
  productId: undefined,
  productEans: [],
};

export default ProductEans;
