import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';

import { joinNotEmpty } from 'app/utils';
import { mapStore } from 'ducks/stores/entityMappers';

export const mapAccount = (data) => {
  const { id, cardNumber, storeNumber, balance, embedded = {} } = data;
  const { store } = embedded;
  return {
    id,
    cardNumber,
    storeNumber,
    balance,
    store: mapStore(store || {}),
  };
};

export const mapCard = (data) => {
  const { id, number, status, embedded = {} } = data;
  const { accounts = [] } = embedded;
  return {
    id,
    number,
    enabled: status === '1',
    accounts: accounts.map(mapAccount),
  };
};

export const addressToString = (address) => {
  return joinNotEmpty([address.houseNumber, address.street, address.postalCode, address.city]);
};

export const mapAddress = (data) => {
  const { houseNumber, streetName: street, postcode: postalCode, town: city, country } = data;
  const address = {
    houseNumber,
    street,
    postalCode,
    city,
    country,
  };
  address.addressString = addressToString(address);
  return address;
};

export const mapClient = (data) => {
  const {
    id,
    firstName,
    lastName,
    birthday,
    deletedAt,
    email,
    mobileNumber: phoneNumber,
    gender = {},
    address = {},
    embedded = {},
  } = data;
  const { user = {} } = embedded;

  let { cards = [] } = embedded;
  if (!isArray(cards) && !isEmpty(cards)) {
    cards = values(cards);
  }

  return {
    id: parseInt(id),
    firstName,
    lastName,
    birthday,
    isDeleted: !!deletedAt,
    email,
    phoneNumber,
    gender,
    user,
    isUserRelated: !!user.id,
    address: mapAddress(address),
    cards: cards.map(mapCard),
  };
};
