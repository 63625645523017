import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import { useAction } from 'app/hooks';
import { selectors as appSelectors } from 'ducks/app';
import { actions as modalsActions } from 'ducks/modals';
import { PrimaryButton, SecondaryButton } from 'components';

const Modal = function ({
  show,
  children,
  titleText,
  bodyText,
  okText,
  cancelText,
  onOk,
  disableOk,
  hideAfterOk,
  clearAfterHide,
  ...rest
}) {
  const { t } = useTranslation();
  const loading = useSelector(appSelectors.loading);
  const hideModal = useAction(modalsActions.hideModal);
  const clearModal = useAction(modalsActions.clearModal);

  const handleOk = useCallback(() => {
    if (onOk) {
      onOk();
    }
    if (hideAfterOk) {
      hideModal();
    }
  }, [onOk, hideAfterOk, hideModal]);

  const handleExited = useCallback(() => {
    if (clearAfterHide) {
      clearModal();
    }
  }, [clearAfterHide, clearModal]);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        handleOk();
      }
    },
    [handleOk],
  );

  const handleOnClose = useCallback(() => {
    hideModal();
    handleExited();
  }, []);

  return (
    <Dialog
      open={show}
      onClose={handleOnClose}
      onKeyDown={handleKeyDown}
      disableEscapeKeyDown={false}
      fullWidth
      {...rest}
    >
      <div className={loading ? 'blocked' : ''}>
        <DialogTitle>{t(titleText)}</DialogTitle>
        <DialogContent>
          {children || <DialogContentText>{t(bodyText)}</DialogContentText>}
        </DialogContent>
        <DialogActions>
          <PrimaryButton label={okText} onClick={handleOk} disabled={disableOk} />
          <SecondaryButton label={cancelText} onClick={handleOnClose} />
        </DialogActions>
      </div>
    </Dialog>
  );
};

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.element,
  titleText: PropTypes.string,
  bodyText: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  onOk: PropTypes.func,
  disableOk: PropTypes.bool,
  hideAfterOk: PropTypes.bool,
  clearAfterHide: PropTypes.bool,
};

Modal.defaultProps = {
  children: null,
  titleText: '',
  bodyText: '',
  okText: 'app:ok',
  cancelText: 'app:cancel',
  onOk: null,
  disableOk: false,
  hideAfterOk: false,
  clearAfterHide: true,
};

export default Modal;
