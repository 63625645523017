import React, { memo } from 'react';

import { TextField } from 'components';

const BarcodeField = memo((props) => <TextField {...props} />);

BarcodeField.propTypes = {
  ...TextField.propTypes,
};

BarcodeField.defaultProps = {
  ...TextField.defaultProps,
  name: 'barcode',
  label: 'coupons:barcode',
};

export default BarcodeField;
