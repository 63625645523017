import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useAction } from 'app/hooks';
import { actions as modalsActions, modalTypes } from 'ducks/modals';
import { actions as clientsActions } from 'ducks/clients';
import { Panel, SecondaryButton, Caption } from 'components/index';

const ClientDeactivate = memo((props) => {
  const {
    client: {
      id: clientId,
      user: { id: userId },
    },
  } = props;
  const deactivateClient = useAction(clientsActions.deactivateClient);
  const showModal = useAction(modalsActions.showModal);

  const handleDeactivate = useCallback(() => {
    showModal(modalTypes.CONFIRMATION_MODAL, {
      onOk: () => deactivateClient(userId, clientId),
    });
  }, [userId, clientId, deactivateClient, showModal]);

  return (
    <Panel title="app:deactivate" defaultExpanded={false}>
      <Caption text="clients:deactivationWarning" color="secondary" />
      <SecondaryButton label="app:deactivate" onClick={handleDeactivate} />
    </Panel>
  );
});

ClientDeactivate.propTypes = {
  client: PropTypes.object.isRequired,
};

export default ClientDeactivate;
