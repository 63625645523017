import * as actionTypes from './actionTypes';

export const fetchTransactionList = (params) => ({
  type: actionTypes.FETCH_TRANSACTION_LIST,
  params,
});

export const fetchTransactionListSuccess = (data) => ({
  type: actionTypes.FETCH_TRANSACTION_LIST_SUCCESS,
  data,
});

export const fetchTransaction = (id) => ({
  type: actionTypes.FETCH_TRANSACTION,
  id,
});

export const fetchTransactionSuccess = (data) => ({
  type: actionTypes.FETCH_TRANSACTION_SUCCESS,
  data,
});
