import React, { memo } from 'react';

import TextField from './TextField';

const LastNameField = memo((props) => <TextField {...props} />);

LastNameField.propTypes = {
  ...TextField.propTypes,
};

LastNameField.defaultProps = {
  ...TextField.defaultProps,
  name: 'lastName',
  label: 'app:lastName',
};

export default LastNameField;
