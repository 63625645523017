export const CHECK_AUTH = 'auth/CHECK_AUTH';
export const CHECK_AUTH_SUCCESS = 'auth/CHECK_AUTH_SUCCESS';

export const LOGIN = 'auth/LOGIN';
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';

export const LOGOUT = 'auth/LOGOUT';
export const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS';

export const FETCH_USER = 'auth/FETCH_USER';
export const FETCH_USER_SUCCESS = 'auth/FETCH_USER_SUCCESS';

export const CHANGE_PASSWORD = 'auth/CHANGE_PASSWORD';

export const RESET_PASSWORD = 'auth/RESET_PASSWORD';

export const RESET_PASSWORD_CONFIRM = 'auth/RESET_PASSWORD_CONFIRM';
