import { schema } from 'normalizr';

import { mapListRequest, mapListResponse } from 'app/mappers';
import { errorTypes as appErrorTypes } from 'ducks/app';
import { mapPlig, mapEan } from './entityMappers';

const productSchema = new schema.Entity('products');
const productListSchema = [productSchema];

export const mapFetchProductListRequest = (params) => {
  return mapListRequest(params, {
    scope: 'product_full,countable_eans',
  });
};

export const mapFetchProductListResponse = (data) => {
  return mapListResponse(data, {
    entityName: 'products',
    entityMapper: mapPlig,
    schema: productListSchema,
  });
};

export const mapFetchProductResponse = (data) => {
  return mapPlig(data);
};

export const mapFetchEansRequest = ({ ean }) => {
  return {
    ean,
    free_ean: true,
  };
};

export const mapFetchEansResponse = (data) => {
  const {
    embedded: { items },
  } = data;
  return items.map(mapEan);
};

export const mapUpdateEansRequest = ({ newEan, existingEans }) => {
  const params = { eans: [...existingEans.map((ean) => ean.value)] };
  if (newEan) {
    params.eans.push(newEan);
  }
  return params;
};

export const mapUpdateEansError = (e) => {
  e.type = appErrorTypes.CANNOT_UPDATE;
  throw e;
};
