import paths from 'app/paths';
import AdminAdd from 'components/admins/AdminView/AdminAdd';
import AdminEdit from 'components/admins/AdminView/AdminEdit';
import AdminList from 'components/admins/AdminList/AdminList';

export default [
  {
    path: paths.adminsAdd.path,
    roles: paths.adminsAdd.roles,
    component: AdminAdd,
  },
  {
    path: paths.adminsEdit.path,
    roles: paths.adminsEdit.roles,
    component: AdminEdit,
  },
  {
    path: paths.admins.path,
    roles: paths.admins.roles,
    component: AdminList,
  },
];
