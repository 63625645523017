import { AddButton, Panel } from 'components/shared';
import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, FieldArray } from 'formik';
import { LoyaltyDisablePeriod } from 'components/stores/StoreView/LoyaltyDisablePeriod';
import { makeStyles } from '@material-ui/core/styles';

import * as yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  addDiv: {
    display: 'flex',
    padding: [10, 0],
    justifyContent: 'flex-end',
    '& svg': {
      width: 30,
      height: 30,
    },
  },
  button: {
    marginTop: 10,
  },
  error: {
    fontSize: 17,
    color: 'red',
    fontWeight: 400,
  },
});

const initialPeriods = {
  startsAt: new Date(),
  endsAt: new Date(),
};

const areIntersected = (dates, dates1) => {
  const milliSecondsInDay = 86400000;
  let firstDate;
  let lastDate;

  if (dates.startsAt.getTime() < dates1.startsAt.getTime()) {
    firstDate = dates;
    lastDate = dates1;
  } else {
    firstDate = dates1;
    lastDate = dates;
  }

  return (
    Math.floor(lastDate.startsAt.getTime() / milliSecondsInDay) <=
    Math.floor(firstDate.endsAt.getTime() / milliSecondsInDay) - 1
  );
};

const handleOnValidate = (values) => {
  const errors = {};

  if (values?.loyaltyPeriods?.length > 0) {
    const periods = values.loyaltyPeriods;

    periods.forEach((elem1, index1) => {
      // eslint-disable-next-line consistent-return
      periods.forEach((elem2, index2) => {
        if (index1 !== index2 && areIntersected(elem1, elem2)) {
          errors.commonError = 'stores:periodsShouldNotOverlap';

          return errors;
        }
      });
    });
  }
  return errors;
};

const validationSchema = yup.object({
  loyaltyPeriods: yup.array().of(
    yup.object({
      startsAt: yup
        .date()
        .min(moment(), 'stores:startAtShouldBeGreaterThanCurrentDate')
        .required('stores:startAtIsRequired'),
      endsAt: yup
        .date()
        .when(
          'startsAt',
          (startsAt, schema) =>
            startsAt &&
            schema.min(moment(startsAt).add(1, 'day'), 'stores:endAtGreaterThanStartAt'),
        )
        .required('stores:endAtIsRequired'),
    }),
  ),
});

const getPreparedLoyaltyPeriods = (loyaltyDisablePeriods) => {
  const preparedArray = [];

  if (loyaltyDisablePeriods?.length) {
    loyaltyDisablePeriods.map(({ startsAt, endsAt, id }) =>
      preparedArray.push({
        startsAt: new Date(startsAt),
        endsAt: new Date(endsAt),
        id,
      }),
    );
  }

  preparedArray.sort((prev, next) => (prev.startsAt.getTime() > next.startsAt.getTime() ? 1 : -1));
  return preparedArray;
};

export const StoreLoyaltyDisablePeriods = memo(({ loyaltyDisabledPeriods, storeId }) => {
  const [initialValues, setInitialState] = useState({
    loyaltyPeriods: getPreparedLoyaltyPeriods(loyaltyDisabledPeriods),
  });

  const { t } = useTranslation();

  useEffect(() => {
    setInitialState({
      loyaltyPeriods: getPreparedLoyaltyPeriods(loyaltyDisabledPeriods),
    });
  }, [loyaltyDisabledPeriods, loyaltyDisabledPeriods?.length, setInitialState]);

  const styles = useStyles();

  const handleOnSubmit = () => {};

  return (
    <Panel title="stores:loyaltyDisablePeriods" defaultExpanded={false}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validate={handleOnValidate}
        onSubmit={handleOnSubmit}
      >
        {({ values, errors, resetForm }) => {
          const [loyaltyPeriods, setLoyaltyPeriods] = useState([]);

          useEffect(() => {
            resetForm({ values: initialValues });
          }, [initialValues, resetForm]);

          useEffect(() => {
            if (values.loyaltyPeriods) {
              setLoyaltyPeriods([...values.loyaltyPeriods]);
            }
          }, [values]);

          return (
            <Form>
              <FieldArray name="loyaltyPeriods">
                {(arrayHelpers) => (
                  <div className={styles.root}>
                    {loyaltyPeriods.map((period, index) => (
                      <LoyaltyDisablePeriod
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        errors={errors}
                        id={index}
                        isNew={!period.id}
                        storeId={storeId}
                        value={period}
                        onDelete={() => {
                          arrayHelpers.remove(index);
                        }}
                      />
                    ))}
                    <div className={styles.addDiv}>
                      <AddButton
                        title={t`stores:addPeriod`}
                        onClick={() => arrayHelpers.push(initialPeriods)}
                      />
                    </div>
                    {!!errors.commonError && (
                      <p className={styles.error}>{t(errors.commonError)}</p>
                    )}
                  </div>
                )}
              </FieldArray>
            </Form>
          );
        }}
      </Formik>
    </Panel>
  );
});

StoreLoyaltyDisablePeriods.propTypes = {
  storeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loyaltyDisabledPeriods: PropTypes.array,
};

StoreLoyaltyDisablePeriods.defaultProps = {
  storeId: '',
  loyaltyDisabledPeriods: [],
};
