import React, { memo } from 'react';

import TextField from './TextField';

const FirstNameField = memo((props) => <TextField {...props} />);

FirstNameField.propTypes = {
  ...TextField.propTypes,
};

FirstNameField.defaultProps = {
  ...TextField.defaultProps,
  name: 'firstName',
  label: 'app:firstName',
};

export default FirstNameField;
