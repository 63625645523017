import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { generatePath } from 'app/utils';
import { usePushAction } from 'app/hooks';

const LinkButton = memo((props) => {
  const { component: Component, to, params, queryParams, ...rest } = props;
  const push = usePushAction();

  const onClick = useCallback(() => {
    push(generatePath(to, params, queryParams));
  }, [push, to, params, queryParams]);

  return <Component onClick={onClick} size="small" {...rest} />;
});

LinkButton.propTypes = {
  component: PropTypes.object.isRequired,
  to: PropTypes.string.isRequired,
  params: PropTypes.object,
  queryParams: PropTypes.object,
};

LinkButton.defaultProps = {
  params: {},
  queryParams: {},
};

export default LinkButton;
