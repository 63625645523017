import { schema } from 'normalizr';
import moment from 'moment';
import map from 'lodash/map';
import keys from 'lodash/keys';
import values from 'lodash/values';

import { toSnakeCase } from 'app/utils';
import { mapListRequest, mapListResponse } from 'app/mappers';
import { errorTypes as appErrorTypes } from 'ducks/app';
import { errorTypes as storesErrorTypes } from 'ducks/stores';
import { mapStore } from './entityMappers';

const storeSchema = new schema.Entity('stores');
const storeListSchema = [storeSchema];

export const mapFetchStoreListRequest = (params) => {
  return mapListRequest(params, {
    scope: 'store_full',
    filterMap: { priceGroup: 'price_list' },
    sortMap: { priceGroup: 'price_template_name' },
  });
};

export const mapFetchStoreListResponse = (data) => {
  return mapListResponse(data, {
    entityName: 'stores',
    entityMapper: mapStore,
    schema: storeListSchema,
  });
};

export const mapFetchOnlinePercentageRequest = ({ startDate, endDate }) => {
  return toSnakeCase({
    startDate,
    endDate,
  });
};

export const mapFetchOnlinePercentageResponse = (data) => {
  return {
    items: map(data, values),
    columns: data[0] ? keys(toSnakeCase(data[0])) : [],
  };
};

export const mapFetchStoreResponse = (data) => {
  return mapStore(data);
};

export const mapStoreRequest = ({
  name,
  number,
  posAmount,
  newPosAmount,
  priceGroup,
  phoneNumber,
  firstName,
  lastName,
  houseNumber,
  street,
  postalCode,
  city,
  country,
}) => {
  const contact = { firstName, lastName };
  const address = { streetNumber: houseNumber, street, postCode: postalCode, city, country };
  posAmount = newPosAmount;
  return {
    name,
    number,
    posAmount,
    priceCategory: priceGroup,
    phoneNumber,
    contact,
    address,
  };
};

export const mapUpdateStoreError = (e) => {
  e.type = appErrorTypes.CANNOT_UPDATE;
  throw e;
};

export const mapAddStoreError = (e) => {
  e.type = appErrorTypes.CANNOT_ADD;
  throw e;
};

export const mapActivateStoreError = (error) => {
  error.type = appErrorTypes.CANNOT_ACTIVATE;
  throw error;
};

export const mapDeactivateStoreError = (error) => {
  error.type = appErrorTypes.CANNOT_DEACTIVATE;
  throw error;
};

export const mapFetchPriceGroupsResponse = (data) => {
  return map(data, (item) => item.label);
};

export const mapGenerateDailyReportRequest = ({ startDate, endDate, storeNumber }) => {
  return {
    startDate: moment(startDate).format('YYYYMMDD'),
    endDate: endDate ? moment(endDate).format('YYYYMMDD') : '',
    storeNumber,
  };
};

export const mapGenerateMonthlyReportRequest = ({ startMonth, endMonth, storeNumber }) => {
  return {
    startMonth: moment(startMonth, 'MM/YYYY').format('YYYYMM'),
    endMonth: endMonth ? moment(endMonth, 'MM/YYYY').format('YYYYMM') : '',
    storeNumber,
  };
};

export const mapGenerateReportError = (e) => {
  e.type = storesErrorTypes.CANNOT_GENERATE_REPORT;
  throw e;
};

export const mapUploadCardsForStoreError = (error) => {
  error.type = appErrorTypes.CANNOT_UPLOAD;
  throw error;
};
