import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  addButton: {
    marginTop: 4,
    marginLeft: 4,
  },
  removeButton: {
    marginTop: -2,
    marginLeft: 2,
  },
  eanRow: {
    marginTop: 5,
  },
}));

export default useStyles;
