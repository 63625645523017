import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const getLabel = (value) => {
  switch (value) {
    case true:
      return 'app:yes';
    case false:
      return 'app:no';
    default:
      return 'app:na';
  }
};

const ConfirmedCell = memo((props) => {
  const {
    item: {
      user: { enabled },
    },
  } = props;
  const { t } = useTranslation();
  return <>{t(getLabel(enabled))}</>;
});

ConfirmedCell.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ConfirmedCell;
