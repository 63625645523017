import { useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import isArray from 'lodash/isArray';
import pick from 'lodash/pick';

import { promotionRuleTypes } from 'ducks/campaigns/enums';

export const usePligsListActions = () => {
  const { values: promotion, setFieldValue } = useFormikContext();

  const ruleName = promotionRuleTypes.CART_HAS_ITEMS;
  const rule = promotion.rules[ruleName];
  const config = promotion.cartHasItemsConfig;
  const multiplePligs = useMemo(() => {
    const pligs = Object.values(promotion.pligs);

    return isArray(pligs) && pligs.length > 1;
  }, [promotion.pligs]);

  const hideSecondList = useCallback(() => {
    const id = Object.keys(promotion.pligs)[0];

    id && setFieldValue('pligs', pick(promotion.pligs, id), false);
  }, [rule, promotion.pligs]);

  const showSecondList = useCallback(() => {
    const id = Object.keys(promotion.pligs)[0] + 1 || 0;

    setFieldValue('pligs', { ...promotion.pligs, [id]: [] }, false);
  }, [rule, promotion.pligs]);

  return {
    rule,
    config,
    multiplePligs,
    showSecondList,
    hideSecondList,
  };
};
