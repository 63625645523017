import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';

const CouponUsingButton = memo((props) => {
  const { enabled, ...rest } = props;
  const { t } = useTranslation();
  const iconColor = enabled ? 'primary' : 'disabled';

  return (
    <IconButton title={t('coupons:couponUsing')} {...rest}>
      <ConfirmationNumberIcon color={iconColor} />
    </IconButton>
  );
});

CouponUsingButton.propTypes = {
  ...IconButton.propTypes,
  enabled: PropTypes.bool,
};

CouponUsingButton.defaultProps = {
  ...IconButton.defaultProps,
  enabled: false,
};

export default CouponUsingButton;
