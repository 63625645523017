import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { RemoveButton } from '../../buttons';
import ManipulationRowAction from './ManipulationRowAction';

const RemoveRowAction = memo((props) => <ManipulationRowAction {...props} />);

RemoveRowAction.propTypes = {
  ...ManipulationRowAction.propTypes,
  component: PropTypes.object,
};

RemoveRowAction.defaultProps = {
  ...ManipulationRowAction.defaultProps,
  component: RemoveButton,
  toFirstPage: true,
};

export default RemoveRowAction;
