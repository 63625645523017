import moment from 'moment';

export const formatDate = (date) => {
  return date ? moment(date).format('DD/MM/YYYY') : '';
};

export const formatDateTime = (date) => {
  return date ? moment(date).format('DD/MM/YYYY kk:mm') : '';
};

const euroFormatter = new Intl.NumberFormat('fr-FR', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 2,
});

const percentFormatter = new Intl.NumberFormat('fr-FR', {
  style: 'percent',
  maximumFractionDigits: 0,
});

export const formatEuro = (amount) => {
  if (amount === undefined || amount === null) {
    return '';
  }
  return euroFormatter.format(amount / 100);
};

export const formatPercent = (amount) => {
  if (amount === undefined || amount === null) {
    return '';
  }
  return percentFormatter.format(amount);
};
