import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useAction } from 'app/hooks';
import { actions as modalsActions, modalTypes } from 'ducks/modals';
import { Button } from 'components';

const ChangeClientEmail = memo((props) => {
  const { client } = props;
  const userId = client.isUserRelated ? client.user.id : client.id;
  const showModal = useAction(modalsActions.showModal);

  const onClick = useCallback(() => {
    showModal(modalTypes.CHANGE_CLIENT_EMAIL_MODAL, {
      userId,
      isUserRelated: client.isUserRelated,
    });
  }, [userId, showModal]);

  return <Button variant="outlined" size="small" label="auth:changeEmail" onClick={onClick} />;
});

ChangeClientEmail.propTypes = {
  client: PropTypes.object.isRequired,
};

export default ChangeClientEmail;
