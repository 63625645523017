import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Form } from 'formik';

import { useAction } from 'app/hooks';
import { actions } from 'ducks/auth';
import { EmailField } from 'components';
import Modal from 'components/modals/Modal';
import * as yup from 'yup';

const ResetPasswordForm = memo(({ handleSubmit, modalProps }) => {
  return (
    <Modal titleText="auth:resetPassword" onOk={handleSubmit} {...modalProps}>
      <Form>
        <EmailField />
      </Form>
    </Modal>
  );
});

ResetPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  modalProps: PropTypes.shape(Modal.propTypes).isRequired,
};

const validationSchema = yup.object().shape({
  email: yup.string().email('auth:invalidEmail').required('app:required'),
});

const EnhancedResetPasswordForm = withFormik({
  displayName: 'ResetPasswordForm',
  validationSchema,
  enableReinitialize: true,
  validateOnBlur: false,
  mapPropsToValues: () => ({
    email: '',
  }),
  handleSubmit: (values, { props: { resetPassword } }) => {
    resetPassword(values);
  },
})(ResetPasswordForm);

EnhancedResetPasswordForm.propTypes = {
  resetPassword: PropTypes.func.isRequired,
};

const ResetPasswordModal = memo((props) => {
  const resetPassword = useAction(actions.resetPassword);
  return <EnhancedResetPasswordForm modalProps={props} resetPassword={resetPassword} />;
});

ResetPasswordModal.propTypes = {
  ...Modal.propTypes,
};

export default ResetPasswordModal;
