import React from 'react';
import PropTypes from 'prop-types';
import { Form, useFormik, FormikProvider } from 'formik';

import paths from 'app/paths';
import { actions } from 'ducks/campaigns';
import { useAction } from 'app/hooks';
import {
  AmountField,
  EndDateField,
  Panel,
  RedirectButton,
  SaveButton,
  StartDateField,
} from 'components/shared';

import PromotionTotalDependentRules from './PromotionTotalDependentRules';
import { promotionRuleTypes } from '../../../ducks/campaigns/enums';
import PromotionTotalDependentAction from './PromotionTotalDependentAction';
import { validationSchema } from './totalDependentValidation';

const PromotionCreateTotalDependent = function ({ promotion }) {
  const createTotalDependentPromotion = useAction(actions.createTotalDependentPromotion);

  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      campaign: promotion.id,
      pligs: { 0: [] },
      actions: promotion.actions,
      rules: promotion.rules,
      target: promotion.target,
    },
    onSubmit: createTotalDependentPromotion,
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Panel title="app:general" defaultExpanded>
          <StartDateField />
          <EndDateField />
        </Panel>
        <Panel
          title={`campaigns:promotionRuleTypesBrief:${promotionRuleTypes.PER_CARD_DISCOUNT_LIMIT}`}
          defaultExpanded
        >
          <AmountField
            name={`rules.${promotionRuleTypes.PER_CARD_DISCOUNT_LIMIT}.configuration.limit`}
            label="campaigns:limitPerCardEuro"
          />
        </Panel>
        <PromotionTotalDependentRules promotion={promotion} />
        <PromotionTotalDependentAction promotion={promotion} />
        <SaveButton label="app:create" />
        <RedirectButton marginLeft label="app:cancel" path={paths.promotions.path} />
      </Form>
    </FormikProvider>
  );
};

PromotionCreateTotalDependent.propTypes = {
  promotion: PropTypes.object.isRequired,
};

export default React.memo(PromotionCreateTotalDependent);
