import { schema } from 'normalizr';
import cloneDeep from 'lodash/cloneDeep';
import values from 'lodash/values';

import { mapListRequest, mapListResponse } from 'app/mappers';
import { errorTypes as appErrorTypes } from 'ducks/app';
import { errorTypes } from 'ducks/campaigns';

import {
  promotionRuleTypes,
  promotionTargetTypes,
  promotionActionTypes,
  promotionDiscountTypes,
} from './enums';
import { mapCampaign, mapPromotion } from './entityMappers';
import { editableActionTypes, targetIdByTargetType } from './const';

const campaignSchema = new schema.Entity('campaigns');
const campaignListSchema = [campaignSchema];

const promotionSchema = new schema.Entity('promotions');
const promotionListSchema = [promotionSchema];

export const mapFetchCampaignListRequest = (params) => {
  return mapListRequest(params, {
    scope: 'campaign_full,product_full',
  });
};

export const mapFetchCampaignListResponse = (data) => {
  return mapListResponse(data, {
    entityName: 'campaigns',
    entityMapper: mapCampaign,
    schema: campaignListSchema,
  });
};

export const mapFetchPromotionListRequest = (params) => {
  return mapListRequest(params, {
    scope: 'promotion_full,product_full',
    sortMap: { pligs: 'plig' },
  });
};

export const mapFetchPromotionListResponse = (data) => {
  return mapListResponse(data, {
    entityName: 'promotions',
    entityMapper: mapPromotion,
    schema: promotionListSchema,
  });
};
export const mapFetchPromotionResponse = (data) => {
  return mapPromotion(data);
};

export const mapUpdateCouponBasedError = (e) => {
  e.type = appErrorTypes.CANNOT_UPDATE;
  throw e;
};

export const mapDeactivatePromotionError = (e) => {
  e.type = appErrorTypes.CANNOT_DEACTIVATE;
  throw e;
};

export const mapCreateSpecialPromotionError = (e) => {
  e.type = errorTypes.CANNOT_CREATE_SPECIAL_PROMO;
  e.errors = values(e.response.data.errors).join('\n');
  throw e;
};

export const mapUpdatePromotionError = (error) => {
  error.type = appErrorTypes.CANNOT_UPDATE;

  throw error;
};

export const mapUploadCampaignError = (error) => {
  error.type = appErrorTypes.CANNOT_UPLOAD;

  throw error;
};

export const mapCreateCampaignError = (error) => {
  error.type = appErrorTypes.CANNOT_CREATE;

  throw error;
};

const discountTypeToBoolean = (discountType) => {
  return discountType === promotionDiscountTypes.DELAYED;
};

// TODO: just a small note for future updates.
// It would be really great to refactor whole this code to use immutable approach and pure functions
// Currently this code is becoming too cumbersome to debug and add new features
const updateHasAccountRule = (rules, data) => {
  rules[promotionRuleTypes.HAS_ACCOUNT] = {
    type: promotionRuleTypes.HAS_ACCOUNT,
    configuration: {},
  };

  const hasAccountRule = rules[promotionRuleTypes.HAS_ACCOUNT];

  switch (data.target) {
    case promotionTargetTypes.CARDHOLDERS:
      hasAccountRule.configuration.invert = false;
      break;
    case promotionTargetTypes.NON_CARDHOLDERS:
      hasAccountRule.configuration.invert = true;
      break;
    default:
      delete rules[promotionRuleTypes.HAS_ACCOUNT];
  }
};

const getUpdatedCartHasItemsRule = (rules, data) => {
  const rule = rules[promotionRuleTypes.CART_HAS_ITEMS];
  if (!rule) {
    return [];
  }
  const { pligs: pligsObject, cartHasItemsConfig } = data;

  return Object.values(pligsObject).map((pligs) => {
    return {
      pligs,
      type: promotionRuleTypes.CART_HAS_ITEMS,
      configuration: cartHasItemsConfig,
    };
  });
};

const updateOrderTotalRule = (rule, data) => {
  if (rule) {
    const { orderTotal } = data;
    rule.configuration.amount = orderTotal;
  }
};

const updatePerCardDiscountLimitRule = (rule) => {
  if (rule) {
    rule.configuration.limit *= 100;
  }
};

const updateRules = (rules, data) => {
  const updatedRules = cloneDeep(rules);
  updateHasAccountRule(updatedRules, data);

  const cartHasItemsRule = getUpdatedCartHasItemsRule(updatedRules, data);
  delete updatedRules[promotionRuleTypes.CART_HAS_ITEMS];

  updatePerCardDiscountLimitRule(updatedRules[promotionRuleTypes.PER_CARD_DISCOUNT_LIMIT]);

  updateOrderTotalRule(updatedRules[promotionRuleTypes.ORDER_TOTAL], data);

  const isCardholder = data.target === promotionTargetTypes.CARDHOLDERS;
  if (!isCardholder) {
    delete updatedRules[promotionRuleTypes.PER_CARD_USAGE_LIMIT];
  }

  let rulesList = values(updatedRules);
  if (cartHasItemsRule) {
    rulesList = [...rulesList, ...cartHasItemsRule];
  }

  rulesList.forEach((rule) => delete rule.id);
  return rulesList;
};

const updateAction = (action, data, discountDataField) => {
  if (action) {
    const discount = data[discountDataField];

    if (discount.limit) {
      action.configuration.limit = discount.limit;
    } else {
      delete action.configuration.limit;
    }

    if (discount.cycle) {
      action.configuration.cycle = discount.cycle;
    } else {
      delete action.configuration.cycle;
    }

    action.configuration.neutral = discountTypeToBoolean(discount.type);
    action.configuration.amount =
      action.type === promotionActionTypes.PERCENTAGE_DISCOUNT
        ? discount.amount / 100
        : Math.round(discount.amount * 100);
  }
};

const updateActions = (actions, data) => {
  const updatedActions = cloneDeep(actions);
  editableActionTypes.forEach(({ type, key }) => updateAction(updatedActions[type], data, key));

  const actionsList = values(updatedActions);
  actionsList.forEach((action) => delete action.id);

  return actionsList;
};

export const mapUpdatePromotionRequest = (data) => {
  const { startDate, endDate, actions, rules } = data;

  return {
    startsAt: startDate,
    endsAt: endDate,
    rules: updateRules(rules, data),
    actions: updateActions(actions, data),
  };
};

export const mapCreatePromotionRequest = (data, id) => {
  const { startDate, endDate, actions, rules } = data;

  return {
    campaign: id,
    startsAt: startDate,
    endsAt: endDate,
    rules: updateRules(rules, data),
    actions: updateActions(actions, data),
  };
};

/**
 * Helper function that maps form values to request format
 * @param {object} data
 * @returns {object} mapped data for special promo creation request
 */
export const mapSpecialPromotionRequest = ({
  startDate,
  endDate,
  pligs,
  target,
  discountType,
  y1,
  y2,
  y3,
  ...data
}) => {
  return {
    ...data,
    y1: y1 * 100,
    y2: y2 * 100,
    y3: y3 * 100,
    startsAt: startDate,
    endsAt: endDate,
    pligs: pligs[0],
    target: targetIdByTargetType[target],
    // delayed discount only applicable to cardholders
    entry: target === promotionTargetTypes.CARDHOLDERS && discountTypeToBoolean(discountType),
  };
};

export const mapRemoveCampaignError = (e) => {
  e.type = appErrorTypes.CANNOT_REMOVE;
  throw e;
};

export const mapCreateTotalDependentPromotionRequest = ({ startDate, endDate, ...data }) => {
  const { campaign, rules } = data;
  data.cartHasItemsConfig = rules[promotionRuleTypes.CART_HAS_ITEMS].configuration;

  return {
    campaign,
    startsAt: startDate,
    endsAt: endDate,
    rules: updateRules(rules, data),
    actions: {
      [promotionActionTypes.DYNAMIC_DISCOUNT]: {
        type: promotionActionTypes.DYNAMIC_DISCOUNT,
        configuration: {
          accruingRules: [
            {
              total: data.total1 * 100,
              discount: data.discount1 * 100,
            },
            {
              total: data.total2 * 100,
              discount: data.discount2 * 100,
            },
            {
              total: data.total3 * 100,
              discount: data.discount3 * 100,
            },
            {
              total: data.total4 * 100,
              discount: data.discount4 * 100,
            },
            {
              total: data.total5 * 100,
              discount: data.discount5 * 100,
            },
            {
              total: data.total6 * 100,
              discount: data.discount6 * 100,
            },
          ],
        },
      },
    },
  };
};
