import React, { memo } from 'react';

import DateField from './DateField';

const EndDateField = memo((props) => <DateField {...props} />);

EndDateField.propTypes = {
  ...DateField.propTypes,
};

EndDateField.defaultProps = {
  ...DateField.defaultProps,
  name: 'endDate',
  label: 'app:endDate',
};

export default EndDateField;
