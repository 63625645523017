import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useAction } from 'app/hooks';
import { actions as modalsActions, modalTypes } from 'ducks/modals';
import { actions as clientsActions } from 'ducks/clients';
import { Button } from 'components';

const DeactivateCard = memo((props) => {
  const { card, client } = props;
  const { id: cardId } = card;
  const { id: clientId } = client;
  const deactivateCard = useAction(clientsActions.deactivateCard);
  const showModal = useAction(modalsActions.showModal);

  const onClick = useCallback(() => {
    showModal(modalTypes.CONFIRMATION_MODAL, {
      onOk: () => deactivateCard(cardId, clientId),
    });
  }, [cardId, clientId, deactivateCard, showModal]);

  return (
    <Button variant="outlined" size="small" label="app:deactivate" onClick={onClick} marginLeft />
  );
});

DeactivateCard.propTypes = {
  card: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
};

export default DeactivateCard;
