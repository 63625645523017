import { normalize, schema } from 'normalizr';

import { STORE_ADMIN } from 'app/roles';
import { mapListRequest, mapListResponse } from 'app/mappers';
import { errorTypes as appErrorTypes } from 'ducks/app';
import { mapAdmin } from './entityMappers';

const adminSchema = new schema.Entity('admins');
const adminListSchema = [adminSchema];

const adminGroupSchema = new schema.Entity('adminGroups');
const adminGroupListSchema = [adminGroupSchema];

export const mapFetchAdminListRequest = (params) => {
  return mapListRequest(params, {
    extraParams: { only_admin: 1 },
  });
};

export const mapFetchAdminListResponse = (data) => {
  return mapListResponse(data, {
    entityName: 'admins',
    entityMapper: mapAdmin,
    schema: adminListSchema,
  });
};

export const mapRemoveUserError = (e) => {
  e.type = appErrorTypes.CANNOT_REMOVE;
  throw e;
};

export const mapCreateAdminRequest = ({
  username,
  email,
  password: plainPassword,
  role: roles,
  groups,
}) => {
  const data = { username, email, roles };
  if (plainPassword) {
    data.plainPassword = plainPassword;
  }
  if (roles === STORE_ADMIN) {
    data.groups = groups;
  }
  return data;
};

export const mapCreateAdminError = (e) => {
  e.type = appErrorTypes.CANNOT_CREATE;
  throw e;
};

export const mapFetchAdminResponse = (data) => {
  return mapAdmin(data);
};

export const mapUpdateAdminRequest = (data) => {
  return mapCreateAdminRequest(data);
};

export const mapUpdateAdminError = (e) => {
  e.type = appErrorTypes.CANNOT_UPDATE;
  throw e;
};

export const mapFetchAdminGroupsResponse = ({ availableGroups = [], allGroups }) => {
  const normAvailableGroups = normalize(availableGroups, adminGroupListSchema);
  const normAllGroups = normalize(allGroups, adminGroupListSchema);
  return {
    availableGroups: normAvailableGroups.entities.adminGroups || {},
    allGroups: normAllGroups.entities.adminGroups || {},
  };
};

export const mapRestoreUserError = (e) => {
  e.type = appErrorTypes.CANNOT_RESTORE;
  throw e;
};
