import { errorHandlers as appErrorHandlers } from 'ducks/app';
import { errorHandlers as authErrorHandlers } from 'ducks/auth';
import { errorHandlers as couponsErrorHandlers } from 'ducks/coupons';
import { errorHandlers as storesErrorHandlers } from 'ducks/stores';
import { errorHandlers as campaignsErrorHandlers } from 'ducks/campaigns';

export default {
  ...appErrorHandlers,
  ...authErrorHandlers,
  ...couponsErrorHandlers,
  ...storesErrorHandlers,
  ...campaignsErrorHandlers,
};
