import React, { memo, useState, useCallback, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Formik, Form } from 'formik';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { useClickOutside } from 'app/hooks';
import { ListContext } from './_constants';
import useStyles from './Filter.styles';

const FilterHead = memo(({ mainField, toggle }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.head}>
      {mainField}
      <IconButton className={clsx(classes.iconButton, classes.arrowButton)} onClick={toggle}>
        <ArrowDropDownIcon />
      </IconButton>
      <Divider className={classes.divider} orientation="vertical" />
      <IconButton className={classes.iconButton} type="submit" color="primary">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
});

FilterHead.propTypes = {
  mainField: PropTypes.element.isRequired,
  toggle: PropTypes.func.isRequired,
};

const Filter = memo(({ initialValues, validationSchema, validate, mainField, children }) => {
  const { filter, updateFilter } = useContext(ListContext);
  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleSubmit = useCallback(
    (values) => {
      updateFilter(values);
      close();
    },
    [updateFilter, close],
  );

  useClickOutside(wrapperRef, close);

  return (
    <div className={classes.root} ref={wrapperRef}>
      <Formik
        initialValues={{ ...initialValues, ...filter }}
        validationSchema={validationSchema}
        validate={validate}
        onSubmit={handleSubmit}
        enableReinitialize
        validateOnBlur={false}
      >
        {() => (
          <Form>
            <FilterHead mainField={mainField} toggle={toggle} />
            <Paper className={clsx(classes.body, isOpen && classes.bodyVisible)}>{children}</Paper>
          </Form>
        )}
      </Formik>
    </div>
  );
});

Filter.propTypes = {
  initialValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object,
  validate: PropTypes.object,
  mainField: PropTypes.element.isRequired,
  children: PropTypes.node,
};

Filter.defaultProps = {
  validationSchema: null,
  validate: null,
  children: null,
};

export default Filter;
