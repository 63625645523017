import axios from 'axios';

import * as mappers from './serviceMappers';

export const fetchClientList = (params) => {
  return axios
    .get('customers/search', { params: mappers.mapFetchClientListRequest(params) })
    .then((response) => mappers.mapFetchClientListResponse(response.data));
};

export const fetchBestClients = (params) => {
  const { storeId: store } = params;
  return axios
    .post('customers/get-best-clients', { store }, { responseType: 'text' })
    .then((response) => mappers.mapFetchBestClientsResponse(response.data));
};

export const fetchClient = (id) => {
  const scope = 'user_full,card_full,account_full,store_full,customer_full';
  return axios
    .get(`customers/${id}`, { params: { scope } })
    .then((response) => mappers.mapFetchClientResponse(response.data));
};

export const resetClientPassword = (userId) => {
  return axios
    .post(`users/${userId}/set-default-password`)
    .catch(mappers.mapResetClientPasswordError);
};

export const changeClientEmail = (userId, data) => {
  return axios
    .post(`users/${userId}/change-email-request`, data)
    .catch(mappers.mapChangeClientEmailError);
};

export const changeClientEmailWithoutUser = (userId, data) => {
  return axios.patch(`customers/${userId}/email`, data).catch(mappers.mapChangeClientEmailError);
};

export const updateClientProfile = (clientId, data) => {
  return axios
    .patch(`customers/${clientId}`, mappers.mapUpdateClientProfileRequest(data))
    .catch(mappers.mapUpdateClientProfileError);
};

export const activateClient = (userId) => {
  return axios.post(`users/${userId}/activate`).catch(mappers.mapActivateClientError);
};

export const deactivateClient = (userId) => {
  return axios.post(`users/${userId}/expire`).catch(mappers.mapDeactivateClientError);
};

export const activateCard = (cardId) => {
  return axios.post(`cards/${cardId}/activate`).catch(mappers.mapActivateCardError);
};

export const deactivateCard = (cardId) => {
  return axios.post(`cards/${cardId}/deactivate`).catch(mappers.mapDeactivateCardError);
};

export const fetchBalanceHistory = (accountId, page) => {
  const params = {
    scope: 'account_full,card_full,store_full,order_full',
    page,
  };
  return axios
    .get(`accounts/${accountId}/balance-history`, { params })
    .then((response) => mappers.mapFetchBalanceHistoryResponse(response.data, accountId));
};

export const updateBalance = (accountId, data) => {
  return axios.post(`accounts/${accountId}/balance`, data).catch(mappers.mapUpdateBalanceError);
};

export const exportPersonalData = (clientId) => {
  return axios.get(`customers/${clientId}/export`, {
    responseType: 'arraybuffer',
  });
};

export const removePersonalData = (clientId) => {
  return axios.post(`customers/${clientId}/anonymize`, {});
};

export function uploadCardsFile(file) {
  const formData = new FormData();
  formData.append('file', file);

  const config = {
    headers: { 'Content-Type': 'application/json' },
  };

  return axios.post('cards/from-file', formData, config).catch(mappers.mapUploadCardsError);
}

export function getExpiredBalance(accountId) {
  return axios.get(`accounts/${accountId}/expired-balance`).then((response) => response.data);
}

export function subtractExpiredBonuses() {
  return axios
    .post('accounts/subtract-expired-bonuses', {})
    .catch(mappers.mapSubtractExpiredBonusesError);
}
