import { useEffect } from 'react';

import { useAction } from 'app/hooks';
import { actions } from 'ducks/auth';

const Logout = function () {
  const logout = useAction(actions.logout);
  useEffect(() => {
    logout();
  }, [logout]);
  return null;
};

export default Logout;
