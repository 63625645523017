import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { useAction } from 'app/hooks';
import { positiveNumberValidator, idValidator } from 'app/validators';
import { selectors as appSelectors } from 'ducks/app';
import { actions as couponsActions } from 'ducks/coupons';
import { Breadcrumbs } from 'components';
import CouponForm from './CouponForm';

const validationSchema = yup.object().shape({
  storeNumber: positiveNumberValidator().required('app:required'),
  cardNumbers: yup.string().required('app:required'),
  promotionId: idValidator().required('app:required'),
  quantity: positiveNumberValidator().required('app:required'),
});

const CouponAdd = memo(() => {
  const { promotionId } = useSelector(appSelectors.queryParams);
  const createCoupon = useAction(couponsActions.createCoupon);

  return (
    <>
      <Breadcrumbs />
      <CouponForm
        validationSchema={validationSchema}
        onSubmit={createCoupon}
        promotionId={promotionId}
      />
    </>
  );
});

export default CouponAdd;
