import axios from 'axios';

import * as mappers from './serviceMappers';

export const fetchCouponList = (params) => {
  return axios
    .get('coupons/search', { params: mappers.mapFetchCouponListRequest(params) })
    .then((response) => mappers.mapFetchCouponListResponse(response.data));
};

export const deactivateCoupon = (id) => {
  return axios.post(`coupons/${id}/deactivate`).catch(mappers.mapDeactivateCouponError);
};

export const createCoupon = (data) => {
  return axios
    .post('coupons/', mappers.mapCreateCouponRequest(data))
    .catch(mappers.mapCreateCouponError);
};
