import React, { memo } from 'react';
import * as yup from 'yup';

import { useAction } from 'app/hooks';
import { passwordValidator } from 'app/validators';
import { actions as adminsActions } from 'ducks/admins';
import { Breadcrumbs } from 'components';
import AdminForm from './AdminForm';

const validationSchema = yup.object().shape({
  username: yup.string().required('app:required'),
  email: yup.string().email('auth:invalidEmail').required('app:required'),
  password: passwordValidator().required('app:required'),
  passwordConfirmation: yup
    .string()
    .required('app:required')
    .oneOf([yup.ref('password')], 'auth:passwordsDoNotMatch'),
  role: yup.string().required('app:required'),
});

const AdminAdd = memo(() => {
  const createAdmin = useAction(adminsActions.createAdmin);

  return (
    <>
      <Breadcrumbs />
      <AdminForm validationSchema={validationSchema} onSubmit={createAdmin} />
    </>
  );
});

export default AdminAdd;
