import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

const Caption = memo((props) => {
  const { text, strong, ...rest } = props;
  const { t } = useTranslation();
  const content = strong ? <strong>{t(text)}</strong> : t(text);
  return (
    <p>
      <Typography variant="subtitle2" component="span" {...rest}>
        {content}
      </Typography>
    </p>
  );
});

Caption.propTypes = {
  ...Typography.propTypes,
  text: PropTypes.string.isRequired,
  strong: PropTypes.bool,
};

Caption.defaultProps = {
  ...Typography.defaultProps,
  strong: true,
};

export default Caption;
