import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components';

import paths from 'app/paths';

const PromotionNameCell = memo((props) => {
  const {
    item: { promotion },
  } = props;
  const { id: promotionId, name, campaign } = promotion;
  const { id: campaignId } = campaign;
  return (
    <Link to={paths.promotionView.path} params={{ promotionId, campaignId }}>
      {name}
    </Link>
  );
});

PromotionNameCell.propTypes = {
  item: PropTypes.shape({
    promotion: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      campaign: PropTypes.shape({
        id: PropTypes.number,
      }),
    }),
  }).isRequired,
};

export default PromotionNameCell;
