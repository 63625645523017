import React, { memo } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { withFormik, Form } from 'formik';

import { dateValidator, endDateValidator } from 'app/validators';
import { useAction } from 'app/hooks';
import { actions as storeActions } from 'ducks/stores';
import { StartDateField, EndDateField } from 'components';
import Modal from 'components/modals/Modal';

const OnlinePercentageForm = memo(({ handleSubmit, modalProps }) => {
  return (
    <Modal titleText="stores:exportOnlinePercentage" onOk={handleSubmit} {...modalProps}>
      <Form>
        <StartDateField />
        <EndDateField />
      </Form>
    </Modal>
  );
});

OnlinePercentageForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  modalProps: PropTypes.shape(Modal.propTypes).isRequired,
};

const validationSchema = yup.object().shape({
  startDate: dateValidator().required('app:required'),
  endDate: endDateValidator().required('app:required'),
});

const EnhancedOnlinePercentageForm = withFormik({
  displayName: 'OnlinePercentageForm',
  validationSchema,
  enableReinitialize: true,
  validateOnBlur: true,
  mapPropsToValues: () => ({
    startDate: '',
    endDate: '',
  }),
  handleSubmit: (values, { props: { onSubmit } }) => {
    onSubmit(values);
  },
})(OnlinePercentageForm);

EnhancedOnlinePercentageForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

const OnlinePercentageModal = memo((props) => {
  const fetchOnlinePercentage = useAction(storeActions.fetchOnlinePercentage);
  return <EnhancedOnlinePercentageForm modalProps={props} onSubmit={fetchOnlinePercentage} />;
});

OnlinePercentageModal.propTypes = {
  ...Modal.propTypes,
};

export default OnlinePercentageModal;
