import React, { memo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Form } from 'formik';

import { useAction } from 'app/hooks';
import Modal from 'components/modals/Modal';
import { actions } from 'ducks/clients';

const UploadCardsFileForm = memo(({ modalProps }) => {
  const uploadAction = useAction(actions.uploadCardsFile);
  const file = useRef(null);

  const handleFileSelect = useCallback(
    ({ target }) => {
      [file.current] = target.files;
    },
    [file],
  );

  const handleFileSubmit = useCallback(() => {
    file.current && uploadAction(file.current);
  }, [file, uploadAction]);

  return (
    <Modal
      titleText="clients:uploadCardsFile"
      okText="campaigns:uploadButton"
      onOk={handleFileSubmit}
      {...modalProps}
    >
      <Form>
        <input type="file" accept=".xls,.xlsx,.csv" onChange={handleFileSelect} />
      </Form>
    </Modal>
  );
});

UploadCardsFileForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  modalProps: PropTypes.shape(Modal.propTypes).isRequired,
};

const EnhancedUploadCardsFileForm = withFormik({
  enableReinitialize: true,
  validateOnBlur: true,
  handleSubmit: (values, { props: { onSubmit, header } }) => {
    onSubmit(values, header);
  },
})(UploadCardsFileForm);

EnhancedUploadCardsFileForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

const UploadCardsFileModal = memo((props) => {
  return <EnhancedUploadCardsFileForm modalProps={props} />;
});

UploadCardsFileModal.propTypes = {
  ...Modal.propTypes,
};

export default UploadCardsFileModal;
