import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useAction, useListSelector } from 'app/hooks';
import { actions as storesActions, selectors as storesSelectors } from 'ducks/stores';
import { AutocompleteField } from 'components';

const StoreNumberActField = memo((props) => {
  const fetchStoreOptions = useAction(storesActions.fetchStoreOptions);
  const data = useListSelector(storesSelectors.storeOptionNumbers);

  const onFetch = useCallback(
    (actId, value) => {
      fetchStoreOptions(actId, { number: value });
    },
    [fetchStoreOptions],
  );

  return <AutocompleteField data={data} onFetch={onFetch} {...props} />;
});

StoreNumberActField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  valueSource: PropTypes.string,
};

StoreNumberActField.defaultProps = {
  name: 'storeNumber',
  label: 'stores:storeNumber',
  valueSource: 'name',
};

export default StoreNumberActField;
