import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';

import { promotionRuleTypes, promotionTargetTypes } from 'ducks/campaigns/enums';
import moment from 'moment';

const mapPromotionRules = (data) => {
  const result = {};

  data.forEach((item) => {
    if (isEmpty(item.configuration)) {
      item.configuration = {};
    }

    if (item.type === promotionRuleTypes.PER_CARD_DISCOUNT_LIMIT) {
      item.configuration.limit /= 100;
    }

    if (result[item.type]) {
      if (isArray(result[item.type])) {
        result[item.type].push(item);
      } else {
        result[item.type] = [result[item.type], item];
      }
      return;
    }

    result[item.type] = item;
  });

  return result;
};

const mapPromotionActions = (data) => {
  const result = {};
  data.forEach((item) => {
    result[item.type] = item;
  });
  return result;
};

const calculatePromotionTarget = (rules) => {
  const hasAccountRule = rules[promotionRuleTypes.HAS_ACCOUNT];
  if (!hasAccountRule) {
    return promotionTargetTypes.ALL_CLIENTS;
  }
  switch (hasAccountRule.configuration.invert) {
    case false:
      return promotionTargetTypes.CARDHOLDERS;
    case true:
      return promotionTargetTypes.NON_CARDHOLDERS;
    default:
      return promotionTargetTypes.CARDHOLDERS;
  }
};

export const mapPromotion = (data) => {
  const {
    id,
    name,
    description,
    isCouponBased,
    startsAt,
    endsAt,
    campaign = {},
    rules = [],
    actions = [],
  } = data;
  const mappedRules = mapPromotionRules(rules);
  const mappedActions = mapPromotionActions(actions);

  return {
    id,
    name,
    description,
    isCouponBased,
    startDate: moment(startsAt).format('YYYY-MM-DD'),
    endDate: moment(endsAt).format('YYYY-MM-DD'),
    campaign: { id: campaign.id },
    rules: mappedRules,
    actions: mappedActions,
    target: calculatePromotionTarget(mappedRules),
  };
};

export const mapCampaign = (data) => {
  const { id, name, description, promotions = [] } = data;
  return {
    id,
    name,
    description,
    promotions: promotions.map(mapPromotion),
  };
};
