import React from 'react';
import { useSelector } from 'react-redux';

import { selectors } from 'ducks/modals';
import * as modalComponents from './index';

function ModalManager() {
  const modals = useSelector(selectors.modals);

  return modals.map((modal, i) => {
    const Modal = modalComponents[modal.name];
    const getUniqueKey = () => i * Math.random();
    return <Modal key={getUniqueKey()} {...modal.props} show={modal.show} />;
  });
}

export default ModalManager;
