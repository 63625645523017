import React, { memo } from 'react';

import TextField from './TextField';

const NameField = memo((props) => <TextField {...props} />);

NameField.propTypes = {
  ...TextField.propTypes,
};

NameField.defaultProps = {
  ...TextField.defaultProps,
  name: 'name',
  label: 'app:name',
};

export default NameField;
