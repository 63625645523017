import * as actionTypes from './actionTypes';

export const showSuccess = (message) => ({
  type: actionTypes.SHOW_SUCCESS,
  message,
});

export const showError = (message) => ({
  type: actionTypes.SHOW_ERROR,
  message,
});

export const handleError = (error) => ({
  type: actionTypes.HANDLE_ERROR,
  error,
});

export const clearNotification = () => ({
  type: actionTypes.CLEAR_NOTIFICATION,
});

export const startLoading = () => ({
  type: actionTypes.START_LOADING,
});

export const finishLoading = () => ({
  type: actionTypes.FINISH_LOADING,
});

export const startActLoading = (actId) => ({
  type: actionTypes.START_ACT_LOADING,
  actId,
});

export const finishActLoading = () => ({
  type: actionTypes.FINISH_ACT_LOADING,
});

export const exportPreparedCsv = (filename, dataString) => ({
  type: actionTypes.EXPORT_PREPARED_CSV,
  filename,
  dataString,
});

export const exportRawCsv = (filename, rows, columnNames, rowHandler) => ({
  type: actionTypes.EXPORT_RAW_CSV,
  filename,
  rows,
  columnNames,
  rowHandler,
});
