import React, { memo } from 'react';

import MonthField from './MonthField';

const EndMonthField = memo((props) => <MonthField {...props} />);

EndMonthField.propTypes = {
  ...MonthField.propTypes,
};

EndMonthField.defaultProps = {
  ...MonthField.defaultProps,
  name: 'endMonth',
  label: 'app:endMonth',
};

export default EndMonthField;
