import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Form } from 'formik';

import { useAction } from 'app/hooks';
import { actions as clientsActions } from 'ducks/clients';
import { EmailField } from 'components';
import Modal from 'components/modals/Modal';
import * as yup from 'yup';

const ChangeClientEmailForm = memo(({ handleSubmit, modalProps, isUserRelated }) => {
  return (
    <Modal titleText="auth:changeEmail" onOk={handleSubmit} {...modalProps}>
      <Form>
        <EmailField isUserRelated={isUserRelated} />
      </Form>
    </Modal>
  );
});

ChangeClientEmailForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  modalProps: PropTypes.shape(Modal.propTypes).isRequired,
  isUserRelated: PropTypes.bool.isRequired,
};

const validationSchema = yup.object().shape({
  email: yup.string().email('auth:invalidEmail').required('app:required'),
});

const EnhancedChangeClientEmailForm = withFormik({
  displayName: 'ChangeClientEmailForm',
  validationSchema,
  enableReinitialize: true,
  validateOnBlur: false,
  mapPropsToValues: () => ({
    email: '',
  }),
  handleSubmit: (values, { props: { onSubmit, userId } }) => {
    onSubmit(userId, values);
  },
})(ChangeClientEmailForm);

EnhancedChangeClientEmailForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  isUserRelated: PropTypes.bool.isRequired,
};

const ChangeClientEmailModal = memo((props) => {
  const { userId, isUserRelated, ...restProps } = props;
  const changeClientEmail = useAction(
    isUserRelated ? clientsActions.changeClientEmail : clientsActions.changeClientEmailWithoutUser,
  );
  return (
    props.show && (
      <EnhancedChangeClientEmailForm
        modalProps={restProps}
        onSubmit={changeClientEmail}
        userId={userId}
        isUserRelated={isUserRelated}
      />
    )
  );
});

ChangeClientEmailModal.propTypes = {
  ...Modal.propTypes,
  userId: PropTypes.number.isRequired,
};

export default ChangeClientEmailModal;
