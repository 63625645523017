export const genderTypes = {
  MR: 1,
  MRS: 2,
  MS: 3,
  MISS: 4,
};

export const entryTypes = {
  ENTRY: 'entry',
  ORDER: 'order',
};
