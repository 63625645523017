import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { formatEuro } from 'app/formatters';
import { PropertyRow } from 'components';

const AccountsCell = memo((props) => {
  const {
    item: { cards },
  } = props;
  const accounts = cards[0] ? cards[0].accounts.filter((account) => account.store.name) : [];

  const renderAccount = useCallback((data) => {
    const { id, balance, store } = data;
    return (
      <PropertyRow
        key={id}
        label={store.name}
        spacing={1}
        labelColumnSize={6}
        contentColumnSize={6}
        strongLabel={false}
      >
        {formatEuro(balance)}
      </PropertyRow>
    );
  }, []);

  return <>{accounts.map(renderAccount)}</>;
});

AccountsCell.propTypes = {
  item: PropTypes.object.isRequired,
};

export default AccountsCell;
