export const FETCH_ADMIN_LIST = 'admins/FETCH_ADMIN_LIST';
export const FETCH_ADMIN_LIST_SUCCESS = 'admins/FETCH_ADMIN_LIST_SUCCESS';

export const REMOVE_ADMIN = 'admins/REMOVE_ADMIN';
export const REMOVE_ADMIN_SUCCESS = 'admins/REMOVE_ADMIN_SUCCESS';

export const CREATE_ADMIN = 'admins/CREATE_ADMIN';
export const CREATE_ADMIN_SUCCESS = 'admins/CREATE_ADMIN_SUCCESS';

export const FETCH_ADMIN = 'admins/FETCH_ADMIN';
export const FETCH_ADMIN_SUCCESS = 'admins/FETCH_ADMIN_SUCCESS';

export const UPDATE_ADMIN = 'admins/UPDATE_ADMIN';

export const FETCH_ADMIN_GROUPS = 'admins/FETCH_ADMIN_GROUPS';
export const FETCH_ADMIN_GROUPS_SUCCESS = 'admins/FETCH_ADMIN_GROUPS_SUCCESS';

export const RESTORE_ADMIN = 'admins/RESTORE_ADMIN';
export const RESTORE_ADMIN_SUCCESS = 'admins/CREATE_ADMIN_SUCCESS';
