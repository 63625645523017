import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';

import { formatEuro } from 'app/formatters';
import { entryTypes } from 'ducks/clients/enums';

const BalanceCell = memo((props) => {
  const { entry } = props;

  const renderBalance = useCallback((data) => {
    const { type, item } = data;
    const {
      entryHistory: { balance, amount },
    } = item;

    const accountBalance = amount.reduce((res, value) => res + value, balance);
    let result = formatEuro(balance);

    if (type === entryTypes.ORDER) {
      return result;
    }

    amount.forEach((value) => {
      if (value > 0) {
        result += ` + ${formatEuro(value)}`;
      } else if (value < 0) {
        result += ` - ${formatEuro(-value)}`;
      }
    });

    return `${result} = ${formatEuro(accountBalance)}`;
  }, []);

  return <TableCell>{renderBalance(entry)}</TableCell>;
});

BalanceCell.propTypes = {
  entry: PropTypes.object.isRequired,
};

export default BalanceCell;
