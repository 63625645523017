import paths from 'app/paths';
import Login from 'components/auth/Login';
import Logout from 'components/auth/Logout';
import Reset from 'components/auth/Reset';

export default [
  {
    path: paths.login.path,
    roles: paths.login.roles,
    component: Login,
    auth: false,
  },
  {
    path: paths.logout.path,
    roles: paths.logout.roles,
    component: Logout,
  },
  {
    path: paths.reset.path,
    roles: paths.reset.roles,
    component: Reset,
    auth: false,
  },
];
