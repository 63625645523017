import React, { memo } from 'react';

import { genderTypes } from 'ducks/clients/enums';
import { DropdownField } from 'components';

const items = [
  {
    value: genderTypes.MR,
    label: 'clients:genderTypes.mr',
  },
  {
    value: genderTypes.MRS,
    label: 'clients:genderTypes.mrs',
  },
  {
    value: genderTypes.MS,
    label: 'clients:genderTypes.ms',
  },
  {
    value: genderTypes.MISS,
    label: 'clients:genderTypes.miss',
  },
];

const GenderDropdownField = memo((props) => {
  return <DropdownField {...props} />;
});

GenderDropdownField.propTypes = {
  ...DropdownField.propTypes,
};

GenderDropdownField.defaultProps = {
  ...DropdownField.defaultProps,
  name: 'gender',
  label: 'clients:gender',
  items,
};

export default GenderDropdownField;
