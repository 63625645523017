import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router';

import { usePushAction } from 'app/hooks';
import { generatePath } from 'app/utils';

import PrimaryButton from './PrimaryButton';

const RedirectButton = function ({ path, ...props }) {
  const match = useRouteMatch();
  const push = usePushAction();

  const handleCancel = useCallback(() => {
    push(generatePath(path, match.params));
  }, [push, path, match]);

  return <PrimaryButton onClick={handleCancel} {...props} />;
};

RedirectButton.propTypes = {
  ...PrimaryButton.propTypes,
  path: PropTypes.string.isRequired,
};

RedirectButton.defaultProps = {
  ...PrimaryButton.defaultProps,
  color: 'secondary',
};

export default React.memo(RedirectButton);
