import { normalize } from 'normalizr';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import values from 'lodash/values';
import assign from 'lodash/assign';
import toSnakeCase from 'lodash/snakeCase';

export const mapListFilter = (filter, namesMap = {}, snakeCase = true) => {
  if (isEmpty(filter)) {
    return {};
  }
  const keyValues = keys(filter).map((key) => {
    const newKey = namesMap[key] || (snakeCase && toSnakeCase(key)) || key;
    return { [newKey]: filter[key] };
  });
  return assign({}, ...keyValues);
};

export const mapListSort = (sort, namesMap = {}, snakeCase = true) => {
  if (isEmpty(sort)) {
    return {};
  }
  const orderBy = keys(sort)[0];
  return {
    orderBy: namesMap[orderBy] || (snakeCase && toSnakeCase(orderBy)) || orderBy,
    order: values(sort)[0].toUpperCase(),
  };
};

export const mapListRequest = (
  params,
  {
    scope = null,
    filterMap = {},
    sortMap = {},
    filterSnakeCase = true,
    sortSnakeCase = true,
    extraParams = {},
  } = {},
) => {
  const { filter, sort, page = 1, limit = 10 } = params;
  const preparedParams = {
    ...mapListFilter(filter, filterMap, filterSnakeCase),
    ...mapListSort(sort, sortMap, sortSnakeCase),
    ...extraParams,
  };
  if (page) {
    preparedParams.page = page;
  }
  if (limit) {
    preparedParams.limit = limit;
  }
  if (scope) {
    preparedParams.scope = scope;
  }
  return preparedParams;
};

export const mapListResponse = (data, { entityName, entityMapper, schema }) => {
  const {
    embedded: { items },
    page,
    limit,
    total,
  } = data;
  const mappedItems = entityMapper ? items.map(entityMapper) : items;
  const normData = normalize(mappedItems, schema);
  return {
    order: normData.result || [],
    items: normData.entities[entityName] || {},
    meta: { page, limit, total },
  };
};
