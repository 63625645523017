import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';

const AddButton = memo(({ title, ...rest }) => {
  const { t } = useTranslation();
  return (
    <IconButton title={t(title)} {...rest}>
      <AddCircleIcon />
    </IconButton>
  );
});

AddButton.propTypes = {
  ...IconButton.propTypes,
};

AddButton.defaultProps = {
  ...IconButton.defaultProps,
  title: 'app:add',
};

export default AddButton;
