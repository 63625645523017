import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    right: 0,
    left: 0,
    top: 30,
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    marginTop: '5px !important',
    width: '100%',
  },
  appName: {
    textAlign: 'center',
    marginTop: '10px',
    fontWeight: 300,
    fontSize: '2rem',
  },
  adminInterface: {
    textAlign: 'center',
    marginTop: '10px',
    fontWeight: 400,
    fontSize: '1.7rem',
  },
}));

export default useStyles;
