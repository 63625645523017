import { Map, List } from 'immutable';
import { createSelector } from 'reselect';

import { createObjectSelector, createPaginatedListSelector } from 'app/selectors';

export const productList = createPaginatedListSelector((state) =>
  state.products.get('productList', Map()),
);

export const viewingProduct = createObjectSelector((state) =>
  state.products.get('viewingProduct', Map()),
);

export const eanOptions = createObjectSelector((state) => state.products.get('eanOptions', List()));

export const eanOptionValues = createSelector([eanOptions], (data) =>
  data.map(({ id, value }) => ({ id, name: value })),
);

export const pligOptions = createObjectSelector((state) =>
  state.products.get('pligOptions', Map()),
);

export const pligOptionValues = createSelector(
  [pligOptions, (_, ruleId) => ruleId],
  (data, ruleId) => (data[ruleId] || []).map(({ id, value }) => ({ id, name: value })),
);
