import React, { memo } from 'react';

import MonthField from './MonthField';

const StartMonthField = memo((props) => <MonthField {...props} />);

StartMonthField.propTypes = {
  ...MonthField.propTypes,
};

StartMonthField.defaultProps = {
  ...MonthField.defaultProps,
  name: 'startMonth',
  label: 'app:startMonth',
};

export default StartMonthField;
