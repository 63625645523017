import { call, put, all, takeLatest } from 'redux-saga/effects';

import { showSuccess, handleError, startLoading, finishLoading } from 'ducks/app/actions';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as services from './services';

function* fetchAdminList(action) {
  try {
    yield put(startLoading());
    const data = yield call(services.fetchAdminList, action.params);
    yield put(actions.fetchAdminListSuccess(data));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* removeAdmin(action) {
  const {
    params: { id },
    onRemoved,
  } = action;
  try {
    yield put(startLoading());
    yield call(services.removeAdmin, id);
    yield put(actions.removeAdminSuccess());
    yield put(showSuccess('app:removed'));
    yield call(onRemoved);
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* createAdmin({ data }) {
  try {
    yield put(startLoading());
    yield call(services.createAdmin, data);
    yield put(actions.createAdminSuccess());
    yield put(showSuccess('app:created'));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* fetchAdmin({ id }) {
  try {
    yield put(startLoading());
    const data = yield call(services.fetchAdmin, id);
    yield put(actions.fetchAdminSuccess(data));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* updateAdmin({ id, data }) {
  try {
    yield put(startLoading());
    yield call(services.updateAdmin, id, data);
    yield put(showSuccess('app:updated'));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* fetchAdminGroups({ id }) {
  try {
    yield put(startLoading());
    const data = yield call(services.fetchAdminGroups, id);
    yield put(actions.fetchAdminGroupsSuccess(data));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* restoreAdmin(action) {
  const {
    params: { id },
  } = action;
  try {
    yield put(startLoading());
    yield call(services.restoreAdmin, id);
    yield put(actions.restoreAdminSuccess());
    yield put(showSuccess('app:restored'));
    const data = yield call(services.fetchAdminList, action.params);
    yield put(actions.fetchAdminListSuccess(data));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

export default function* () {
  yield all([
    yield takeLatest(actionTypes.FETCH_ADMIN_LIST, fetchAdminList),
    yield takeLatest(actionTypes.REMOVE_ADMIN, removeAdmin),
    yield takeLatest(actionTypes.CREATE_ADMIN, createAdmin),
    yield takeLatest(actionTypes.FETCH_ADMIN, fetchAdmin),
    yield takeLatest(actionTypes.UPDATE_ADMIN, updateAdmin),
    yield takeLatest(actionTypes.FETCH_ADMIN_GROUPS, fetchAdminGroups),
    yield takeLatest(actionTypes.RESTORE_ADMIN, restoreAdmin),
  ]);
}
