import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import values from 'lodash/values';

import { promotionActionTypes } from 'ducks/campaigns/enums';

const filterAction = (action) => {
  return [
    promotionActionTypes.FIXED_DISCOUNT,
    promotionActionTypes.PERCENTAGE_DISCOUNT,
    promotionActionTypes.FIXED_TOTAL_DISCOUNT,
    promotionActionTypes.FIN_DISCOUNT,
  ].includes(action.type);
};

const DiscountInfoCell = memo(({ property, item }) => {
  const { actions } = item;

  const renderAction = useCallback(
    ({ id, configuration }) => {
      return <div key={id}>{configuration[property]}</div>;
    },
    [property],
  );

  return values(actions).filter(filterAction).map(renderAction);
});

DiscountInfoCell.propTypes = {
  item: PropTypes.object.isRequired,
  property: PropTypes.string.isRequired,
};

export default DiscountInfoCell;
