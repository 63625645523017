import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import intersection from 'lodash/intersection';

import paths from 'app/paths';
import { selectors as authSelectors } from 'ducks/auth';
import ProtectedRoute from './ProtectedRoute';

const AuthRoute = function ({ roles, ...rest }) {
  const { isAuthenticated, isAuthorized } = useSelector(authSelectors.authStatus);
  const userRoles = useSelector(authSelectors.userRoles);

  if (isAuthenticated === false) {
    return <ProtectedRoute {...rest} canShow={false} redirectTo={paths.login.path} />;
  }

  if (isAuthenticated && isAuthorized) {
    const canShow = roles.length === 0 || intersection(roles, userRoles).length > 0;
    return <ProtectedRoute {...rest} canShow={canShow} />;
  }

  return null;
};

AuthRoute.propTypes = {
  ...ProtectedRoute.propTypes,
  roles: PropTypes.arrayOf(PropTypes.string),
};

AuthRoute.defaultProps = {
  roles: [],
};

export default AuthRoute;
