import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Form } from 'formik';

import { useAction } from 'app/hooks';
import { actions as clientsActions } from 'ducks/clients';
import { AmountField } from 'components';
import Modal from 'components/modals/Modal';
import * as yup from 'yup';

const UpdateBalanceForm = memo(({ handleSubmit, modalProps }) => {
  return (
    <Modal titleText="clients:editBalance" onOk={handleSubmit} {...modalProps}>
      <Form>
        <AmountField label="clients:amountToAdd" />
      </Form>
    </Modal>
  );
});

UpdateBalanceForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  modalProps: PropTypes.shape(Modal.propTypes).isRequired,
};

const validationSchema = yup.object().shape({
  amount: yup.number().typeError('app:invalidAmount').required('app:required'),
});

const EnhancedUpdateBalanceForm = withFormik({
  displayName: 'UpdateBalanceForm',
  validationSchema,
  enableReinitialize: true,
  validateOnBlur: false,
  mapPropsToValues: () => ({
    amount: '',
  }),
  handleSubmit: (values, { props: { onSubmit } }) => {
    onSubmit(values);
  },
})(UpdateBalanceForm);

EnhancedUpdateBalanceForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

const UpdateBalanceModal = memo((props) => {
  const { accountId, clientId, ...restProps } = props;
  const updateBalance = useAction(clientsActions.updateBalance);

  const onSubmit = useCallback(
    (data) => {
      updateBalance(accountId, clientId, data);
    },
    [accountId, clientId, updateBalance],
  );

  return <EnhancedUpdateBalanceForm modalProps={restProps} onSubmit={onSubmit} />;
});

UpdateBalanceModal.propTypes = {
  ...Modal.propTypes,
  accountId: PropTypes.number.isRequired,
  clientId: PropTypes.number.isRequired,
};

export default UpdateBalanceModal;
