import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const AdminRoleCell = memo(({ item: { roles } }) => {
  const { t } = useTranslation();
  return <>{roles ? t(`auth:roles.${t(roles[0])}`) : null}</>;
});

AdminRoleCell.propTypes = {
  item: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default AdminRoleCell;
