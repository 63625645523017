import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import useStyles from './Panel.styles';

const Panel = memo(({ title, children, defaultExpanded }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Accordion
      className={classes.root}
      classes={{ expanded: classes.expanded }}
      defaultExpanded={defaultExpanded}
    >
      <AccordionSummary className={classes.summary} expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">{t(title)}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className={classes.content}>{children}</div>
      </AccordionDetails>
    </Accordion>
  );
});

Panel.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  defaultExpanded: PropTypes.bool,
};

Panel.defaultProps = {
  title: '',
  children: undefined,
  defaultExpanded: true,
};

export default Panel;
