import React, { memo } from 'react';
import PropTypes from 'prop-types';

import paths from 'app/paths';
import { actions as campaignActions } from 'ducks/campaigns';
import { ViewLinkButton, RemoveRowAction } from 'components';

const CampaignActionsCell = memo((props) => {
  const {
    item: { id },
  } = props;

  return (
    <>
      <ViewLinkButton to={paths.promotions.path} params={{ campaignId: id }} />
      <RemoveRowAction action={campaignActions.removeCampaign} params={{ id }} />
    </>
  );
});

CampaignActionsCell.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

export default CampaignActionsCell;
