import isArray from 'lodash/isArray';
import get from 'lodash/get';

import i18n from 'app/i18n';

import { promotionActionTypes, promotionDiscountTypes, promotionRuleTypes } from './enums';
import { editableActionTypes } from './const';

/**
 * Helper function to extract translated rule name
 * @param {{
 *  type: string,
 *  configuration: {
 *   method: string,
 *   threshold: number,
 *   amount: number
 *  }
 * }} promotion
 * @returns {string} translated rule name
 */
export const getRuleName = ({
  type,
  configuration: { method, threshold, amount, limit } = {},
} = {}) => {
  const base = `campaigns:promotionRuleTypes.${type}`;

  switch (type) {
    case promotionRuleTypes.CART_HAS_ITEMS:
      return i18n.t(`${base}.${method}`, { threshold });
    case promotionRuleTypes.ITEM_GROUP_TOTAL:
    case promotionRuleTypes.ORDER_TOTAL:
      return i18n.t(base, { amount });
    case promotionRuleTypes.PER_CARD_USAGE_LIMIT:
      return i18n.t(base, { limit });
    case promotionRuleTypes.PER_CARD_DISCOUNT_LIMIT:
      return i18n.t(base, { limit });
    default:
      return i18n.t(base);
  }
};

/**
 * Helper function for mapping discount type from api response
 * @param {{ configuration: { neutral?: boolean }}} action
 * @returns {string} one of 'promotionDiscountTypes'
 */
export const getDiscountType = (action) => {
  if (!action) {
    return '';
  }

  return action.configuration.neutral
    ? promotionDiscountTypes.DELAYED
    : promotionDiscountTypes.IMMEDIATE;
};

/**
 * Helper function for formatting discount amount based on action type
 * @param {{ type: string, configuration: { amount?: number } }} action
 * @returns {string} discount amount string
 */
export const getDiscountAmount = (action) => {
  if (!action || !action.configuration.amount) {
    return undefined;
  }
  if (action.type === promotionActionTypes.PERCENTAGE_DISCOUNT) {
    return action.configuration.amount * 100;
  }
  return action.configuration.amount / 100;
};

/**
 * Helper function that returns discount configuration for form validation from action
 * @param {object} action - action object
 * @returns discount configuration
 */
export const getDiscountData = (action) => ({
  amount: getDiscountAmount(action),
  type: getDiscountType(action),
  limit: get(action, 'configuration.limit', undefined),
  cycle: get(action, 'configuration.cycle', undefined),
});

/**
 * Extracts amount from action rule configuration
 * @param {object} rule
 * @returns
 */
export const getOrderTotalData = (rule) => {
  if (!rule) {
    return undefined;
  }
  return rule.configuration.amount;
};

/**
 * Helper function for initializing promotion creation or edit form values
 * @param {{ promotion: object }} props
 * @returns {object} initial form values
 */
export const mapPromotionToValues = ({ promotion }) => {
  const { startDate = '', endDate = '', target = '', rules = {}, actions = {} } = promotion;

  const cartHasItemsRule = rules[promotionRuleTypes.CART_HAS_ITEMS];
  let cartHasItemsConfig;
  const pligs = {};
  if (cartHasItemsRule) {
    const rulesArray = isArray(cartHasItemsRule) ? cartHasItemsRule : [cartHasItemsRule];
    cartHasItemsConfig = rulesArray[0].configuration;
    rulesArray.forEach((rule) => {
      pligs[rule.id] = rule.pligs.map((plig) => plig.value);
    });
  }

  const orderTotal = getOrderTotalData(rules[promotionRuleTypes.ORDER_TOTAL]);

  const editableFields = editableActionTypes.reduce((editableActions, { type, key }) => {
    editableActions[key] = getDiscountData(actions[type]);
    return editableActions;
  }, {});

  return {
    startDate,
    endDate,
    target,
    pligs,
    actions,
    rules,
    orderTotal,
    cartHasItemsConfig,
    ...editableFields,
  };
};
