import axios from 'axios';

import * as mappers from './serviceMappers';

export function fetchTransactionList(params) {
  return axios
    .get('orders/search', { params: mappers.mapFetchTransactionListRequest(params) })
    .then((response) => mappers.mapFetchTransactionListResponse(response.data));
}

export function fetchTransaction(id) {
  const scope = 'entry_full,item_full,adjustment_full,pos_full,card_full,user_full';
  return axios
    .get(`orders/${id}`, { params: { scope } })
    .then((response) => mappers.mapFetchTransactionResponse(response.data));
}
