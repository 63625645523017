import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Form } from 'formik';

import { passwordValidator } from 'app/validators';
import { useAction } from 'app/hooks';
import { actions } from 'ducks/auth';
import { PasswordField } from 'components';
import Modal from 'components/modals/Modal';
import * as yup from 'yup';

const ChangePasswordForm = memo(({ handleSubmit, modalProps }) => {
  return (
    <Modal titleText="auth:changePassword" onOk={handleSubmit} {...modalProps}>
      <Form>
        <PasswordField name="currentPassword" label="auth:currentPassword" />
        <PasswordField name="newPassword" label="auth:newPassword" />
        <PasswordField name="newPasswordConfirmation" label="auth:newPasswordConfirmation" />
      </Form>
    </Modal>
  );
});

ChangePasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  modalProps: PropTypes.shape(Modal.propTypes).isRequired,
};

const validationSchema = yup.object().shape({
  currentPassword: yup.string().required('app:required'),
  newPassword: passwordValidator().required('app:required'),
  newPasswordConfirmation: yup
    .string()
    .required('app:required')
    .oneOf([yup.ref('newPassword')], 'auth:passwordsDoNotMatch'),
});

const EnhancedChangePasswordForm = withFormik({
  displayName: 'ChangePasswordForm',
  validationSchema,
  enableReinitialize: true,
  validateOnBlur: false,
  mapPropsToValues: () => ({
    currentPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
  }),
  handleSubmit: (values, { props: { changePassword } }) => {
    changePassword(values);
  },
})(ChangePasswordForm);

EnhancedChangePasswordForm.propTypes = {
  changePassword: PropTypes.func.isRequired,
};

const ChangePasswordModal = memo((props) => {
  const changePassword = useAction(actions.changePassword);
  return <EnhancedChangePasswordForm modalProps={props} changePassword={changePassword} />;
});

ChangePasswordModal.propTypes = {
  ...Modal.propTypes,
};

export default ChangePasswordModal;
