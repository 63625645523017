import { Stack, Map } from 'immutable';

import * as actionTypes from './actionTypes';

const initialState = Stack();

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SHOW_MODAL:
      return state.push(
        Map({
          name: action.name,
          props: action.props,
          show: true,
        }),
      );

    case actionTypes.HIDE_MODAL:
      return state.pop().push(state.first().merge({ show: false }));

    case actionTypes.CLEAR_MODAL:
      return state.pop();

    default:
      return state;
  }
}
