import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

const TextHeader = memo((props) => {
  const { text, strong, ...restProps } = props;
  const { t } = useTranslation();
  const content = strong ? <strong>{t(text)}</strong> : t(text);
  return (
    <Typography variant="h5" {...restProps}>
      {content}
    </Typography>
  );
});

TextHeader.propTypes = {
  ...Typography.propTypes,
  text: PropTypes.string.isRequired,
  strong: PropTypes.bool,
};

TextHeader.defaultProps = {
  ...Typography.defaultProps,
  strong: false,
};

export default TextHeader;
