import React from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import 'app/axios';
import 'app/i18n';
import { store } from 'app/state';
import theme from 'app/theme';
import { Router } from 'app/router';
import ModalManager from 'components/modals/ModalManager';
import useStyles from './App.styles';

const App = function () {
  useStyles();

  return (
    <div>
      <StoreProvider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ModalManager />
          <Router />
        </ThemeProvider>
      </StoreProvider>
    </div>
  );
};

export default App;
