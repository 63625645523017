import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import TableCell from '@material-ui/core/TableCell';

import { formatEuro } from 'app/formatters';
import { entryTypes } from 'ducks/clients/enums';

const EntryAmountCell = memo((props) => {
  const { entry } = props;
  const { t } = useTranslation();

  const renderAmount = useCallback(
    (data) => {
      const { type, item } = data;
      const { orders } = item;
      switch (type) {
        case entryTypes.ENTRY:
          return isEmpty(orders) ? t('clients:adminEntry') : formatEuro(orders[0].total);
        case entryTypes.ORDER:
          return formatEuro(item.total);
        default:
          return null;
      }
    },
    [t],
  );

  return <TableCell>{renderAmount(entry)}</TableCell>;
});

EntryAmountCell.propTypes = {
  entry: PropTypes.object.isRequired,
};

export default EntryAmountCell;
