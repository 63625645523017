import * as yup from 'yup';
import {
  cityAddressValidator,
  countryAddressValidator,
  phoneNumberValidator,
  postalCodeValidator,
  streetAddressValidator,
  streetNumberAddressValidator,
} from 'app/validators';

export default yup.object().shape({
  name: yup.string().required('app:required'),
  number: yup.string().required('app:required'),
  newPosAmount: greaterThanValidator('newPosAmount', function () {
    return this.parent.posAmount;
  }),
  priceGroup: yup.string().required('app:required'),
  phoneNumber: phoneNumberValidator(),
  firstName: yup.string(),
  lastName: yup.string(),
  houseNumber: streetNumberAddressValidator(),
  street: streetAddressValidator(),
  postalCode: postalCodeValidator(),
  city: cityAddressValidator(),
  country: countryAddressValidator(),
});

function greaterThanValidator(path, getComparisonValue) {
  return yup
    .number()
    .required('app:required')
    .typeError('stores:invalidPosAmount')
    .test({
      test(value) {
        const comparisonValue = getComparisonValue.call(this);

        if (value < comparisonValue) {
          return this.createError({
            path,
            message: {
              text: 'stores:invalidPosAmount',
              data: { value: comparisonValue },
            },
          });
        }

        return true;
      },
    });
}
