import { promotionActionTypes, promotionTargetTypes } from './enums';

export const editableActionTypes = [
  { type: promotionActionTypes.FIXED_DISCOUNT, key: 'fixedDiscount' },
  { type: promotionActionTypes.PERCENTAGE_DISCOUNT, key: 'percentageDiscount' },
  { type: promotionActionTypes.FIN_DISCOUNT, key: 'finDiscount' },
  { type: promotionActionTypes.FIXED_TOTAL_DISCOUNT, key: 'fixedTotalDiscount' },
];

export const targetIdByTargetType = {
  [promotionTargetTypes.ALL_CLIENTS]: 0,
  [promotionTargetTypes.CARDHOLDERS]: 1,
  [promotionTargetTypes.NON_CARDHOLDERS]: 2,
};
