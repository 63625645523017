import React, { forwardRef, useCallback } from 'react';
import * as yup from 'yup';
import { Form, Formik } from 'formik';

import { endMonthValidator, monthValidator } from 'app/validators';
import { useAction } from 'app/hooks';
import { actions as storeActions } from 'ducks/stores/index';
import { StartMonthField, EndMonthField } from 'components/index';
import PropTypes from 'prop-types';

const validationSchema = yup.object().shape({
  startMonth: monthValidator().required('app:required'),
  endMonth: endMonthValidator(),
});

export const GenerateMonthlyReportForm = forwardRef((props, ref) => {
  const generateReport = useAction(storeActions.generateMonthlyReport);

  const onSubmit = useCallback(
    (values) => {
      generateReport(props.storeNumber, values);
    },
    [props.storeNumber],
  );

  return (
    <Formik
      initialValues={{
        startMonth: '',
        endMonth: '',
      }}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnBlur
      innerRef={ref}
      validationSchema={validationSchema}
    >
      {() => (
        <Form>
          <StartMonthField />
          <EndMonthField label="app:endMonthOptional" />
        </Form>
      )}
    </Formik>
  );
});

GenerateMonthlyReportForm.defaultProps = {
  storeNumber: undefined,
};

GenerateMonthlyReportForm.propTypes = {
  storeNumber: PropTypes.string,
};

export default React.memo(GenerateMonthlyReportForm);
