export default {
  app: {
    appName: 'G20',
    adminInterface: 'Admin Interface',
    main: 'Main',
    ok: 'Valider',
    cancel: 'Annuler',
    success: 'Succès',
    error: 'Erreur',
    confirmation: 'Confirmation',
    confirmationQuestion: 'Voulez-vous vraiment effectuer cette action?',
    required: 'Requis',
    first: 'Premier',
    last: 'Dernier',
    previous: 'Précédent',
    next: 'Suivant',
    id: 'ID',
    invalidId: 'ID invalide',
    selected: 'Sélectionnés',
    username: "Nom d'utilisateur",
    none: 'Aucun',
    action: 'Action',
    actions: 'Actions',
    search: 'Rechercher',
    view: 'Vue',
    save: 'Enregistrer',
    submit: 'Nous faire parvenir',
    add: 'Ajouter',
    added: 'Ajouté avec succès',
    cannotAdd: "Impossible d'ajouter",
    create: 'Créer',
    created: 'Création réussie',
    cannotCreate: 'Ne peut créer',
    edit: 'Éditer',
    updated: 'Mis à jour avec succès',
    uploaded: 'Fichier mis à jour avec succès',
    cannotUpdate: 'Impossible de mettre à jour',
    cannotUpload: 'Impossible de télécharger le fichier',
    remove: 'Supprimer',
    removeAll: 'Supprimer tout',
    removed: 'Supprimé avec succès',
    cannotRemove: 'Impossible de supprimer',
    activate: 'Activer',
    activated: 'Activé avec succès',
    cannotActivate: "Impossible d'activer",
    deactivate: 'Désactiver',
    deactivated: 'Désactivé avec succès',
    cannotDeactivate: 'Impossible de désactiver',
    somethingWentWrong: 'Quelque chose a mal tourné',
    number: 'Numéro',
    invalidNumber: 'Numéro invalide',
    name: 'Nom',
    contact: 'Contact',
    phoneNumber: 'Numéro de téléphone',
    invalidPhoneNumber: 'Numéro de téléphone invalide',
    address: 'Adresse',
    houseNumber: 'Numéro de rue',
    street: 'Rue',
    postalCode: 'Code postal',
    invalidPostalCode: 'Code postal invalide',
    city: 'Ville',
    country: 'Pays',
    startDate: 'Date de début',
    endDate: 'Date de fin',
    invalidDate: 'Date invalide',
    notLessThanStartDate: 'Ne doit pas être inférieur à la date de début',
    information: 'Informations',
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    label: 'Label',
    lastModification: 'Dernière modification',
    general: 'Général',
    noOptions: 'Aucune option',
    date: 'Date',
    noData: 'Aucune entrée',
    amount: 'Montant',
    invalidAmount: 'Montant invalide',
    quantity: 'Quantité',
    total: 'Total',
    yes: 'Oui',
    no: 'Non',
    na: 'N/A',
    description: 'Description',
    actionIsNotReversible: "Attention: cette action n'est pas réversible!",
    creationDate: 'Date de création',
    enabled: 'Activée',
    disabled: 'Désactivée',
    showMore: 'Voir plus',
    restored: 'Restauré avec succès',
    restore: 'Restaurer',
    startMonth: 'Mois de début',
    endMonth: 'Mois de fin',
    endMonthOptional: 'Mois de fin (optionnel)',
    endDateOptional: 'Date de fin (optionnel)',
  },
  auth: {
    roles: {
      ROLE_SUPER_ADMIN: 'Super Admin',
      ROLE_G20_ADMIN: 'G20 Admin',
      ROLE_STORE: 'Store Admin',
    },
    role: 'Rôle',
    logIn: 'Connexion',
    logOut: 'Déconnexion',
    email: 'Email',
    changeEmail: "Changer l'e-mail",
    emailChanged: "L'email a été changé",
    cannotChangeEmail: "Impossible de modifier l'e-mail",
    password: 'Mot de passe',
    passwordConfirmation: 'Confirmation mot de passe',
    changePassword: 'Modifier le mot de passe',
    resetPassword: 'Réinitialiser le mot de passe',
    passwordReset: 'Le mot de passe a été réinitialisé',
    cannotResetPassword: 'Impossible de réinitialiser le mot de passe',
    currentPassword: 'Votre mot de passe',
    newPassword: 'Nouveau mot de passe',
    newPasswordConfirmation: 'Confirmer nouveau mot de passe',
    passwordRequirements:
      'Le mot de passe doit contenir: 1 majuscule, 1 minuscule, et un chiffre et faire au moins 8 caractères',
    passwordsDoNotMatch: 'Les mots de passe ne correspondent pas',
    passwordChanged: 'Le mot de passe a été modifié',
    invalidEmail: 'Email invalide',
    invalidToken: 'Token invalide',
    invalidCredentials: 'Identifiant ou mot de passe incorrect',
    invalidCurrentPassword: 'Impossible de modifier le mot de passe',
    emailSent: "L'email a été envoyé",
    exportPersonalData: 'Exporter des données personnelles',
    removePersonalData: 'Supprimer les données personnelles',
  },
  admins: {
    admins: 'Administrateurs',
    addAdmin: "Ajout d'administrateur",
    editAdmin: "Edition d'administrateur",
    group: 'Groupe',
    groups: 'Groupes',
    locked: 'Supprimé',
  },
  stores: {
    startAtShouldBeGreaterThanCurrentDate: '« Fin à » doit être supérieur au jour actuel !',
    loyaltyDisablePeriods: 'Périodes de désactivation de la fidélité',
    periodsShouldNotOverlap: 'Les périodes ne doivent pas se chevaucher',
    startAtIsRequired: '« Début à » est requis !',
    endAtIsRequired: '« Fin à » est requis !',
    endAtGreaterThanStartAt: '« Fin à » doit être supérieur à « Début à »',
    endAt: 'Fin à',
    startAt: 'Début à',
    addPeriod: 'Ajouter une période',
    store: 'Magasin',
    stores: 'Magasins',
    priceGroup: 'Groupe de prix',
    exportOnlinePercentage: 'Export online percentage statistic',
    editStore: 'Modifier le magasin',
    addStore: 'Ajouter le magasin',
    storeNumber: 'Numéro de magasin',
    invalidStoreNumber: 'Numéro de magasin non valide',
    posNumber: 'Numéro de poste',
    generateReport: 'Générer un rapport',
    reportGenerated: 'Rapport généré',
    cannotGenerateReport: 'Impossible de générer le rapport',
    reporting: 'Création de rapports',
    status: 'Statut du magasin',
    currentStatus: 'Statut actuel',
    reportingList: 'Création de rapports activée',
    dailyReport: 'Rapport journalier',
    monthlyReport: 'Rapport mensuel',
    reportRequestSent: 'Demande de génération de rapport envoyée',
    posAmount: 'Nombre de points de vente',
    invalidPosAmount: 'Le nombre de points de vente ne doit pas être inférieur à {{value}}',
    groupHaveAdmin: 'Le groupe sélectionné a déjà des administrateurs.',
  },
  campaigns: {
    campaigns: 'Campagnes',
    campaignName: 'Nom de la campagne',
    campaignDescription: 'Description de la campagne',
    create: 'Créer une nouvelle campagne',
    rules: 'Règles',
    promotion: 'Promotion',
    promotionCreate: 'Création de la promotion',
    promotions: 'Promotions',
    promotionDetails: 'Détails de la promotion',
    promotionName: 'Identifiant de promotion',
    promotionId: 'ID de promotion',
    invalidPromotionId: 'ID de promotion non valide',
    promotionCount: 'Nombre de promotions',
    uploadSpecialCampaignFile: 'Télécharger un fichier de campagne spécial',
    uploadButton: 'Télécharger',
    promotionRuleTypes: {
      has_account: 'Doit avoir un compte de fidélité',
      cart_has_items: {
        all: '{{threshold}} fois tous les produits du panier',
        count: 'Au moins {{threshold}} produit de la liste',
        one_of: 'Le panier doit posséder au moins {{threshold}} fois un des produits de la liste',
      },
      item_group_total:
        'Le total des produits de la liste présent dans le panier atteignent {{amount}}',
      per_plig_usage_limit: "Limite d'utilisation par plig",
      per_card_usage_limit: "Limite d'utilisation par carte est de {{limit}} fois",
      items_have_reference_price: "Le prix de l'article est inférieur ou égal au prix de référence",
      order_total: 'Total panier avant remises est supérieure ou égale à {{amount}} €',
      per_card_discount_limit: 'Limite totale de réduction par carte: {{limit}} €',
    },
    promotionRuleTypesBrief: {
      per_card_usage_limit: "Limite d'utilisation par carte",
      order_total: 'Total panier avant remises',
      per_card_discount_limit: 'Limite totale de réduction par carte',
    },
    target: 'Cible',
    promotionTargetTypes: {
      cardholders: 'Porteurs',
      non_cardholders: 'Non porteurs',
      all_clients: 'Porteurs et non porteurs',
    },
    cartHasItems: {
      method: 'Méthode',
      threshold: 'Au seuil',
      all: 'Tous',
      count: 'Quantité',
      one_of: 'Un de',
    },
    removePligsList: 'Supprimer liste des pligs ({{n}})',
    minPligs: 'La liste des pligs doit contenir 1 élément au moins',
    addSecondPligList: 'Ajouter la liste des pligs',
    limit: 'Limite par ticket',
    limitPerCard: 'Limite par carte',
    limitPerCardEuro: 'Limite par carte (€)',
    cycle: 'Cycle',
    addRule: 'Ajouter une règle',
    removeRule: 'Supprimer la règle',
    discount: 'Remise',
    discounts: 'Remises',
    discountType: 'Type de remise',
    delayed: 'Différé',
    immediate: 'Immédiate',
    immediateDiscounts: 'Remises immédiates',
    fixedTotalDiscount: 'Remise fixe sur total ticket',
    fixedDiscount: 'Remise fixe',
    percentageDiscount: 'Remise en pourcentage',
    finDiscount: 'Remise neutre sur le premier article',
    orderTotalAmount: 'Montant total (€)',
    discountAmountEuro: 'Montant de la remise (€)',
    discountAmountPercent: 'Montant de la remise (%)',
    allToCouponBased:
      'Rendre toutes les promotions de cette campagne accessible sur utilisation de coupon',
    allToNonCouponBased:
      'Rendre toutes les promotions de cette campagne soient accessibles sans utiliser de coupon',
    selectPromotionType: 'Choisissez le type de promotion',
    createPromo: {
      basic1: "Promotion basée sur une liste d'articles",
      basic2: 'Promotion basée sur le montant du panier',
      special1: 'Special cumulative pack',
      totalDependent: "Promotion avec des règles d'accumulation spéciales",
    },
    special: {
      panel: 'Special cumulitive pack',
      oneItem: 'Un article',
      twoItems: 'Deux articles',
      threeAndMoreItems: 'Trois articles ou plus',
      error: 'La valeur doit être supérieure ou égale à {{value}}',
    },
    dynamic: "Règles d'accumulation modifiables",
    totalDependent: {
      panel: "Règles d'accumulation",
      accruingRules: "Règles d'accumulation",
      total: 'Montant minimum de commande',
      discount: 'Valeur accumulée sur la carte',
      limit1: 'Limite 1',
      limit2: 'Limite 2',
      limit3: 'Limite 3',
      limit4: 'Limite 4',
      limit5: 'Limite 5',
      limit6: 'Limite 6',
    },
  },
  products: {
    products: 'Des produits',
    productDetails: "Détails d'un produit",
    plig: 'Plig',
    pligs: 'Pligs',
    eansCount: "Nombre d'EANs",
    ean: 'EAN',
    eans: 'EANs',
    referencePrices: 'Prix de références',
  },
  transactions: {
    transactions: 'Transactions',
    transactionDetails: 'Détails de la transaction',
    transactionNumber: 'Numéro de transaction',
    exportTransactions: "Transactions d'exportation",
    article: 'Article',
    listOfArticles: 'Liste des articles',
    unitPrice: 'Prix unitaire',
    accumulationOnLoyaltyAccount: 'Cumul sur compte de fidélité',
    balanceBeforeAfter: 'Solde du compte avant / après transaction',
  },
  clients: {
    client: 'Client',
    clients: 'Clients',
    clientDetails: 'Détails du client',
    cardNumber: 'Numéro de carte',
    cardNumbers: 'Numéros de carte (séparés par une virgule)',
    confirmed: 'Confirmé',
    accounts: 'Soldes',
    exportBestClients: 'Exporter les 100 meilleurs clients des 3 derniers mois',
    revenue: 'Revenu',
    clientProfile: 'Profil client',
    gender: 'Sexe',
    genderTypes: {
      mr: 'M.',
      mrs: 'Mme.',
      ms: 'Mme.',
      miss: 'Mlle.',
    },
    birthday: 'Date de naissance',
    clientEmailChanged:
      "La demande de modification de l'email a été enregistré. Le client va recevoir un email (sur sa nouvelle adresse) afin de valider ce changement.",
    deactivationWarning:
      'ATTENTION : Si vous désactivez le compte, le client ne pourra plus se connecter, ni utiliser sa carte de fidélité !',
    removePersonalDataWarning:
      'Attention : la suppression des données personnelles est irréversible !',
    loyaltyInfo: 'Information fidélité',
    loyaltyCard: 'Carte de fidélité',
    balance: 'Solde',
    balanceHistory: 'Historique de solde',
    adminEntry: 'Action admin',
    editBalance: 'Modification de solde',
    amountToAdd: 'Ajouter/Enlever des centimes de la cagnotte',
    userEmailChange:
      'Le client possède un compte Web. Une confirmation sera préalablement envoyée sur son adresse mail',
    clientEmailChange:
      'Le client ne possède pas de compte Web. Le changement sera effectif immediatement',
    uploadCardsFile: 'Télécharger un fichier avec des cartes',
    uploadButton: 'Télécharger',
    createCardsRequestSent: 'Demande de création de cartes envoyée',
    expiredBalanceButton: 'Compter le solde expiré',
    expiredBalance: "Solde expiré pour l'année dernière",
    subtractExpiredBonusesRequestSent: 'Demande de soustraction des bonus expirés envoyée',
    subtractExpiredBonuses: 'Soustraire les bonus expirés pour la dernière année',
    subtractConfirmationQuestion:
      "Êtes-vous sûr de vouloir réinitialiser le solde de l'année dernière pour tous les clients? Cette action ne doit être effectuée qu'une fois par an.",
  },
  coupons: {
    coupons: 'Coupons',
    addCoupon: 'Ajouter un coupon',
    barcode: 'Code à barre',
    isUsed: 'Est utilisé',
    usableAt: 'Utilisable à',
    expiresAt: 'Expire à',
    couponUsing: 'Coupon utilisant',
  },
  address: {
    number: {
      maxLength: 'Le numéro de rue ne doit pas dépasser 20 caractères',
    },
    street: {
      maxLength: 'Le rue ne doit pas dépasser 255 caractères',
    },
    city: {
      maxLength: 'La ville ne doit pas dépasser 50 caractères',
    },
    country: {
      maxLength: 'Le pays ne doit pas dépasser 50 caractères',
    },
  },
};
