import paths from 'app/paths';
import StoreEdit from 'components/stores/StoreView/StoreEdit';
import StoreAdd from 'components/stores/StoreView/StoreAdd';
import StoreList from 'components/stores/StoreList/StoreList';

export default [
  {
    path: paths.storesEdit.path,
    roles: paths.storesEdit.roles,
    component: StoreEdit,
  },
  {
    path: paths.stores.path,
    roles: paths.stores.roles,
    component: StoreList,
  },
  {
    path: paths.storesAdd.path,
    roles: paths.storesAdd.roles,
    component: StoreAdd,
  },
];
