import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { AddButton } from '../buttons';
import LinkButton from './LinkButton';

const AddLinkButton = memo((props) => <LinkButton {...props} />);

AddLinkButton.propTypes = {
  ...LinkButton.propTypes,
  component: PropTypes.object,
};

AddLinkButton.defaultProps = {
  ...LinkButton.defaultProps,
  component: AddButton,
};

export default AddLinkButton;
