import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const TargetCell = memo((props) => {
  const {
    item: { target },
  } = props;
  const { t } = useTranslation();
  return <>{target && t(`campaigns:promotionTargetTypes.${target}`)}</>;
});

TargetCell.propTypes = {
  item: PropTypes.object.isRequired,
};

export default TargetCell;
