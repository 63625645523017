import React, { memo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { useAction } from 'app/hooks';
import { passwordValidator } from 'app/validators';
import { actions as adminsActions, selectors as adminsSelectors } from 'ducks/admins';
import { Breadcrumbs } from 'components';
import AdminForm from './AdminForm';

const validationSchema = yup.object().shape({
  username: yup.string().required('app:required'),
  email: yup.string().email('auth:invalidEmail').required('app:required'),
  password: passwordValidator().ensure(),
  passwordConfirmation: yup
    .string()
    .ensure()
    .oneOf([yup.ref('password')], 'auth:passwordsDoNotMatch'),
  role: yup.string().required('app:required'),
});

const AdminEdit = memo(
  ({
    match: {
      params: { id },
    },
  }) => {
    const editingAdmin = useSelector(adminsSelectors.editingAdmin);
    const fetchAdmin = useAction(adminsActions.fetchAdmin);
    const updateAdmin = useAction(adminsActions.updateAdmin);

    useEffect(() => {
      if (id) {
        fetchAdmin(id);
      }
    }, [id, fetchAdmin]);

    const onSubmit = useCallback(
      (values) => {
        updateAdmin(id, values);
      },
      [id, updateAdmin],
    );

    return (
      <>
        <Breadcrumbs />
        <AdminForm validationSchema={validationSchema} onSubmit={onSubmit} admin={editingAdmin} />
      </>
    );
  },
);

AdminEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default AdminEdit;
