export const FETCH_STORE_LIST = 'stores/FETCH_STORE_LIST';
export const FETCH_STORE_LIST_SUCCESS = 'stores/FETCH_STORE_LIST_SUCCESS';

export const FETCH_ONLINE_PERCENTAGE = 'stores/FETCH_ONLINE_PERCENTAGE';

export const FETCH_STORE = 'stores/FETCH_STORE';
export const FETCH_STORE_SUCCESS = 'stores/FETCH_STORE_SUCCESS';

export const ADD_STORE = 'stores/ADD_STORE';
export const UPDATE_STORE = 'stores/UPDATE_STORE';

export const FETCH_PRICE_GROUPS = 'stores/FETCH_PRICE_GROUPS';
export const FETCH_PRICE_GROUPS_SUCCESS = 'stores/FETCH_PRICE_GROUPS_SUCCESS';

export const FETCH_STORE_OPTIONS = 'stores/FETCH_STORE_OPTIONS';
export const FETCH_STORE_OPTIONS_SUCCESS = 'stores/FETCH_STORE_OPTIONS_SUCCESS';

export const GENERATE_DAILY_REPORT = 'stores/GENERATE_DAILY_REPORT';
export const GENERATE_MONTHLY_REPORT = 'stores/GENERATE_MONTHLY_REPORT';
export const GENERATE_REPORT_SUCCESS = 'stores/GENERATE_REPORT_SUCCESS';

export const ACTIVATE_STORE = 'stores/ACTIVATE_STORE';
export const DEACTIVATE_STORE = 'stores/DEACTIVATE_STORE';

export const ENABLE_STORE_REPORTING = 'stores/ENABLE_STORE_REPORTING';
export const DISABLE_STORE_REPORTING = 'stores/DISABLE_STORE_REPORTING';

export const CREATE_LOYALTY_DISABLED_PERIOD = 'stores/CREATE_LOYALTY_DISABLED_PERIOD';
export const CREATE_LOYALTY_DISABLED_PERIOD_SUCCESS =
  'stores/CREATE_LOYALTY_DISABLED_PERIOD_SUCCESS';
export const CREATE_LOYALTY_DISABLED_PERIOD_FAILED = 'stores/CREATE_LOYALTY_DISABLED_PERIOD_FAILED';

export const UPDATE_LOYALTY_DISABLED_PERIOD = 'stores/UPDATE_LOYALTY_DISABLED_PERIOD';
export const UPDATE_LOYALTY_DISABLED_PERIOD_SUCCESS =
  'stores/UPDATE_LOYALTY_DISABLED_PERIOD_SUCCESS';
export const UPDATE_LOYALTY_DISABLED_PERIOD_FAILED = 'stores/UPDATE_LOYALTY_DISABLED_PERIOD_FAILED';

export const REMOVE_LOYALTY_DISABLED_PERIOD = 'stores/REMOVE_LOYALTY_DISABLED_PERIOD';
export const REMOVE_LOYALTY_DISABLED_PERIOD_SUCCESS =
  'stores/REMOVE_LOYALTY_DISABLED_PERIOD_SUCCESS';
export const REMOVE_LOYALTY_DISABLED_PERIOD_FAILED = 'stores/REMOVE_LOYALTY_DISABLED_PERIOD_FAILED';

export const UPLOAD_CARDS_FILE_FOR_STORE = 'stores/UPLOAD_CARDS_FILE_FOR_STORE';
