import { makeStyles } from '@material-ui/core/styles';

const openWidth = 250;
const closedWidth = 70;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: openWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: openWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: closedWidth,
    },
  },
  paper: {
    backgroundColor: '#2d3437',
  },
  item: {
    borderLeft: '3px solid transparent',
    '&:hover': {
      borderLeft: '3px solid #e99d1a',
    },
    '& *': {
      color: '#797979',
    },
    '&:hover *': {
      color: '#fff',
    },
  },
  itemIcon: {
    marginLeft: '5px',
  },
  header: {
    paddingLeft: '12px',
    paddingTop: '6px',
    cursor: 'pointer',
    '& *': {
      color: '#797979',
    },
  },
  headerText: {
    marginLeft: '22px',
  },
}));

export default useStyles;
