import { G20_ADMIN, STORE_ADMIN, SUPER_ADMIN } from 'app/roles';

export default {
  products: {
    path: '/products',
    name: 'products:products',
    roles: [SUPER_ADMIN, G20_ADMIN, STORE_ADMIN],
  },
  productsView: {
    path: '/products/:id',
    name: 'products:productDetails',
    roles: [SUPER_ADMIN, G20_ADMIN, STORE_ADMIN],
  },
};
