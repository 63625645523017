import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined';

import { useAction } from 'app/hooks';
import { selectors as authSelectors } from 'ducks/auth';
import { actions as campaignsActions, selectors as campaignsSelectors } from 'ducks/campaigns';
import { actions as modalsActions, modalTypes } from 'ducks/modals';
import { List, Breadcrumbs } from 'components';

import PromotionFilter from './PromotionFilter';
import PligCell from './PligsCell';
import TargetCell from './TargetCell';
import RulesCell from './RulesCell';
import DiscountTypeCell from './DiscountTypeCell';
import DiscountCell from './DiscountCell';
import PromotionActionsCell from './PromotionActionsCell';

const rowConfig = [
  {
    name: 'pligs',
    label: 'products:pligs',
    component: PligCell,
    sortable: true,
  },
  {
    name: 'target',
    label: 'campaigns:target',
    component: TargetCell,
  },
  {
    name: 'rules',
    label: 'campaigns:rules',
    component: RulesCell,
  },
  {
    name: 'discountType',
    label: 'campaigns:discountType',
    component: DiscountTypeCell,
  },
  {
    name: 'discount',
    label: 'campaigns:discount',
    component: DiscountCell,
  },
  {
    name: 'actions',
    label: 'app:actions',
    component: PromotionActionsCell,
  },
];

const PromotionList = memo(
  ({
    match: {
      params: { campaignId },
    },
  }) => {
    const { items, meta } = useSelector(campaignsSelectors.promotionList);
    const isAppAdmin = useSelector(authSelectors.isAppAdmin);
    const isSuperAdmin = useSelector(authSelectors.isSuperAdmin);
    const fetchPromotionList = useAction(campaignsActions.fetchPromotionList);
    const updateCouponBased = useAction(campaignsActions.updateCouponBased);
    const showModal = useAction(modalsActions.showModal);

    const onFetchData = useCallback(
      (data) => {
        const { filter, ...rest } = data;
        const updatedData = {
          filter: { ...filter, campaignId },
          ...rest,
        };
        return fetchPromotionList(updatedData);
      },
      [campaignId, fetchPromotionList],
    );

    const toolbarActions = useMemo(() => {
      return [
        {
          name: 'allToCouponBased',
          label: 'campaigns:allToCouponBased',
          icon: ConfirmationNumberIcon,
          show: isAppAdmin,
          confirm: true,
          callback: ({ refreshData }) =>
            updateCouponBased(
              {
                id: campaignId,
                target: 'campaigns',
                value: true,
              },
              refreshData,
            ),
        },
        {
          name: 'allToNonCouponBased',
          label: 'campaigns:allToNonCouponBased',
          icon: ConfirmationNumberOutlinedIcon,
          show: isAppAdmin,
          confirm: true,
          callback: ({ refreshData }) =>
            updateCouponBased(
              {
                id: campaignId,
                target: 'campaigns',
                value: false,
              },
              refreshData,
            ),
        },
        {
          name: 'add',
          label: 'app:add',
          icon: AddCircleIcon,
          show: isSuperAdmin,
          confirm: false,
          callback: () => showModal(modalTypes.PROMO_TYPE_SELECT_MODAL, { campaignId }),
        },
      ];
    }, [campaignId, updateCouponBased, isAppAdmin, isSuperAdmin, showModal]);

    return (
      <>
        <Breadcrumbs />
        <List
          data={items}
          meta={meta}
          onFetchData={onFetchData}
          filterComponent={PromotionFilter}
          rowConfig={rowConfig}
          toolbarActions={toolbarActions}
        />
      </>
    );
  },
);

PromotionList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      campaignId: PropTypes.string,
    }),
  }).isRequired,
};

export default PromotionList;
