import { call, put, select, all, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import paths from 'app/paths';
import { showSuccess, handleError, startLoading, finishLoading } from 'ducks/app/actions';
import { hideModal } from 'ducks/modals/actions';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as selectors from './selectors';
import * as services from './services';

function* checkAuth() {
  try {
    const result = localStorage.getItem('token') !== null;
    yield put(actions.checkAuthSuccess(result));
  } catch (e) {
    yield put(handleError(e));
  }
}

function* login(action) {
  try {
    yield put(startLoading());
    const result = yield call(services.login, action.data);
    localStorage.setItem('token', result.token);
    yield put(actions.loginSuccess());
    yield put(push(paths.stores.path));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* logout() {
  try {
    localStorage.removeItem('token');
    yield put(actions.logoutSuccess());
    yield put(push(paths.login.path));
  } catch (e) {
    yield put(handleError(e));
  }
}

function* fetchUser() {
  try {
    yield put(startLoading());
    const result = yield call(services.fetchUser);
    yield put(actions.fetchUserSuccess(result));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* changePassword(action) {
  try {
    yield put(startLoading());
    const { id } = yield select(selectors.user);
    yield call(services.changePassword, id, action.data);
    yield put(showSuccess('auth:passwordChanged'));
    yield put(hideModal());
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* resetPassword(action) {
  try {
    yield put(startLoading());
    yield call(services.resetPassword, action.data);
    yield put(showSuccess('auth:emailSent'));
    yield put(hideModal());
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* resetPasswordConfirm(action) {
  try {
    if (!action.token) {
      throw new Error('auth:cannotResetPassword');
    }
    yield put(startLoading());
    yield call(services.resetPasswordConfirm, action.token, action.data);
    yield put(showSuccess('auth:passwordReset'));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

export default function* () {
  yield all([
    yield takeLatest(actionTypes.CHECK_AUTH, checkAuth),
    yield takeLatest(actionTypes.LOGIN, login),
    yield takeLatest(actionTypes.LOGOUT, logout),
    yield takeLatest(actionTypes.FETCH_USER, fetchUser),
    yield takeLatest(actionTypes.CHANGE_PASSWORD, changePassword),
    yield takeLatest(actionTypes.RESET_PASSWORD, resetPassword),
    yield takeLatest(actionTypes.RESET_PASSWORD_CONFIRM, resetPasswordConfirm),
  ]);
}
