import React, { memo } from 'react';

import { TextField } from 'components';

const PromotionNameField = memo((props) => <TextField {...props} />);

PromotionNameField.propTypes = {
  ...TextField.propTypes,
};

PromotionNameField.defaultProps = {
  ...TextField.defaultProps,
  name: 'promotionName',
  label: 'campaigns:promotionName',
};

export default PromotionNameField;
