import React, { memo } from 'react';

import { Filter, NameField, DescriptionField } from 'components';

const CampaignFilter = memo(() => {
  return (
    <Filter
      initialValues={{
        name: '',
        description: '',
      }}
      mainField={<NameField />}
    >
      <NameField />
      <DescriptionField />
    </Filter>
  );
});

export default CampaignFilter;
