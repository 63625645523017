import React, { memo } from 'react';

import Button from './Button';

const PrimaryButton = memo((props) => <Button {...props} />);

PrimaryButton.propTypes = {
  ...Button.propTypes,
};

PrimaryButton.defaultProps = {
  ...Button.defaultProps,
  variant: 'contained',
  color: 'primary',
};

export default PrimaryButton;
