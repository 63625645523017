import React from 'react';

import TextField from './TextField';

const IntegerField = function (props) {
  return <TextField {...props} />;
};

IntegerField.propTypes = {
  ...TextField.propTypes,
};

IntegerField.defaultProps = {
  ...TextField.defaultProps,
  name: 'amount',
  label: 'app:amount',
  type: 'number',
  inputProps: { step: '1' },
};

export default React.memo(IntegerField);
