import React, { memo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useAction } from 'app/hooks';
import { selectors as authSelectors } from 'ducks/auth';
import { actions as campaignsActions, selectors as campaignsSelectors } from 'ducks/campaigns';
import { Breadcrumbs, Button } from 'components';
import { PromotionDetails } from './PromotionDetails';
import { PromotionEdit } from './PromotionEdit';

const PromotionView = memo((props) => {
  const {
    match: {
      params: { promotionId },
    },
  } = props;
  const isSuperAdmin = useSelector(authSelectors.isSuperAdmin);
  const fetchPromotion = useAction(campaignsActions.fetchPromotion);
  const promotion = useSelector(campaignsSelectors.viewingPromotion);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    !isEdit && fetchPromotion(promotionId);
  }, [promotionId, fetchPromotion, isEdit]);

  const switchMode = useCallback(() => {
    setIsEdit((prevEdit) => !prevEdit);
  }, []);

  return (
    <>
      <Breadcrumbs />
      {isSuperAdmin && (
        <Button
          label={isEdit ? 'app:cancel' : 'app:edit'}
          variant="outlined"
          onClick={switchMode}
        />
      )}
      {!isEdit && <PromotionDetails promotion={promotion} />}
      {isEdit && <PromotionEdit promotion={promotion} />}
    </>
  );
});

PromotionView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      promotionId: PropTypes.string,
    }),
  }).isRequired,
};

export default PromotionView;
