import { G20_ADMIN, SUPER_ADMIN } from 'app/roles';

export default {
  coupons: {
    path: '/coupons',
    name: 'coupons:coupons',
    roles: [SUPER_ADMIN, G20_ADMIN],
  },
  couponsAdd: {
    path: '/coupons/add',
    name: 'coupons:addCoupon',
    roles: [SUPER_ADMIN, G20_ADMIN],
  },
};
