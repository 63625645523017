import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';

import { useAction, useListSelector } from 'app/hooks';
import { actions as productsActions, selectors as productsSelectors } from 'ducks/products';
import { AutocompleteField } from 'components';

const PligActField = memo(({ name, id, ...props }) => {
  const fetchPligOptions = useAction(productsActions.fetchPligOptions);
  const data = useListSelector((state) => productsSelectors.pligOptionValues(state, id));

  const onFetch = useCallback(
    (actId, value) => {
      fetchPligOptions(actId, { filter: { plig: value } }, id);
    },
    [fetchPligOptions, id],
  );

  return (
    <AutocompleteField
      name={`${name}.${id}`}
      label="products:plig"
      data={data}
      onFetch={onFetch}
      valueSource="name"
      {...props}
    />
  );
});

PligActField.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
};

export default PligActField;
