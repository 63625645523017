import { CLIENT_ID } from 'app/config';

import { errorTypes as authErrorTypes } from 'ducks/auth';
import { mapUser } from './entityMappers';

export function mapLoginRequest({ email, password }) {
  return {
    client_id: CLIENT_ID,
    grant_type: 'password',
    username: email,
    password,
  };
}

export function mapLoginResponse({ accessToken }) {
  return { token: accessToken };
}

export function mapLoginError(e) {
  const {
    response: {
      status,
      data: { error },
    },
  } = e;
  if (status === 400 && error === 'invalid_grant') {
    e.type = authErrorTypes.INVALID_CREDENTIALS;
  }
  throw e;
}

export function mapUserResponse(data) {
  return mapUser(data);
}

export function mapChangePasswordRequest({ currentPassword, newPassword }) {
  return {
    oldPassword: currentPassword,
    newPassword,
  };
}

export function mapChangePasswordError(e) {
  const {
    response: { status },
  } = e;
  if (status === 400) {
    e.type = authErrorTypes.INVALID_CURRENT_PASSWORD;
  }
  throw e;
}

export function mapResetPasswordRequest({ email }) {
  return {
    email,
  };
}

export function mapResetPasswordConfirmRequest({ newPassword }) {
  return {
    plainPassword: newPassword,
  };
}

export function mapResetPasswordError(e) {
  const {
    response: { status },
  } = e;
  if ([400, 404].includes(status)) {
    e.type = authErrorTypes.INVALID_EMAIL;
  }
  throw e;
}

export function mapResetPasswordConfirmError(e) {
  const {
    response: { status },
  } = e;
  if ([400, 404].includes(status)) {
    e.type = authErrorTypes.INVALID_TOKEN;
  }
  throw e;
}
