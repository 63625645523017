import axios from 'axios';

import { API_BASE } from 'app/config';
import { toCamelCase } from 'app/utils';
import paths from 'app/paths';

axios.interceptors.request.use((config) => {
  const section = config.url === 'token' ? 'oauth/v2/' : 'api/';
  config.url = `${API_BASE}${section}${config.url}`;

  if (!config.headers['Content-Type']) {
    config.headers['Content-Type'] = 'application/json';
  }

  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  config.transformResponse = [
    (data) => {
      const isJson = !config.responseType || config.responseType === 'json';
      return data && isJson ? toCamelCase(JSON.parse(data)) : data;
    },
  ];

  return config;
});

axios.interceptors.response.use(null, (error) => {
  if (error.response && error.response.status === 401) {
    localStorage.removeItem('token');
    window.location.href = paths.login.path;
  }
  return Promise.reject(error);
});
