import React, { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { actions as couponsActions } from 'ducks/coupons';
import { ManipulationRowAction, DeactivateButton } from 'components';

const CouponActionsCell = memo((props) => {
  const {
    item: { id, isCashed, expiresAt },
  } = props;
  const canDeactivate = !isCashed && moment(expiresAt).isAfter(moment());

  return (
    <>
      {canDeactivate && (
        <ManipulationRowAction
          component={DeactivateButton}
          action={couponsActions.deactivateCoupon}
          params={{ id }}
        />
      )}
    </>
  );
});

CouponActionsCell.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    isCashed: PropTypes.bool,
    expiresAt: PropTypes.string,
  }).isRequired,
};

export default CouponActionsCell;
