import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import RestoreIcon from '@material-ui/icons/RestoreFromTrash';

const RestoreButton = memo(({ title, color, ...rest }) => {
  const { t } = useTranslation();
  return (
    <IconButton title={t(title)} {...rest}>
      <RestoreIcon color={color} />
    </IconButton>
  );
});

RestoreButton.propTypes = {
  ...IconButton.propTypes,
};

RestoreButton.defaultProps = {
  ...IconButton.defaultProps,
  title: 'app:restore',
};

export default RestoreButton;
