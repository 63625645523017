import React from 'react';

import Modal from 'components/modals/Modal';

const ConfirmationModal = function (props) {
  return <Modal {...props} />;
};

ConfirmationModal.propTypes = {
  ...Modal.propTypes,
};

ConfirmationModal.defaultProps = {
  ...Modal.defaultProps,
  titleText: 'clients:subtractExpiredBonuses',
  bodyText: 'clients:subtractConfirmationQuestion',
  hideAfterOk: true,
};

export default ConfirmationModal;
