import paths from 'app/paths';
import TransactionView from 'components/transactions/TransactionView/TransactionView';
import TransactionList from 'components/transactions/TransactionList/TransactionList';

export default [
  {
    path: paths.transactionView.path,
    roles: paths.transactionView.roles,
    component: TransactionView,
  },
  {
    path: paths.transactions.path,
    roles: paths.transactions.roles,
    component: TransactionList,
  },
];
