import React, { memo, useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormikContext, Field } from 'formik';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import { randomString } from 'app/utils';
import useStyles from './DropdownField.styles';

const DropdownField = memo(
  ({ name, label, variant, fullWidth, margin, items, allowEmpty, ...rest }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { setFieldValue, initialValues, values } = useFormikContext();
    const formikValue = get(values, name);
    const [inputValue, setInputValue] = useState(get(initialValues, name));
    const [inputId] = useState(randomString());
    const [inputProps] = useState({ id: inputId });
    const [labelWidth, setLabelWidth] = useState(0);
    const inputLabel = useRef(null);

    useEffect(() => {
      if (!isEmpty(items) && !formikValue && !allowEmpty) {
        setFieldValue(name, items[0].value, false);
      }
    }, [items, formikValue, allowEmpty, setFieldValue, name]);

    useEffect(() => {
      setInputValue(formikValue);
    }, [formikValue]);

    useEffect(() => {
      setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    const onChange = useCallback(
      (event) => {
        const {
          target: { value },
        } = event;
        setInputValue(value);
        setFieldValue(name, value);
      },
      [name, setFieldValue],
    );

    const renderItem = useCallback(
      ({ value: optionValue, label: optionLabel }) => {
        return (
          <option key={optionValue} value={optionValue}>
            {t(optionLabel)}
          </option>
        );
      },
      [t],
    );

    return (
      <FormControl
        className={classes.formControl}
        variant={variant}
        margin={margin}
        fullWidth={fullWidth}
      >
        <InputLabel ref={inputLabel} htmlFor={inputId}>
          {t(label)}
        </InputLabel>
        <Select
          native
          value={inputValue}
          onChange={onChange}
          labelWidth={labelWidth}
          inputProps={inputProps}
          {...rest}
        >
          {allowEmpty && <option value="" />}
          {items.map(renderItem)}
        </Select>
        <Field name={name} type="hidden" />
      </FormControl>
    );
  },
);

DropdownField.propTypes = {
  ...Select.propTypes,
  name: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  allowEmpty: PropTypes.bool,
};

DropdownField.defaultProps = {
  ...Select.defaultProps,
  variant: 'outlined',
  margin: 'dense',
  items: [],
  allowEmpty: false,
  fullWidth: true,
};

export default DropdownField;
