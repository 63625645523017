import React, { memo, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import omitBy from 'lodash/omitBy';
import TableCell from '@material-ui/core/TableCell';
import MaterialTableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { sortTypes } from 'ducks/app';
import { ListContext } from './_constants';
import useStyles from './TableHead.styles';

const TableHead = memo(({ selectAll, onSelectAll }) => {
  const { rowConfig, sort, multiSort, updateSort } = useContext(ListContext);
  const { t } = useTranslation();
  const classes = useStyles();

  const toggleSort = useCallback(
    (name) => {
      switch (sort[name]) {
        case sortTypes.ASC:
          updateSort({ ...sort, [name]: sortTypes.DESC });
          break;
        case sortTypes.DESC:
          updateSort(omitBy(sort, (v, k) => k === name));
          break;
        default:
          if (multiSort) {
            updateSort({ ...sort, [name]: sortTypes.ASC });
          } else {
            updateSort({ [name]: sortTypes.ASC });
          }
      }
    },
    [sort, multiSort, updateSort],
  );

  const renderCell = useCallback(
    ({ name, label, sortable }) => {
      return (
        <TableCell key={name} className={classes.cell} align="center">
          {!sortable && t(label)}
          {sortable && (
            <TableSortLabel
              active={!!sort[name]}
              direction={sort[name] || sortTypes.ASC}
              onClick={sortable ? () => toggleSort(name) : null}
            >
              <span className={classes.cellLabel}>{t(label)}</span>
            </TableSortLabel>
          )}
        </TableCell>
      );
    },
    [sort, toggleSort, t, classes],
  );

  const renderSelectAllCell = useCallback(() => {
    if (selectAll === null) {
      return null;
    }
    return (
      <TableCell key="selectAll" className={classes.cell} align="center">
        <Checkbox checked={selectAll} onChange={(e) => onSelectAll(e.target.checked)} />
      </TableCell>
    );
  }, [selectAll, onSelectAll, classes]);

  return (
    <MaterialTableHead>
      <TableRow>
        {renderSelectAllCell()}
        {rowConfig.map(renderCell)}
      </TableRow>
    </MaterialTableHead>
  );
});

TableHead.propTypes = {
  selectAll: PropTypes.bool,
  onSelectAll: PropTypes.func,
};

TableHead.defaultProps = {
  selectAll: null,
  onSelectAll: null,
};

export default TableHead;
