import { fromJS } from 'immutable';

import * as actionTypes from './actionTypes';

const initialState = fromJS({
  authStatus: {
    isAuthenticated: null,
    isAuthorized: false,
  },
  user: {
    id: null,
    email: null,
    username: null,
    roles: [],
  },
});

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CHECK_AUTH_SUCCESS:
      return state.mergeDeep(
        fromJS({
          authStatus: { isAuthenticated: action.result },
        }),
      );

    case actionTypes.LOGIN_SUCCESS:
      return state.mergeDeep(
        fromJS({
          authStatus: { isAuthenticated: true },
        }),
      );

    case actionTypes.LOGOUT_SUCCESS:
      return state.merge(initialState);

    case actionTypes.FETCH_USER_SUCCESS:
      return state.mergeDeep(
        fromJS({
          authStatus: { isAuthorized: true },
          user: action.data,
        }),
      );

    default:
      return state;
  }
}
