import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useAction } from 'app/hooks';
import { actions as modalsActions, modalTypes } from 'ducks/modals';
import { actions as clientsActions } from 'ducks/clients';
import { Panel, SecondaryButton, Caption } from 'components/index';

const DeletePersonalData = memo((props) => {
  const {
    client: {
      id: clientId,
      user: { id: userId },
    },
  } = props;

  const removePersonalData = useAction(clientsActions.removePersonalData);
  const showModal = useAction(modalsActions.showModal);

  const handleOnClick = useCallback(() => {
    showModal(modalTypes.CONFIRMATION_MODAL, {
      onOk: () => removePersonalData(clientId),
    });
  }, [userId, clientId, removePersonalData, showModal]);

  return (
    <Panel title="auth:removePersonalData" defaultExpanded={false}>
      <Caption text="clients:removePersonalDataWarning" color="secondary" />
      <SecondaryButton label="auth:removePersonalData" onClick={handleOnClick} />
    </Panel>
  );
});

DeletePersonalData.propTypes = {
  client: PropTypes.object.isRequired,
};

export default DeletePersonalData;
