import { schema } from 'normalizr';

import { mapListRequest, mapListResponse } from 'app/mappers';
import { mapTransaction } from './entityMappers';

const transactionSchema = new schema.Entity('transactions');
const transactionListSchema = [transactionSchema];

export const mapFetchTransactionListRequest = (params) => {
  return mapListRequest(params, {
    scope: 'pos_full,store_full,card_full',
    filterMap: { transactionNumber: 'order_number' },
    sortMap: { number: 'order_number' },
  });
};

export const mapFetchTransactionListResponse = (data) => {
  return mapListResponse(data, {
    entityName: 'transactions',
    entityMapper: mapTransaction,
    schema: transactionListSchema,
  });
};

export const mapFetchTransactionResponse = (data) => {
  return mapTransaction(data);
};
