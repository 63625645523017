import React, { memo } from 'react';

import NumberField from './NumberField';

const QuantityField = memo((props) => <NumberField {...props} />);

QuantityField.propTypes = {
  ...NumberField.propTypes,
};

QuantityField.defaultProps = {
  ...NumberField.defaultProps,
  name: 'quantity',
  label: 'app:quantity',
};

export default QuantityField;
