import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import paths from 'app/paths';
import { generatePath } from 'app/utils';
import { hideModal } from 'ducks/modals/actions';
import { handleError, startLoading, finishLoading, showSuccess } from 'ducks/app/actions';

import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as services from './services';
import { selectNextPromotionListPage } from './selectors';

function* fetchCampaignList({ params }) {
  try {
    yield put(startLoading());
    const data = yield call(services.fetchCampaignList, params);
    yield put(actions.fetchCampaignListSuccess(data));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* fetchPromotionList({ params }) {
  try {
    yield put(startLoading());
    const data = yield call(services.fetchPromotionList, params);
    yield put(actions.fetchPromotionListSuccess(data));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* updateCouponBased(action) {
  const { params, onUpdated } = action;
  try {
    yield put(startLoading());
    yield call(services.updateCouponBased, params);
    yield put(showSuccess('app:updated'));
    yield call(onUpdated);
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* fetchPromotion(action) {
  const { id } = action;
  try {
    yield put(startLoading());
    const data = yield call(services.fetchPromotion, id);
    yield put(actions.fetchPromotionSuccess(data));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* deactivatePromotion(action) {
  const { id, campaignId } = action;
  try {
    yield put(startLoading());
    yield call(services.deactivatePromotion, id);
    yield put(showSuccess('app:deactivated'));
    const path = generatePath(paths.promotions.path, { campaignId });
    yield put(push(path));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* updatePromotion({ id, data }) {
  try {
    yield put(startLoading());
    yield call(services.updatePromotion, id, data);
    yield put(showSuccess('app:updated'));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* uploadSpecialCampaignFileWorker({ file }) {
  try {
    yield put(startLoading());
    yield call(services.uploadSpecialCampaignFile, file);
    yield put(showSuccess('app:uploaded'));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(hideModal());
    yield put(finishLoading());
  }
}

function* createCampaignWorker({ fields }) {
  try {
    yield put(startLoading());
    yield call(services.createCampaign, fields);
    yield put(showSuccess('app:created'));
    yield put(actions.fetchCampaignList({ page: 1 }));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
    yield put(hideModal());
  }
}

function* createPromotionWorker({ id, data }) {
  try {
    yield put(startLoading());
    yield call(services.createPromotion, data, id);
    yield put(showSuccess('app:created'));
    const page = yield select(selectNextPromotionListPage);
    const path = generatePath(paths.promotions.path, { campaignId: id }, { page });
    yield put(push(path));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* createSpecialPromotionWorker({ data }) {
  try {
    yield put(startLoading());
    yield call(services.createSpecialPromotion, data);
    yield put(showSuccess('app:created'));
    const page = yield select(selectNextPromotionListPage);
    const path = generatePath(paths.promotions.path, { campaignId: data.campaign }, { page });
    yield put(push(path));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* removeCampaign(action) {
  const {
    params: { id },
    onRemoved,
  } = action;
  try {
    yield put(startLoading());
    yield call(services.removeCampaign, id);
    yield put(actions.removeCampaignSuccess());
    yield put(showSuccess('app:removed'));
    yield call(onRemoved);
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* createTotalDependentPromotionWorker({ data }) {
  try {
    yield put(startLoading());
    yield call(services.createTotalDependentPromotion, data);
    yield put(showSuccess('app:created'));
    const page = yield select(selectNextPromotionListPage);
    const path = generatePath(paths.promotions.path, { campaignId: data.campaign }, { page });
    yield put(push(path));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

export default function* () {
  yield all([
    yield takeLatest(actionTypes.FETCH_CAMPAIGN_LIST, fetchCampaignList),
    yield takeLatest(actionTypes.FETCH_PROMOTION_LIST, fetchPromotionList),
    yield takeLatest(actionTypes.UPDATE_COUPON_BASED, updateCouponBased),
    yield takeLatest(actionTypes.FETCH_PROMOTION, fetchPromotion),
    yield takeLatest(actionTypes.DEACTIVATE_PROMOTION, deactivatePromotion),
    yield takeLatest(actionTypes.UPDATE_PROMOTION, updatePromotion),
    yield takeLatest(actionTypes.UPLOAD_SPECIAL_CAMPAIGN_FILE, uploadSpecialCampaignFileWorker),
    yield takeLatest(actionTypes.CREATE_CAMPAIGN, createCampaignWorker),
    yield takeLatest(actionTypes.CREATE_PROMOTION, createPromotionWorker),
    yield takeLatest(actionTypes.CREATE_SPECIAL_PROMOTION, createSpecialPromotionWorker),
    yield takeLatest(actionTypes.REMOVE_CAMPAIGN, removeCampaign),
    yield takeLatest(
      actionTypes.CREATE_TOTAL_DEPENDENT_PROMOTION,
      createTotalDependentPromotionWorker,
    ),
  ]);
}
