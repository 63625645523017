import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import RestorePageIcon from '@material-ui/icons/RestorePage';

const GenerateReportButton = memo(({ title, ...rest }) => {
  const { t } = useTranslation();
  return (
    <IconButton title={t('stores:generateReport')} {...rest}>
      <RestorePageIcon />
    </IconButton>
  );
});

GenerateReportButton.propTypes = {
  ...IconButton.propTypes,
};

GenerateReportButton.defaultProps = {
  ...IconButton.defaultProps,
  title: 'stores:generateReport',
};

export default GenerateReportButton;
