import React, { memo } from 'react';
import PropTypes from 'prop-types';
import values from 'lodash/values';
import isArray from 'lodash/isArray';

import { getRuleName } from 'ducks/campaigns/helpers';

const renderRule = (data, key = null) => {
  if (isArray(data)) {
    return data.map(renderRule);
  }

  return (
    <div key={data.id}>
      {getRuleName(data)}
      {key !== null && ` (${key + 1})`}
    </div>
  );
};

const RulesCell = memo((props) => {
  const {
    item: { rules },
  } = props;

  return values(rules).map((item) => renderRule(item));
});

RulesCell.propTypes = {
  item: PropTypes.object.isRequired,
};

export default RulesCell;
