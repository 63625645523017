import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/fr';

import { LANG } from 'app/config';
import en from './en';
import fr from './fr';

moment.locale(LANG);

i18n.on('languageChanged', (lng) => {
  moment.locale(lng);
});

i18n.use(initReactI18next).init({
  resources: { en, fr },
  lng: LANG,
  defaultNS: 'app',
  interpolation: { escapeValue: false },
});

export default i18n;
