import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

import { useAction } from 'app/hooks';
import { actions as modalsActions, modalTypes } from 'ducks/modals';
import { actions as storeActions } from 'ducks/stores';
import { Panel, SecondaryButton } from 'components';

const StoreStatus = function ({ id: storeId, store }) {
  const deactivateStore = useAction(storeActions.deactivateStore);
  const activateStore = useAction(storeActions.activateStore);
  const showModal = useAction(modalsActions.showModal);
  const { t } = useTranslation();

  const handleDeactivate = useCallback(() => {
    showModal(modalTypes.CONFIRMATION_MODAL, {
      onOk: () => deactivateStore(storeId),
    });
  }, [storeId, deactivateStore, showModal]);

  const handleActivate = useCallback(() => {
    showModal(modalTypes.CONFIRMATION_MODAL, {
      onOk: () => activateStore(storeId),
    });
  }, [storeId, activateStore, showModal]);

  return (
    <Panel title="stores:status" defaultExpanded={false}>
      <p>
        <Typography variant="subtitle1" component="span">
          {t('stores:currentStatus')}
          {': '}
          {t(store.enabled ? 'app:enabled' : 'app:disabled')}
        </Typography>
      </p>
      {store.enabled ? (
        <SecondaryButton label="app:deactivate" onClick={handleDeactivate} />
      ) : (
        <SecondaryButton label="app:activate" onClick={handleActivate} />
      )}
    </Panel>
  );
};

StoreStatus.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  store: PropTypes.object.isRequired,
};

export default React.memo(StoreStatus);
