import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useAction } from 'app/hooks';
import { actions as clientsActions } from 'ducks/clients';
import { actions as modalsActions } from 'ducks/modals';
import { Button } from 'components';

const ExportClientData = memo((props) => {
  const {
    client: { id },
  } = props;

  const exportPersonalData = useAction(clientsActions.exportPersonalData);
  const showModal = useAction(modalsActions.showModal);

  const onClick = useCallback(() => {
    exportPersonalData(id);
  }, [id, exportPersonalData, showModal]);

  return (
    <Button
      variant="outlined"
      size="small"
      label="auth:exportPersonalData"
      onClick={onClick}
      marginLeft
      marginRight
    />
  );
});

ExportClientData.propTypes = {
  client: PropTypes.object.isRequired,
};

export default ExportClientData;
