import React, { memo, useCallback } from 'react';

import Modal from 'components/modals/Modal';
import { AutoSizer, Column, Table } from 'react-virtualized';
import TableCell from '@material-ui/core/TableCell';
import { useTranslation } from 'react-i18next';
import useStyles from '../AdminGroups/AdminGroupTable.styles';

const AddAnotherOneAdminForStoreModal = memo((props) => {
  const { admins } = props.group;
  const { t } = useTranslation();
  const classes = useStyles();

  const headerRenderer = useCallback(
    ({ label }) => {
      return (
        <TableCell component="div" variant="head">
          {t(label)}
        </TableCell>
      );
    },
    [t],
  );

  const textCellRenderer = useCallback(
    ({ cellData }) => {
      return (
        <TableCell component="div" variant="body">
          {cellData || <em>{t('app:none')}</em>}
        </TableCell>
      );
    },
    [t],
  );

  const rowGetter = useCallback(
    ({ index }) => {
      return admins[index];
    },
    [admins],
  );

  return (
    <Modal {...props}>
      <div className={classes.root}>
        <AutoSizer>
          {({ height, width }) => {
            const multiplier = Math.floor(width / 12);
            return (
              <Table
                height={height}
                width={width}
                headerHeight={40}
                rowHeight={40}
                rowClassName={classes.row}
                gridClassName={classes.grid}
                rowCount={admins.length}
                rowGetter={rowGetter}
              >
                <Column
                  key="email"
                  dataKey="email"
                  label="auth:email"
                  headerRenderer={headerRenderer}
                  cellRenderer={textCellRenderer}
                  width={multiplier * 10}
                />
              </Table>
            );
          }}
        </AutoSizer>
      </div>
    </Modal>
  );
});

AddAnotherOneAdminForStoreModal.propTypes = {
  ...Modal.propTypes,
};

AddAnotherOneAdminForStoreModal.defaultProps = {
  ...Modal.defaultProps,
  titleText: 'stores:groupHaveAdmin',
  hideAfterOk: true,
};

export default AddAnotherOneAdminForStoreModal;
