import React, { memo } from 'react';
import PropTypes from 'prop-types';

import paths from 'app/paths';
import { ViewLinkButton } from 'components';

const ProductActionsCell = memo((props) => {
  const {
    item: { id },
  } = props;
  return <ViewLinkButton to={paths.productsView.path} params={{ id }} />;
});

ProductActionsCell.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

export default ProductActionsCell;
