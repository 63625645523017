import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import BarChartIcon from '@material-ui/icons/BarChart';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RestorePageIcon from '@material-ui/icons/RestorePage';

import paths from 'app/paths';
import { useAction, usePushAction } from 'app/hooks';
import { joinNotEmpty } from 'app/utils';
import { actions as modalsActions, modalTypes } from 'ducks/modals';
import { selectors as authSelectors } from 'ducks/auth';
import { actions as storesActions, selectors as storesSelectors } from 'ducks/stores';
import { List, Breadcrumbs } from 'components';
import { REPORT_TYPES } from 'components/stores/modals/GenerateReportModal';
import StoreFilter from './StoreFilter';
import StoreActionsCell from './StoreActionsCell';

const StoreList = memo(() => {
  const { t } = useTranslation();
  const isAppAdmin = useSelector(authSelectors.isAppAdmin);
  const isSuperAdmin = useSelector(authSelectors.isSuperAdmin);
  const { items, meta } = useSelector(storesSelectors.storeList);
  const fetchStoreList = useAction(storesActions.fetchStoreList);
  const showModal = useAction(modalsActions.showModal);
  const push = usePushAction();

  const rowConfig = useMemo(
    () => [
      {
        name: 'id',
        label: 'app:id',
        source: 'id',
      },
      {
        name: 'number',
        label: 'app:number',
        source: 'number',
        sortable: true,
      },
      {
        name: 'name',
        label: 'app:name',
        source: 'name',
        sortable: true,
      },
      {
        name: 'priceGroup',
        label: 'stores:priceGroup',
        source: 'priceGroup',
        sortable: true,
      },
      {
        name: 'contact',
        label: 'app:contact',
        render: ({ contact }) => {
          return contact && joinNotEmpty([contact.firstName, contact.lastName]);
        },
      },
      {
        name: 'phoneNumber',
        label: 'app:phoneNumber',
        source: 'phoneNumber',
      },
      {
        name: 'address',
        label: 'app:address',
        source: 'address.addressString',
      },
      {
        name: 'enabled',
        label: 'app:enabled',
        source: 'enabled',
        render: ({ enabled }) => t(enabled ? 'app:yes' : 'app:no'),
      },
      ...(isSuperAdmin
        ? [
            {
              name: 'reportingList',
              label: 'stores:reportingList',
              render: ({ reportingEnabled }) => t(reportingEnabled ? 'app:yes' : 'app:no'),
            },
          ]
        : []),
      {
        name: 'actions',
        label: 'app:actions',
        component: StoreActionsCell,
      },
    ],
    [t, isSuperAdmin],
  );

  const toolbarActions = useMemo(() => {
    return [
      {
        name: 'onlinePercentage',
        label: 'stores:exportOnlinePercentage',
        icon: BarChartIcon,
        show: isAppAdmin,
        confirm: false,
        callback: () => showModal(modalTypes.ONLINE_PERCENTAGE_MODAL),
      },
      {
        name: 'generateReport',
        label: 'stores:generateReport',
        icon: RestorePageIcon,
        show: isAppAdmin,
        confirm: false,
        callback: () =>
          showModal(modalTypes.GENERATE_REPORT_MODAL, { reportType: REPORT_TYPES.MONTHLY }),
      },
      {
        name: 'add',
        label: 'app:add',
        icon: AddCircleIcon,
        show: isSuperAdmin,
        confirm: false,
        callback: () => push(paths.storesAdd.path),
      },
    ];
  }, [push, showModal, isAppAdmin, isSuperAdmin]);

  return (
    <>
      <Breadcrumbs />
      <List
        data={items}
        meta={meta}
        onFetchData={fetchStoreList}
        filterComponent={StoreFilter}
        rowConfig={rowConfig}
        toolbarActions={toolbarActions}
      />
    </>
  );
});

export default StoreList;
