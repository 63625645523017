import axios from 'axios';

import * as mappers from './serviceMappers';

export function fetchCampaignList(params) {
  return axios
    .get('campaigns/search', { params: mappers.mapFetchCampaignListRequest(params) })
    .then((response) => mappers.mapFetchCampaignListResponse(response.data));
}

export function fetchPromotionList(params) {
  return axios
    .get('promotions/search', { params: mappers.mapFetchPromotionListRequest(params) })
    .then((response) => mappers.mapFetchPromotionListResponse(response.data));
}

export function updateCouponBased(params) {
  const { id, target, value } = params;
  const method = value ? axios.put : axios.delete;
  return method(`${target}/${id}/coupon-based`).catch(mappers.mapUpdateCouponBasedError);
}

export function fetchPromotion(id) {
  const scope = 'promotion_full,product_full';
  return axios
    .get(`promotions/${id}`, { params: { scope } })
    .then((response) => mappers.mapFetchPromotionResponse(response.data));
}

export function deactivatePromotion(id) {
  return axios.delete(`promotions/${id}`).catch(mappers.mapDeactivatePromotionError);
}

export function updatePromotion(id, data) {
  return axios
    .patch(`promotions/${id}`, mappers.mapUpdatePromotionRequest(data))
    .catch(mappers.mapUpdatePromotionError);
}

export function uploadSpecialCampaignFile(file) {
  const formData = new FormData();
  formData.append('file', file);

  return axios
    .post('campaigns/special', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    .catch(mappers.mapUploadCampaignError);
}

export function createCampaign(fields) {
  return axios.post('campaigns/', fields);
}

export function createPromotion(data, id) {
  return axios.post('promotions/', mappers.mapCreatePromotionRequest(data, id));
}

export function createSpecialPromotion(data) {
  return axios
    .post('promotions/special-cumulative-pack', mappers.mapSpecialPromotionRequest(data))
    .catch(mappers.mapCreateSpecialPromotionError);
}

export function removeCampaign(id) {
  return axios.delete(`campaigns/${id}`).catch(mappers.mapRemoveCampaignError);
}

export function createTotalDependentPromotion(data) {
  return axios.post('promotions/', mappers.mapCreateTotalDependentPromotionRequest(data));
}
