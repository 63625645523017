import * as actionTypes from './actionTypes';

export const checkAuth = () => ({
  type: actionTypes.CHECK_AUTH,
});

export const checkAuthSuccess = (result) => ({
  type: actionTypes.CHECK_AUTH_SUCCESS,
  result,
});

export const login = (data) => ({
  type: actionTypes.LOGIN,
  data,
});

export const loginSuccess = (data) => ({
  type: actionTypes.LOGIN_SUCCESS,
  data,
});

export const logout = () => ({
  type: actionTypes.LOGOUT,
});

export const logoutSuccess = () => ({
  type: actionTypes.LOGOUT_SUCCESS,
});

export const fetchUser = () => ({
  type: actionTypes.FETCH_USER,
});

export const fetchUserSuccess = (data) => ({
  type: actionTypes.FETCH_USER_SUCCESS,
  data,
});

export const changePassword = (data) => ({
  type: actionTypes.CHANGE_PASSWORD,
  data,
});

export const resetPassword = (data) => ({
  type: actionTypes.RESET_PASSWORD,
  data,
});

export const confirmReset = (token, data) => ({
  type: actionTypes.RESET_PASSWORD_CONFIRM,
  token,
  data,
});
