import React, { memo } from 'react';

import { Filter, PligField } from 'components';

const PromotionFilter = memo(() => {
  return (
    <Filter
      initialValues={{
        plig: '',
      }}
      mainField={<PligField />}
    >
      <PligField />
    </Filter>
  );
});

export default PromotionFilter;
