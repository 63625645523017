import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { withFormik, Form } from 'formik';

import { useAction } from 'app/hooks';
import {
  dateValidator,
  streetNumberAddressValidator,
  streetAddressValidator,
  cityAddressValidator,
  countryAddressValidator,
  phoneNumberValidator,
  postalCodeValidator,
} from 'app/validators';
import { actions as clientsActions } from 'ducks/clients';
import {
  GenderDropdownField,
  FirstNameField,
  LastNameField,
  BirthdayField,
  PhoneNumberField,
  HouseNumberField,
  StreetField,
  PostalCodeField,
  CityField,
  CountryField,
  TextHeader,
  SaveButton,
} from 'components';

const ClientProfileForm = memo(() => {
  return (
    <Form>
      <TextHeader text="app:information" />
      <GenderDropdownField />
      <FirstNameField />
      <LastNameField />
      <BirthdayField />
      <PhoneNumberField />
      <TextHeader text="app:address" />
      <HouseNumberField />
      <StreetField />
      <PostalCodeField />
      <CityField />
      <CountryField />
      <SaveButton />
    </Form>
  );
});

const validationSchema = yup.object().shape({
  firstName: yup.string().required('app:required'),
  lastName: yup.string().required('app:required'),
  birthday: dateValidator().required('app:required'),
  phoneNumber: phoneNumberValidator(),
  houseNumber: streetNumberAddressValidator(),
  street: streetAddressValidator(),
  postalCode: postalCodeValidator(),
  city: cityAddressValidator(),
  country: countryAddressValidator(),
});

const EnhancedClientProfileForm = withFormik({
  displayName: 'ClientProfileForm',
  validationSchema,
  enableReinitialize: true,
  validateOnBlur: false,
  mapPropsToValues: (props) => {
    const { client } = props;
    const {
      gender: { id: gender = '' },
      firstName = '',
      lastName = '',
      birthday = '',
      phoneNumber = '',
      address: { houseNumber = '', street = '', postalCode = '', city = '', country = '' },
    } = client;

    return {
      gender,
      birthday,
      firstName,
      lastName,
      phoneNumber,
      houseNumber,
      street,
      postalCode,
      city,
      country,
    };
  },
  handleSubmit: (values, { props: { onSubmit } }) => {
    onSubmit(values);
  },
})(ClientProfileForm);

EnhancedClientProfileForm.propTypes = {
  client: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

const ClientProfileEdit = memo((props) => {
  const { client } = props;
  const updateClientProfile = useAction(clientsActions.updateClientProfile);

  const onSubmit = useCallback(
    (values) => {
      updateClientProfile(client.id, values);
    },
    [client.id, updateClientProfile],
  );

  return <EnhancedClientProfileForm onSubmit={onSubmit} client={client} />;
});

ClientProfileEdit.propTypes = {
  client: PropTypes.object.isRequired,
};

export default ClientProfileEdit;
