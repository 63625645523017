import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';

import { formatEuro } from 'app/formatters';
import NeutralAdjustment from './NeutralAdjustment';

const TransactionItems = memo((props) => {
  const { items, transactionTotal } = props;
  const { t } = useTranslation();

  const renderItem = useCallback((item) => {
    return (
      <TableRow key={item.id}>
        <TableCell>{item.plig.value}</TableCell>
        <TableCell>{item.label}</TableCell>
        <TableCell>{item.quantity}</TableCell>
        <TableCell>{formatEuro(item.unitPrice)}</TableCell>
        <TableCell>
          {item.notNeutralAdjustments
            .map((adjustment) => {
              return formatEuro(adjustment.amount);
            })
            .join(' ')}
          {item.neutralAdjustments.map((adjustment) => (
            <NeutralAdjustment key={adjustment.id} adjustment={adjustment} />
          ))}
        </TableCell>
        <TableCell>{formatEuro(item.total)}</TableCell>
      </TableRow>
    );
  }, []);

  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t('products:plig')}</TableCell>
            <TableCell>{t('app:label')}</TableCell>
            <TableCell>{t('app:quantity')}</TableCell>
            <TableCell>{t('transactions:unitPrice')}</TableCell>
            <TableCell>{t('campaigns:discounts')}</TableCell>
            <TableCell>{t('app:amount')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{items.map(renderItem)}</TableBody>
      </Table>
      <Box align="right" m={1}>
        {t('app:total')}: {formatEuro(transactionTotal)}
      </Box>
    </>
  );
});

TransactionItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  transactionTotal: PropTypes.number,
};

TransactionItems.defaultProps = {
  items: [],
  transactionTotal: undefined,
};

export default TransactionItems;
