import { call, put, all, select, takeLatest, takeEvery } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import find from 'lodash/find';

import {
  showSuccess,
  handleError,
  startLoading,
  finishLoading,
  exportPreparedCsv,
} from 'ducks/app/actions';
import { hideModal } from 'ducks/modals/actions';
import { selectors as storesSelectors } from 'ducks/stores';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as services from './services';

function* fetchClientList(action) {
  const { params } = action;
  try {
    yield put(startLoading());
    const data = yield call(services.fetchClientList, params);
    yield put(actions.fetchClientListSuccess(data));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* fetchBestClients(action) {
  const { params, header } = action;
  const { storeId } = params;
  try {
    yield put(startLoading());
    const items = yield call(services.fetchBestClients, params);
    const data = `${header}\n${items}`;
    const storeOptions = yield select(storesSelectors.storeOptions);
    const { number } = find(storeOptions, ({ id }) => id === storeId);
    yield put(exportPreparedCsv(`best_clients_${number}`, data));
    yield put(hideModal());
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* fetchClient(action) {
  const { id } = action;
  try {
    yield put(startLoading());
    const data = yield call(services.fetchClient, id);
    yield put(actions.fetchClientSuccess(data));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* resetClientPassword(action) {
  const { userId } = action;
  try {
    yield put(startLoading());
    yield call(services.resetClientPassword, userId);
    yield put(showSuccess('auth:passwordReset'));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* changeClientEmail(action) {
  const { userId, data } = action;
  try {
    yield put(startLoading());
    yield call(services.changeClientEmail, userId, data);
    yield put(showSuccess('clients:clientEmailChanged'));
    yield put(hideModal());
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* changeClientEmailWithoutUser(action) {
  const { userId, data } = action;
  try {
    yield put(startLoading());
    yield call(services.changeClientEmailWithoutUser, userId, data);
    const userData = yield call(services.fetchClient, userId);
    yield put(actions.fetchClientSuccess(userData));
    yield put(hideModal());
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* updateClientProfile(action) {
  const { clientId, data } = action;
  try {
    yield put(startLoading());
    yield call(services.updateClientProfile, clientId, data);
    yield put(showSuccess('app:updated'));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* activateClient(action) {
  const { userId, clientId } = action;
  try {
    yield put(startLoading());
    yield call(services.activateClient, userId);
    yield put(showSuccess('app:activated'));
    yield put(actions.fetchClient(clientId));
  } catch (e) {
    yield put(handleError(e));
  }
}

function* deactivateClient(action) {
  const { userId, clientId } = action;
  try {
    yield put(startLoading());
    yield call(services.deactivateClient, userId);
    yield put(showSuccess('app:deactivated'));
    yield put(actions.fetchClient(clientId));
  } catch (e) {
    yield put(handleError(e));
  }
}

function* activateCard(action) {
  const { cardId, clientId } = action;
  try {
    yield put(startLoading());
    yield call(services.activateCard, cardId);
    yield put(showSuccess('app:activated'));
    yield put(actions.fetchClient(clientId));
  } catch (e) {
    yield put(handleError(e));
  }
}

function* deactivateCard(action) {
  const { cardId, clientId } = action;
  try {
    yield put(startLoading());
    yield call(services.deactivateCard, cardId);
    yield put(showSuccess('app:deactivated'));
    yield put(actions.fetchClient(clientId));
  } catch (e) {
    yield put(handleError(e));
  }
}

function* fetchBalanceHistory(action) {
  const { accountId, page } = action;
  try {
    yield put(startLoading());
    const data = yield call(services.fetchBalanceHistory, accountId, page);
    yield put(actions.fetchBalanceHistorySuccess(accountId, data));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* updateBalance(action) {
  const { accountId, clientId, data } = action;
  try {
    yield put(startLoading());
    yield call(services.updateBalance, accountId, data);
    yield put(showSuccess('app:updated'));
    yield put(hideModal());
    yield put(actions.fetchClient(clientId));
    yield put(actions.fetchBalanceHistory(accountId, 1));
  } catch (e) {
    yield put(handleError(e));
  }
}

function* exportPersonalData(action) {
  const { clientId } = action;
  try {
    yield put(startLoading());
    const { data } = yield call(services.exportPersonalData, clientId);
    const blob = new Blob([new Uint8Array(data)], { type: 'application/zip' });
    yield call(saveAs, blob, `personal-data-${clientId}.zip`);
    yield put(actions.exportPersonalDataSuccess());
  } catch (e) {
    yield put(actions.exportPersonalDataFailure(e));
    yield put(handleError(e));
  }
  yield put(finishLoading());
}

function* removePersonalData(action) {
  const { clientId } = action;
  try {
    yield put(startLoading());
    yield call(services.removePersonalData, clientId);
    yield call(() => window.location.reload());
    yield put(actions.removePersonalDataSuccess());
  } catch (e) {
    yield put(actions.removePersonalDataFailure(e));
    yield put(handleError(e));
  }
  yield put(finishLoading());
}

function* uploadCardsFile({ file, storeNumber }) {
  try {
    yield put(startLoading());
    yield call(services.uploadCardsFile, file, storeNumber);
    yield put(showSuccess('clients:createCardsRequestSent'));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(hideModal());
    yield put(finishLoading());
  }
}

function* getExpiredBalance(action) {
  const { accountId } = action;
  try {
    yield put(startLoading());
    const data = yield call(services.getExpiredBalance, accountId);
    yield put(actions.getExpiredBalanceSuccess(accountId, data));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* subtractExpiredBonuses() {
  try {
    yield put(startLoading());
    yield call(services.subtractExpiredBonuses);
    yield put(showSuccess('clients:subtractExpiredBonusesRequestSent'));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(hideModal());
    yield put(finishLoading());
  }
}

export default function* () {
  yield all([
    yield takeLatest(actionTypes.FETCH_CLIENT_LIST, fetchClientList),
    yield takeLatest(actionTypes.FETCH_BEST_CLIENTS, fetchBestClients),
    yield takeLatest(actionTypes.FETCH_CLIENT, fetchClient),
    yield takeLatest(actionTypes.RESET_CLIENT_PASSWORD, resetClientPassword),
    yield takeLatest(actionTypes.CHANGE_CLIENT_EMAIL, changeClientEmail),
    yield takeLatest(actionTypes.CHANGE_CLIENT_EMAIL_WITHOUT_USER, changeClientEmailWithoutUser),
    yield takeLatest(actionTypes.UPDATE_CLIENT_PROFILE, updateClientProfile),
    yield takeLatest(actionTypes.ACTIVATE_CLIENT, activateClient),
    yield takeLatest(actionTypes.DEACTIVATE_CLIENT, deactivateClient),
    yield takeLatest(actionTypes.ACTIVATE_CARD, activateCard),
    yield takeLatest(actionTypes.DEACTIVATE_CARD, deactivateCard),
    yield takeEvery(actionTypes.FETCH_BALANCE_HISTORY, fetchBalanceHistory),
    yield takeEvery(actionTypes.UPDATE_BALANCE, updateBalance),
    yield takeLatest(actionTypes.EXPORT_PERSONAL_DATA, exportPersonalData),
    yield takeLatest(actionTypes.REMOVE_PERSONAL_DATA, removePersonalData),
    yield takeLatest(actionTypes.UPLOAD_CARDS_FILE, uploadCardsFile),
    yield takeLatest(actionTypes.GET_EXPIRED_BALANCE, getExpiredBalance),
    yield takeLatest(actionTypes.SUBTRACT_EXPIRED_BONUSES, subtractExpiredBonuses),
  ]);
}
