import { fromJS, Map } from 'immutable';

import * as actionTypes from './actionTypes';

const initialState = fromJS({
  clientList: {
    order: [],
    items: {},
    meta: { page: 1, limit: 10, total: 0 },
  },
  viewingClient: {
    isDeleted: false,
    address: {},
    gender: {},
    user: {},
    cards: [],
  },
  balanceHistory: {},
  expiredBalance: {},
});

export default (state = initialState, { type, data, accountId }) => {
  switch (type) {
    case actionTypes.FETCH_CLIENT_LIST_SUCCESS:
      return state.merge(
        fromJS({
          clientList: data,
        }),
      );

    case actionTypes.FETCH_CLIENT_SUCCESS:
      return state.merge(
        fromJS({
          viewingClient: data,
        }),
      );

    case actionTypes.FETCH_BALANCE_HISTORY_SUCCESS:
      const items =
        data.meta.page === 1
          ? data.items
          : state
              .getIn(['balanceHistory', `${data.accountId}`, 'items'])
              .toJS()
              .concat(data.items);
      const balanceHistory = state.get('balanceHistory').merge(
        fromJS({
          [data.accountId]: { meta: data.meta, items },
        }),
      );
      return state.merge(Map({ balanceHistory }));

    case actionTypes.GET_EXPIRED_BALANCE_SUCCESS:
      const expiredBalance = state.get('expiredBalance').merge(
        fromJS({
          [accountId]: { amount: data.expiredBalance },
        }),
      );
      return state.merge(Map({ expiredBalance }));

    default:
      return state;
  }
};
