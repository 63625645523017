import * as yup from 'yup';
import { parse } from 'date-fns';

export const passwordValidator = () => {
  return yup.string().test('password', 'auth:passwordRequirements', (value) => {
    if (!value) {
      return true;
    }
    return value.match(/[A-Z]/) && value.match(/[a-z]/) && value.match(/\d/) && value.length >= 8;
  });
};

export const dateValidator = () => {
  return yup.date().typeError('app:invalidDate');
};

export const endDateValidator = (refName = 'startDate') => {
  return dateValidator().min(yup.ref(refName), 'app:notLessThanStartDate');
};

export const monthValidator = () => {
  return yup
    .date()
    .transform((value, originalValue) => {
      return parse(originalValue, 'MM/yyyy', new Date());
    })
    .typeError('app:invalidDate');
};

export const endMonthValidator = (refName = 'startMonth') => {
  return monthValidator().min(yup.ref(refName), 'app:notLessThanStartDate');
};

export const phoneNumberValidator = () => {
  return yup.string().matches(/^\+?\d{10,}$/, 'app:invalidPhoneNumber');
};

export const postalCodeValidator = () => {
  return yup.string().matches(/^\d{5}$/, 'app:invalidPostalCode');
};

export const positiveNumberValidator = () => {
  return yup
    .number()
    .test('maxDecimalPlaces', 'app:invalidNumber', (number) =>
      number ? /^\d+([.,]\d{1,2})?$/.test(number) : true,
    )
    .typeError('app:invalidNumber')
    .min(0, 'app:invalidNumber');
};

export const integerNumberValidator = () => {
  return yup
    .number()
    .typeError('app:invalidNumber')
    .min(0, 'app:invalidNumber')
    .integer('app:invalidNumber');
};

export const percentValidator = () => {
  return yup
    .number()
    .typeError('app:invalidNumber')
    .min(0, 'app:invalidNumber')
    .max(100, 'app:invalidNumber')
    .integer('app:invalidNumber');
};

export const idValidator = () => {
  return yup.number().typeError('app:invalidId').min(1, 'app:invalidId');
};

export const streetNumberAddressValidator = () => {
  return yup.string().max(20, 'address:number:maxLength');
};

export const streetAddressValidator = () => {
  return yup.string().max(255, 'address:street:maxLength');
};

export const cityAddressValidator = () => {
  return yup.string().max(50, 'address:city:maxLength');
};

export const countryAddressValidator = () => {
  return yup.string().max(50, 'address:country:maxLength');
};
