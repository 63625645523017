import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Panel, PropertyRow } from 'components';
import DiscountTypeCell from '../PromotionList/DiscountTypeCell';
import DiscountCell from '../PromotionList/DiscountCell';
import DiscountInfoCell from '../PromotionList/DicountInfoCell';
import TotalDependentAccruingRuleTable from '../PromotionList/TotalDependentAccruingRuleTable';
import { promotionActionTypes } from '../../../ducks/campaigns/enums';

const PromotionDetailsAction = memo((props) => {
  const { promotion } = props;

  const isTotalDependentPromotion = promotion.actions[promotionActionTypes.DYNAMIC_DISCOUNT];

  return (
    <Panel title="app:action" defaultExpanded>
      <PropertyRow label="campaigns:discountType">
        <DiscountTypeCell item={promotion} />
      </PropertyRow>
      {!isTotalDependentPromotion && (
        <PropertyRow label="campaigns:discount">
          <DiscountCell item={promotion} />
        </PropertyRow>
      )}
      {!isTotalDependentPromotion && (
        <PropertyRow label="campaigns:limit">
          <DiscountInfoCell property="limit" item={promotion} />
        </PropertyRow>
      )}
      {!isTotalDependentPromotion && (
        <PropertyRow label="campaigns:cycle">
          <DiscountInfoCell property="cycle" item={promotion} />
        </PropertyRow>
      )}
      {isTotalDependentPromotion && (
        <PropertyRow
          label="campaigns:totalDependent:accruingRules"
          contentColumnSize={5}
          labelColumnSize={2}
        >
          <TotalDependentAccruingRuleTable item={promotion} />
        </PropertyRow>
      )}
    </Panel>
  );
});

PromotionDetailsAction.propTypes = {
  promotion: PropTypes.object.isRequired,
};

export default PromotionDetailsAction;
