import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import MaterialTextField from '@material-ui/core/TextField';
import get from 'lodash/get';

const gerErrorConfig = (error) => {
  return typeof error === 'object' ? [error.text, error.data] : [error];
};

const TextFieldComponent = memo(({ className, field, form: { errors }, label, ...rest }) => {
  const { t } = useTranslation();
  const error = get(errors, field.name);

  return (
    <MaterialTextField
      label={t(label)}
      helperText={t(...gerErrorConfig(error))}
      error={!!error}
      {...field}
      {...rest}
    />
  );
});

TextFieldComponent.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  ...MaterialTextField.propTypes,
};

TextFieldComponent.defaultProps = {
  ...MaterialTextField.defaultProps,
  margin: 'dense',
  variant: 'outlined',
};

const TextField = memo((props) => {
  return <Field component={TextFieldComponent} {...props} />;
});

TextField.propTypes = {
  ...Field.propTypes,
  ...MaterialTextField.propTypes,
};

TextField.defaultProps = {
  ...Field.defaultProps,
  ...MaterialTextField.defaultProps,
  fullWidth: true,
};

export default TextField;
