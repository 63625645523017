import React, { memo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormikContext, Field } from 'formik';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

const MonthField = memo(({ name, label, ...rest }) => {
  const { t, i18n } = useTranslation();
  const { setFieldValue, initialValues, errors } = useFormikContext();
  const initialValue = initialValues[name];
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    setSelectedDate(initialValue ? new Date(initialValue) : null);
  }, [initialValue]);

  const onChange = useCallback(
    (date) => {
      setSelectedDate(date);
      if (!date || date.toString() === 'Invalid Date') {
        setFieldValue(name, '');
      } else {
        setFieldValue(name, moment(date).format('MM/yyyy'));
      }
    },
    [name, setFieldValue],
  );

  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils} locale={i18n.language}>
        <DatePicker
          label={t(label)}
          value={selectedDate}
          selected={selectedDate}
          onChange={onChange}
          error={!!errors[name]}
          helperText={t(errors[name])}
          {...rest}
        />
      </MuiPickersUtilsProvider>
      <Field name={name} type="hidden" />
    </>
  );
});

MonthField.propTypes = {
  ...DatePicker.propTypes,
  name: PropTypes.string.isRequired,
};

MonthField.defaultProps = {
  ...DatePicker.defaultProps,
  variant: 'inline',
  inputVariant: 'outlined',
  format: 'MM/yyyy',
  margin: 'dense',
  autoOk: true,
  fullWidth: true,
  views: ['year', 'month'],
};

export default MonthField;
