import React, { memo } from 'react';

import TextField from './TextField';

const PhoneNumberField = memo((props) => <TextField {...props} />);

PhoneNumberField.propTypes = {
  ...TextField.propTypes,
};

PhoneNumberField.defaultProps = {
  ...TextField.defaultProps,
  name: 'phoneNumber',
  label: 'app:phoneNumber',
};

export default PhoneNumberField;
