import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import paths from 'app/paths';
import { selectors as authSelectors } from 'ducks/auth';
import { actions as campaignsActions } from 'ducks/campaigns';
import { ViewLinkButton, LinkButton, ManipulationRowAction } from 'components';
import AddCouponButton from './AddCouponButton';
import CouponUsingButton from './CouponUsingButton';

const PromotionActionsCell = memo((props) => {
  const {
    item: { id, isCouponBased },
  } = props;
  const {
    params: { campaignId },
  } = useRouteMatch();
  const isAppAdmin = useSelector(authSelectors.isAppAdmin);

  return (
    <>
      <ViewLinkButton to={paths.promotionView.path} params={{ campaignId, promotionId: id }} />
      {isAppAdmin && (
        <ManipulationRowAction
          component={CouponUsingButton}
          componentProps={{ enabled: isCouponBased }}
          action={campaignsActions.updateCouponBased}
          params={{ id, target: 'promotions', value: !isCouponBased }}
        />
      )}
      {isAppAdmin && isCouponBased && (
        <LinkButton
          component={AddCouponButton}
          to={paths.couponsAdd.path}
          queryParams={{ promotionId: id }}
        />
      )}
    </>
  );
});

PromotionActionsCell.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    isCouponBased: PropTypes.bool,
  }).isRequired,
};

export default PromotionActionsCell;
