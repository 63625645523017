import React, { memo } from 'react';

import { Filter, CardNumberField, EmailField, NameField } from 'components';

const ClientFilter = memo(() => {
  return (
    <Filter
      initialValues={{
        cardNumber: '',
        email: '',
        name: '',
      }}
      mainField={<CardNumberField />}
    >
      <CardNumberField />
      <EmailField />
      <NameField />
    </Filter>
  );
});

export default ClientFilter;
