import React, { useEffect, useMemo } from 'react';

import { AmountField, Button, Caption, DropdownField, Panel } from 'components/shared';
import {
  cartHasItemsMethods,
  promotionActionTypes,
  promotionRuleTypes,
} from 'ducks/campaigns/enums';

import { useFormikContext } from 'formik';
import { getRuleName } from 'ducks/campaigns/helpers';
import PromotionEditPligs from '../PromotionEditPligs';
import { usePligsListActions } from './usePligsListActions';

const cartHasItemsMethodOptions = [
  { value: cartHasItemsMethods.ONE_OF, label: 'campaigns:cartHasItems:one_of' },
  { value: cartHasItemsMethods.ALL, label: 'campaigns:cartHasItems:all' },
  { value: cartHasItemsMethods.COUNT, label: 'campaigns:cartHasItems:count' },
];

const checkCountMethod = (configuration = {}) => {
  return configuration.method === cartHasItemsMethods.COUNT;
};

const CartHasItemsRule = function () {
  const { config, multiplePligs, hideSecondList, showSecondList } = usePligsListActions();

  const isCountMethod = checkCountMethod(config);
  const ruleInfo = useMemo(
    () => getRuleName({ type: promotionRuleTypes.CART_HAS_ITEMS, configuration: config }),
    [config],
  );

  useEffect(() => {
    !isCountMethod && hideSecondList();
  }, [isCountMethod]);

  if (!config) {
    return null;
  }

  const {
    values: { actions },
  } = useFormikContext();

  return (
    <Panel title="campaigns:rules" defaultExpanded>
      {!actions[promotionActionTypes.DYNAMIC_DISCOUNT] && (
        <DropdownField
          name="cartHasItemsConfig.method"
          label="campaigns:cartHasItems:method"
          items={cartHasItemsMethodOptions}
        />
      )}
      {!actions[promotionActionTypes.DYNAMIC_DISCOUNT] && (
        <AmountField name="cartHasItemsConfig.threshold" label="campaigns:cartHasItems:threshold" />
      )}
      <Caption strong component="span" text={ruleInfo} />
      {isCountMethod && !multiplePligs && (
        <Button variant="outlined" onClick={showSecondList} label="campaigns:addSecondPligList" />
      )}
      <PromotionEditPligs />
    </Panel>
  );
};

export default React.memo(CartHasItemsRule);
