import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#3f474c',
    flexGrow: 1,
  },
  title: {
    position: 'absolute',
    left: 0,
    right: 0,
    pointerEvents: 'none',
    textAlign: 'center',
  },
  username: {
    marginRight: theme.spacing(1),
    textAlign: 'right',
    flexGrow: 1,
  },
  button: {
    marginRight: theme.spacing(1),
    color: '#fff',
  },
}));

export default useStyles;
