import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import paths from 'app/paths';
import Modal from 'components/modals/Modal';
import { generatePath } from 'app/utils';
import { usePushAction } from 'app/hooks';
import { promotionTemplateTypes } from 'ducks/campaigns/enums';

const promotionCreationTypes = [
  {
    label: 'campaigns:createPromo:basic1',
    value: promotionTemplateTypes.BASIC1,
  },
  {
    label: 'campaigns:createPromo:basic2',
    value: promotionTemplateTypes.BASIC2,
  },
  {
    label: 'campaigns:createPromo:special1',
    value: promotionTemplateTypes.SPECIAL1,
  },
  {
    label: 'campaigns:createPromo:totalDependent',
    value: promotionTemplateTypes.TOTAL_DEPENDENT,
  },
];

const PromotionTypeSelectModal = function ({ campaignId, ...props }) {
  const { t } = useTranslation();
  const push = usePushAction();
  const [type, setType] = useState(promotionTemplateTypes.BASIC1);

  const handleTypeChange = useCallback(
    ({ target }) => {
      setType(target.value);
    },
    [setType],
  );

  const handleCreate = useCallback(() => {
    push(generatePath(paths.promotionAdd.path, { campaignId, type }));
  }, [push, campaignId, type]);

  const renderTypeOption = useCallback(
    ({ value, label }) => {
      return <FormControlLabel control={<Radio />} key={value} value={value} label={t(label)} />;
    },
    [t],
  );

  return (
    <Modal titleText="campaigns:selectPromotionType" onOk={handleCreate} hideAfterOk {...props}>
      <RadioGroup name="promo-type" value={type} onChange={handleTypeChange}>
        {promotionCreationTypes.map(renderTypeOption)}
      </RadioGroup>
    </Modal>
  );
};

PromotionTypeSelectModal.propTypes = {
  ...Modal.propTypes,
};

export default React.memo(PromotionTypeSelectModal);
