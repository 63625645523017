import React, { memo } from 'react';

import { TextField } from 'components';

const PromotionIdField = memo((props) => <TextField {...props} />);

PromotionIdField.propTypes = {
  ...TextField.propTypes,
};

PromotionIdField.defaultProps = {
  ...TextField.defaultProps,
  name: 'promotionId',
  label: 'campaigns:promotionId',
};

export default PromotionIdField;
