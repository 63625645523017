import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { ViewButton } from '../buttons';
import LinkButton from './LinkButton';

const ViewLinkButton = memo((props) => <LinkButton {...props} />);

ViewLinkButton.propTypes = {
  ...LinkButton.propTypes,
  component: PropTypes.object,
};

ViewLinkButton.defaultProps = {
  ...LinkButton.defaultProps,
  component: ViewButton,
};

export default ViewLinkButton;
