import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import values from 'lodash/values';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { useTranslation } from 'react-i18next';
import { promotionActionTypes } from '../../../ducks/campaigns/enums';
import { formatEuro } from '../../../app/formatters';

const TotalDependentAccruingRuleTable = memo((props) => {
  const { t } = useTranslation();
  const {
    item: { actions },
  } = props;
  const configuration = actions[promotionActionTypes.DYNAMIC_DISCOUNT].configuration || {
    accruingRules: [],
  };

  const renderRule = useCallback((data) => {
    const { discount, total } = data;
    return (
      <TableRow key={`${total}`}>
        <TableCell>{formatEuro(total)}</TableCell>
        <TableCell>{formatEuro(discount)}</TableCell>
      </TableRow>
    );
  }, []);

  return (
    <div>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t('campaigns:totalDependent:total')}</TableCell>
            <TableCell>{t('campaigns:totalDependent:discount')}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>{values(configuration.accruingRules).map((item) => renderRule(item))}</TableBody>
      </Table>
    </div>
  );
});

TotalDependentAccruingRuleTable.propTypes = {
  item: PropTypes.object.isRequired,
};

export default TotalDependentAccruingRuleTable;
