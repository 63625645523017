import { mapAddress } from 'ducks/clients/entityMappers';

export const mapStore = (data) => {
  const {
    id,
    number,
    name,
    posAmount,
    enabled,
    doReporting: reportingEnabled,
    priceTemplate: priceGroup,
    phoneNumber,
    contact = {},
    address = {},
    loyaltyDisablePeriods: loyaltyDisabledPeriods,
  } = data;
  const { firstName, lastName } = contact;
  const preparedContact = { firstName, lastName };

  return {
    id,
    loyaltyDisabledPeriods,
    number,
    posAmount,
    name,
    enabled,
    priceGroup,
    phoneNumber,
    reportingEnabled,
    contact: preparedContact,
    address: mapAddress(address),
  };
};

export const mapPos = (data) => {
  const { number } = data;
  return {
    number,
  };
};
