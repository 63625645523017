import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import Box from '@material-ui/core/Box';

import paths from 'app/paths';
import { promotionRuleTypes } from 'ducks/campaigns/enums';
import { Link, Caption } from 'components';

const PligCell = memo((props) => {
  const {
    item: { rules },
  } = props;
  const rule = rules[promotionRuleTypes.CART_HAS_ITEMS] || { pligs: [] };

  const renderPlig = useCallback((data) => {
    const {
      id,
      value,
      product: { label },
    } = data;
    return (
      <div key={id}>
        <Link to={paths.productsView.path} params={{ id }} title={label}>
          {value}
        </Link>
      </div>
    );
  }, []);

  const renderPligsGroup = useCallback(
    (pligRule, key) => {
      return (
        <Box mb={2} key={key}>
          <Caption strong={false} text={`(${key + 1})`} />
          <div>{pligRule.pligs.map(renderPlig)}</div>
        </Box>
      );
    },
    [renderPlig],
  );

  return <>{isArray(rule) ? rule.map(renderPligsGroup) : rule.pligs.map(renderPlig)}</>;
});

PligCell.propTypes = {
  item: PropTypes.object.isRequired,
};

export default PligCell;
