import React, { memo } from 'react';

import { SUPER_ADMIN, G20_ADMIN, STORE_ADMIN } from 'app/roles';
import DropdownField from './DropdownField';

const items = [
  {
    value: SUPER_ADMIN,
    label: `auth:roles.${SUPER_ADMIN}`,
  },
  {
    value: G20_ADMIN,
    label: `auth:roles.${G20_ADMIN}`,
  },
  {
    value: STORE_ADMIN,
    label: `auth:roles.${STORE_ADMIN}`,
  },
];

const RoleField = memo((props) => {
  return <DropdownField {...props} />;
});

RoleField.propTypes = {
  ...DropdownField.propTypes,
};

RoleField.defaultProps = {
  ...DropdownField.defaultProps,
  name: 'role',
  label: 'auth:role',
  items,
};

export default RoleField;
