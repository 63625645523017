import React, { memo, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import GetAppIcon from '@material-ui/icons/GetApp';

import { useAction } from 'app/hooks';
import { formatDateTime, formatEuro } from 'app/formatters';
import { actions as appActions } from 'ducks/app';
import {
  actions as transactionsActions,
  selectors as transactionsSelectors,
} from 'ducks/transactions';
import { List, Breadcrumbs } from 'components';
import TransactionFilter from './TransactionFilter';
import TransactionActionsCell from './TransactionActionsCell';

const rowConfig = [
  {
    name: 'storeName',
    label: 'stores:store',
    source: 'store.name',
  },
  {
    name: 'posNumber',
    label: 'stores:posNumber',
    source: 'pos.number',
  },
  {
    name: 'number',
    label: 'transactions:transactionNumber',
    source: 'number',
    sortable: true,
  },
  {
    name: 'total',
    label: 'app:amount',
    render: ({ total }) => formatEuro(total),
  },
  {
    name: 'cardNumber',
    label: 'clients:cardNumber',
    source: 'card.number',
    sortable: true,
  },
  {
    name: 'completedAt',
    label: 'app:date',
    render: ({ completedAt }) => formatDateTime(completedAt),
    sortable: true,
  },
  {
    name: 'actions',
    label: 'app:actions',
    component: TransactionActionsCell,
  },
];

const TransactionList = memo(() => {
  const { t } = useTranslation();
  const { items, meta } = useSelector(transactionsSelectors.transactionList);
  const fetchTransactionList = useAction(transactionsActions.fetchTransactionList);
  const exportRawCsv = useAction(appActions.exportRawCsv);

  const exportTransactions = useCallback(() => {
    exportRawCsv(
      'transactions',
      items,
      [
        'stores:store',
        'stores:posNumber',
        'transactions:transactionNumber',
        'app:amount',
        'clients:cardNumber',
        'app:date',
      ].map(t),
      (item) => [
        item.store.name,
        item.pos.number,
        item.number,
        formatEuro(item.total),
        item.card.number,
        formatDateTime(item.completedAt),
      ],
    );
  }, [items, exportRawCsv, t]);

  const toolbarActions = useMemo(() => {
    return [
      {
        name: 'exportTransactions',
        label: 'transactions:exportTransactions',
        icon: GetAppIcon,
        confirm: false,
        callback: exportTransactions,
      },
    ];
  }, [exportTransactions]);

  return (
    <>
      <Breadcrumbs />
      <List
        data={items}
        meta={meta}
        onFetchData={fetchTransactionList}
        filterComponent={TransactionFilter}
        rowConfig={rowConfig}
        toolbarActions={toolbarActions}
        fetchWithoutFilter={false}
      />
    </>
  );
});

export default TransactionList;
