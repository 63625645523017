import { SUPER_ADMIN, G20_ADMIN } from 'app/roles';

export default {
  admins: {
    path: '/admins',
    name: 'admins:admins',
    roles: [SUPER_ADMIN, G20_ADMIN],
  },
  adminsAdd: {
    path: '/admins/add',
    name: 'admins:addAdmin',
    roles: [SUPER_ADMIN],
  },
  adminsEdit: {
    path: '/admins/edit/:id',
    name: 'admins:editAdmin',
    roles: [SUPER_ADMIN, G20_ADMIN],
  },
};
