import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { formatDate } from 'app/formatters';
import { selectors as authSelectors } from 'ducks/auth';
import { Panel, PropertyRow } from 'components';
import { ConfirmedCell } from '../ClientList';
import ActivateClient from './ActivateClient';
import ActivateCard from './ActivateCard';
import DeactivateCard from './DeactivateCard';

const LoyaltyInfo = memo((props) => {
  const { client } = props;
  const { isDeleted } = client;
  const isAppAdmin = useSelector(authSelectors.isAppAdmin);
  const canActivateClient = isAppAdmin && client.isUserRelated && !client.user.enabled;
  const { t } = useTranslation();

  const renderCard = useCallback(
    (card) => {
      const { id, number, enabled } = card;
      const status = t(enabled ? 'app:enabled' : 'app:disabled');
      const canActivateCard = isAppAdmin && !enabled;
      const canDeactivateCard = isAppAdmin && enabled;
      return (
        <div key={id}>
          {number} - {status}
          {canDeactivateCard && !isDeleted && <DeactivateCard card={card} client={client} />}
          {canActivateCard && !isDeleted && <ActivateCard card={card} client={client} />}
        </div>
      );
    },
    [client, isAppAdmin, t],
  );

  return (
    <Panel title="clients:loyaltyInfo">
      <PropertyRow label="clients:confirmed">
        <ConfirmedCell item={client} />
        {canActivateClient && <ActivateClient client={client} />}
      </PropertyRow>
      <PropertyRow label="app:creationDate">{formatDate(client.user.createdAt)}</PropertyRow>
      <PropertyRow label="clients:loyaltyCard">{client.cards.map(renderCard)}</PropertyRow>
    </Panel>
  );
});

LoyaltyInfo.propTypes = {
  client: PropTypes.object.isRequired,
};

export default LoyaltyInfo;
