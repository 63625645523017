import axios from 'axios';

import * as mappers from './serviceMappers';

export function login(data) {
  return axios
    .post('token', mappers.mapLoginRequest(data))
    .then((response) => mappers.mapLoginResponse(response.data))
    .catch(mappers.mapLoginError);
}

export function fetchUser() {
  return axios.get('me').then((response) => mappers.mapUserResponse(response.data));
}

export function changePassword(userId, data) {
  return axios
    .post(`users/${userId}/change-password`, mappers.mapChangePasswordRequest(data))
    .catch(mappers.mapChangePasswordError);
}

export function resetPassword(data) {
  return axios
    .post('users/admin/reset-password-request', mappers.mapResetPasswordRequest(data))
    .catch(mappers.mapResetPasswordError);
}

export function resetPasswordConfirm(token, data) {
  return axios
    .post(`users/reset/${token}`, mappers.mapResetPasswordConfirmRequest(data))
    .catch(mappers.mapResetPasswordConfirmError);
}
