import React, { memo } from 'react';
import PropTypes from 'prop-types';

import PrimaryButton from './PrimaryButton';

const SaveButton = memo(({ submit, ...rest }) => {
  return <PrimaryButton type={submit ? 'submit' : undefined} {...rest} />;
});

SaveButton.propTypes = {
  ...PrimaryButton.propTypes,
  submit: PropTypes.bool,
};

SaveButton.defaultProps = {
  ...PrimaryButton.defaultProps,
  label: 'app:save',
  submit: true,
};

export default SaveButton;
