import React, { memo, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import MaterialTableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

import { ListContext } from './_constants';
import useStyles from './TableBody.styles';

const TableBody = memo(({ selectedItems, onSelectItem }) => {
  const { data, rowConfig, rowIdSource } = useContext(ListContext);
  const classes = useStyles();

  const renderCell = useCallback(
    (rowConfigItem, dataItem) => {
      const { name, source, render, component: Component } = rowConfigItem;
      return (
        <TableCell key={name} className={classes.cell} align="center">
          <>
            {(source && get(dataItem, source)) ||
              (render && render(dataItem)) ||
              (Component && <Component item={dataItem} />)}
          </>
        </TableCell>
      );
    },
    [classes],
  );

  const renderSelectionCell = useCallback(
    (itemId) => {
      if (selectedItems === null) {
        return null;
      }
      return (
        <TableCell key="select" className={classes.cell} align="center">
          <Checkbox
            checked={selectedItems.includes(itemId)}
            onChange={(e) => {
              onSelectItem(itemId, e.target.checked);
            }}
          />
        </TableCell>
      );
    },
    [selectedItems, onSelectItem, classes],
  );

  const renderRow = useCallback(
    (dataItem) => {
      const itemId = dataItem[rowIdSource];
      return (
        <TableRow key={itemId}>
          {renderSelectionCell(itemId)}
          {rowConfig.map((rowConfigItem) => {
            return renderCell(rowConfigItem, dataItem);
          })}
        </TableRow>
      );
    },
    [rowConfig, rowIdSource, renderCell, renderSelectionCell],
  );

  return <MaterialTableBody>{data.map(renderRow)}</MaterialTableBody>;
});

TableBody.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.number),
  onSelectItem: PropTypes.func,
};

TableBody.defaultProps = {
  selectedItems: null,
  onSelectItem: null,
};

export default TableBody;
