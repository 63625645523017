import isArray from 'lodash/isArray';
import values from 'lodash/values';

export const mapAdmin = ({ id, username, email, enabled, expired, createdAt, roles, locked }) => {
  const mappedRoles = isArray(roles) === false ? values(roles) : roles;

  return {
    id,
    username,
    email,
    enabled,
    expired,
    createdAt,
    locked,
    roles: mappedRoles,
  };
};
