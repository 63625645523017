import { put } from 'redux-saga/effects';

import { showError } from 'ducks/app/actions';
import * as errorTypes from './errorTypes';

export default {
  *[errorTypes.INVALID_CREDENTIALS]() {
    yield put(showError('auth:invalidCredentials'));
  },
  *[errorTypes.INVALID_CURRENT_PASSWORD]() {
    yield put(showError('auth:invalidCurrentPassword'));
  },
  *[errorTypes.INVALID_EMAIL]() {
    yield put(showError('auth:invalidEmail'));
  },
  *[errorTypes.INVALID_TOKEN]() {
    yield put(showError('auth:invalidToken'));
  },
  *[errorTypes.CANNOT_RESET_PASSWORD]() {
    yield put(showError('auth:cannotResetPassword'));
  },
  *[errorTypes.CANNOT_CHANGE_EMAIL]() {
    yield put(showError('auth:cannotChangeEmail'));
  },
};
