import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/modals/Modal';
import { Box, MenuItem, Select } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { GenerateDailyReportForm, GenerateMonthlyReportForm } from 'components/stores/forms';

export const REPORT_TYPES = {
  DAILY: 'daily',
  MONTHLY: 'monthly',
};

const GenerateReportForm = memo(
  ({ modalProps: { reportType: initialReportType, storeNumber, ...restModalProps } }) => {
    const { t } = useTranslation();

    const [reportType, setReportType] = useState(initialReportType || REPORT_TYPES.DAILY);

    useEffect(() => {
      if (initialReportType) {
        setReportType(initialReportType);
      }
    }, [initialReportType]);

    const handleReportTypeChange = useCallback(({ target }) => {
      setReportType(target.value);
    }, []);

    const formRef = useRef();

    const handleSubmit = () => {
      if (formRef.current) {
        formRef.current.handleSubmit?.();
      }
    };

    return (
      <Modal titleText="stores:generateReport" onOk={handleSubmit} {...restModalProps}>
        <>
          <Box clone mb={2}>
            <Select value={reportType} onChange={handleReportTypeChange}>
              <MenuItem value={REPORT_TYPES.DAILY}>{t`stores:dailyReport`}</MenuItem>
              <MenuItem value={REPORT_TYPES.MONTHLY}>{t`stores:monthlyReport`}</MenuItem>
            </Select>
          </Box>
          {reportType === REPORT_TYPES.DAILY && (
            <GenerateDailyReportForm ref={formRef} storeNumber={storeNumber} />
          )}
          {reportType === REPORT_TYPES.MONTHLY && (
            <GenerateMonthlyReportForm ref={formRef} storeNumber={storeNumber} />
          )}
        </>
      </Modal>
    );
  },
);

GenerateReportForm.propTypes = {
  modalProps: PropTypes.shape(Modal.propTypes).isRequired,
};

const GenerateReportModal = memo((props) => {
  return <GenerateReportForm modalProps={props} />;
});

GenerateReportModal.propTypes = {
  ...Modal.propTypes,
};

export default GenerateReportModal;
