import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';

import { formatEuro } from 'app/formatters';
import useStyles from './NeutralAdjustment.styles';

const NeutralAdjustment = memo((props) => {
  const {
    adjustment: { amount, description },
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Tooltip
      title={
        <div className={classes.tooltip}>
          <div>{t('transactions:accumulationOnLoyaltyAccount')}</div>
          <hr />
          <div>{formatEuro(amount)}</div>
          <div>{description}</div>
        </div>
      }
    >
      <span className={classes.star}>&#9733;</span>
    </Tooltip>
  );
});

NeutralAdjustment.propTypes = {
  adjustment: PropTypes.shape({
    amount: PropTypes.number,
    description: PropTypes.string,
  }).isRequired,
};

export default NeutralAdjustment;
