import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withFormik, Form } from 'formik';

import { useAction } from 'app/hooks';
import { actions as clientsActions } from 'ducks/clients';
import { StoreNumberActField } from 'components';
import Modal from 'components/modals/Modal';

const BestClientsForm = memo(({ handleSubmit, modalProps }) => {
  return (
    <Modal titleText="clients:exportBestClients" onOk={handleSubmit} {...modalProps}>
      <Form>
        <StoreNumberActField name="storeId" valueSource="id" />
      </Form>
    </Modal>
  );
});

BestClientsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  modalProps: PropTypes.shape(Modal.propTypes).isRequired,
};

const EnhancedBestClientsForm = withFormik({
  displayName: 'BestClientsForm',
  enableReinitialize: true,
  validateOnBlur: true,
  mapPropsToValues: () => ({
    storeId: '',
  }),
  handleSubmit: (values, { props: { onSubmit, header } }) => {
    onSubmit(values, header);
  },
})(BestClientsForm);

EnhancedBestClientsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
};

const BestClientsModal = memo((props) => {
  const fetchBestClients = useAction(clientsActions.fetchBestClients);
  const { t } = useTranslation();
  const header = useMemo(() => {
    return [
      'app:name',
      'clients:cardNumber',
      'clients:revenue',
      'app:address',
      'app:phoneNumber',
      'auth:email',
    ]
      .map(t)
      .join(';');
  }, [t]);

  return <EnhancedBestClientsForm modalProps={props} onSubmit={fetchBestClients} header={header} />;
});

BestClientsModal.propTypes = {
  ...Modal.propTypes,
};

export default BestClientsModal;
