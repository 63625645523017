/* eslint-disable no-console */
import React, { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectors as authSelectors } from 'ducks/auth';
import { Panel, Button } from 'components';
import ExportClientData from 'components/clients/ClientProfile/ExportClientData';

import ClientProfileDetails from './ClientProfileDetails';
import ClientProfileEdit from './ClientProfileEdit';
import ResetClientPassword from './ResetClientPassword';
import ChangeClientEmail from './ChangeClientEmail';

const ClientProfile = memo((props) => {
  const { client } = props;
  const { isDeleted } = client;
  const isAppAdmin = useSelector(authSelectors.isAppAdmin);
  const [isEdit, setIsEdit] = useState(false);
  const isSuperAdmin = useSelector(authSelectors.isSuperAdmin);

  const switchMode = useCallback(() => {
    setIsEdit(!isEdit);
  }, [isEdit]);

  return (
    <Panel title="clients:clientProfile">
      {!client.user.expired && !isDeleted && (
        <Button
          variant="outlined"
          size="small"
          label={isEdit ? 'app:cancel' : 'app:edit'}
          onClick={switchMode}
          marginRight
        />
      )}
      {!isDeleted && isAppAdmin && (
        <>
          {client.isUserRelated && <ResetClientPassword client={client} />}
          {isSuperAdmin && <ChangeClientEmail client={client} />}
        </>
      )}
      {isSuperAdmin && !isDeleted && <ExportClientData client={client} />}
      {!isEdit && <ClientProfileDetails client={client} />}
      {isEdit && <ClientProfileEdit client={client} />}
    </Panel>
  );
});

ClientProfile.propTypes = {
  client: PropTypes.object.isRequired,
};

export default ClientProfile;
