import React, { memo } from 'react';

import { DateField } from 'components';

const BirthdayField = memo((props) => <DateField {...props} />);

BirthdayField.propTypes = {
  ...DateField.propTypes,
};

BirthdayField.defaultProps = {
  ...DateField.defaultProps,
  name: 'birthday',
  label: 'clients:birthday',
};

export default BirthdayField;
