import React, { memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import MaterialLink from '@material-ui/core/Link';

import { generatePath } from 'app/utils';
import { usePushAction } from 'app/hooks';

const Link = memo((props) => {
  const { children, to, params, queryParams, ...rest } = props;
  const push = usePushAction();

  const path = useMemo(() => {
    return generatePath(to, params, queryParams);
  }, [to, params, queryParams]);

  const onClick = useCallback(
    (e) => {
      e.preventDefault();
      push(path);
    },
    [path, push],
  );

  return (
    <MaterialLink href={path} onClick={onClick} {...rest}>
      {children}
    </MaterialLink>
  );
});

Link.propTypes = {
  ...MaterialLink.propTypes,
  children: PropTypes.node.isRequired,
  params: PropTypes.object,
  queryParams: PropTypes.object,
};

Link.defaultProps = {
  ...MaterialLink.defaultProps,
  params: {},
  queryParams: {},
};

export default Link;
