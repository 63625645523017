import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  cellLabel: {
    color: '#000',
  },
  cell: {
    height: 40,
  },
}));

export default useStyles;
