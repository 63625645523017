import { put } from 'redux-saga/effects';

import { showError } from 'ducks/app/actions';
import * as errorTypes from './errorTypes';

export default {
  *[errorTypes.CANNOT_CREATE_COUPON]({ message }) {
    yield put(showError(message));
  },
};
