import authRoutes from './authRoutes';
import clientRoutes from './clientRoutes';
import adminRoutes from './adminRoutes';
import storeRoutes from './storeRoutes';
import campaignRoutes from './campaignRoutes';
import productRoutes from './productRoutes';
import transactionRoutes from './transactionRoutes';
import couponRoutes from './couponRoutes';

export default [].concat(
  authRoutes,
  clientRoutes,
  adminRoutes,
  storeRoutes,
  campaignRoutes,
  productRoutes,
  transactionRoutes,
  couponRoutes,
);
