import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  '@global': {
    '.blocked': {
      opacity: 0.5,
    },
    '.blocked *': {
      pointerEvents: 'none',
    },
  },
});

export default useStyles;
