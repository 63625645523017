import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    margin: '8px auto',
  },
  summary: {
    backgroundColor: '#f3f4f7',
  },
  expanded: {
    '&$expanded': {
      margin: 0,
    },
  },
  content: {
    width: '100%',
  },
}));

export default useStyles;
