export const FETCH_CAMPAIGN_LIST = 'campaigns/FETCH_CAMPAIGN_LIST';
export const FETCH_CAMPAIGN_LIST_SUCCESS = 'campaigns/FETCH_CAMPAIGN_LIST_SUCCESS';

export const FETCH_PROMOTION_LIST = 'campaigns/FETCH_PROMOTION_LIST';
export const FETCH_PROMOTION_LIST_SUCCESS = 'campaigns/FETCH_PROMOTION_LIST_SUCCESS';

export const UPDATE_COUPON_BASED = 'campaigns/UPDATE_COUPON_BASED';

export const FETCH_PROMOTION = 'campaigns/FETCH_PROMOTION';
export const FETCH_PROMOTION_SUCCESS = 'campaigns/FETCH_PROMOTION_SUCCESS';

export const DEACTIVATE_PROMOTION = 'campaigns/DEACTIVATE_PROMOTION';

export const UPDATE_PROMOTION = 'campaigns/UPDATE_PROMOTION';

export const UPLOAD_SPECIAL_CAMPAIGN_FILE = 'campaigns/UPLOAD_SPECIAL_CAMPAIGN_FILE';

export const CREATE_CAMPAIGN = 'campaigns/CREATE_CAMPAIGN';

export const CREATE_PROMOTION = 'campaigns/CREATE_PROMOTION';

export const CREATE_SPECIAL_PROMOTION = 'campaigns/CREATE_SPECIAL_PROMOTION';

export const REMOVE_CAMPAIGN = 'campaigns/REMOVE_CAMPAIGN';
export const REMOVE_CAMPAIGN_SUCCESS = 'admins/REMOVE_CAMPAIGN_SUCCESS';

export const CREATE_TOTAL_DEPENDENT_PROMOTION = 'campaigns/CREATE_TOTAL_DEPENDENT_PROMOTION';
