import React, { memo } from 'react';

import {
  Filter,
  TransactionNumberField,
  StoreNumberField,
  StartDateField,
  EndDateField,
  PligField,
  EanField,
  PromotionNameField,
} from 'components';

const TransactionFilter = memo(() => {
  return (
    <Filter
      initialValues={{
        transactionNumber: '',
        storeNumber: '',
        startDate: '',
        endDate: '',
        ean: '',
        plig: '',
        promotionName: '',
      }}
      mainField={<TransactionNumberField />}
    >
      <TransactionNumberField />
      <StoreNumberField />
      <StartDateField />
      <EndDateField />
      <EanField />
      <PligField />
      <PromotionNameField />
    </Filter>
  );
});

export default TransactionFilter;
