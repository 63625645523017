import axios from 'axios';

import * as mappers from './serviceMappers';

export function fetchAdminList(params) {
  return axios
    .get('users/search', { params: mappers.mapFetchAdminListRequest(params) })
    .then((response) => mappers.mapFetchAdminListResponse(response.data));
}

export function removeAdmin(id) {
  return axios.delete(`users/${id}`).catch(mappers.mapRemoveUserError);
}

export function createAdmin(data) {
  return axios
    .post('users/admin/', mappers.mapCreateAdminRequest(data))
    .catch(mappers.mapCreateAdminError);
}

export function fetchAdmin(id) {
  return axios.get(`users/${id}`).then((response) => mappers.mapFetchAdminResponse(response.data));
}

export function updateAdmin(id, data) {
  return axios
    .patch(`users/admin/${id}`, mappers.mapUpdateAdminRequest(data))
    .catch(mappers.mapUpdateAdminError);
}

export function fetchAdminGroups(id) {
  return axios
    .get('users/access-store-group-list', { params: id ? { user_id: id } : {} })
    .then((response) => mappers.mapFetchAdminGroupsResponse(response.data));
}

export function restoreAdmin(id) {
  return axios.post(`users/${id}/unlock`).catch(mappers.mapRestoreUserError);
}
