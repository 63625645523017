import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { Select, MenuItem, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Panel, Caption } from 'components';
import { promotionActionTypes } from 'ducks/campaigns/enums';

import { IntegerField } from 'components/shared';
import { DiscountTypeDropdownField, DiscountAmountField } from '../fields';
import PromotionTotalDependentAction from '../PromotionAdd/PromotionTotalDependentAction';

const ActionEdit = function ({ name, hideDropdown, label }) {
  return (
    <>
      <Caption text={`campaigns:${name}`} />
      <IntegerField label="campaigns:limit" name={`${name}.limit`} />
      <IntegerField label="campaigns:cycle" name={`${name}.cycle`} />
      {!hideDropdown && <DiscountTypeDropdownField name={`${name}.type`} />}
      <DiscountAmountField name={`${name}.amount`} label={label} />
    </>
  );
};

ActionEdit.defaultProps = {
  hideDropdown: false,
  label: undefined,
};

ActionEdit.propTypes = {
  hideDropdown: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};

const PromotionEditAction = function (promotion) {
  const { t } = useTranslation();

  const {
    values: { actions },
    setFieldValue,
  } = useFormikContext();

  const handleDiscountTypeChange = useCallback(
    ({ target }) => {
      const discountType = target.value;
      const discountConfiguration = {
        [discountType]: {
          type: discountType,
          configuration: {},
        },
      };

      setFieldValue('actions', discountConfiguration, false);
    },
    [setFieldValue],
  );

  const discountType =
    actions[promotionActionTypes.FIXED_DISCOUNT] ||
    actions[promotionActionTypes.PERCENTAGE_DISCOUNT];

  // todo add ability to edit discount rules
  if (!Object.values(actions).length || actions[promotionActionTypes.DYNAMIC_DISCOUNT]) {
    return null;
  }

  return (
    <Panel title="app:action" defaultExpanded>
      {discountType && !actions[promotionActionTypes.DYNAMIC_DISCOUNT] && (
        <Box clone mb={2}>
          <Select value={discountType.type} onChange={handleDiscountTypeChange}>
            <MenuItem value={promotionActionTypes.FIXED_DISCOUNT}>
              {t`campaigns:fixedDiscount`}
            </MenuItem>
            <MenuItem value={promotionActionTypes.PERCENTAGE_DISCOUNT}>
              {t`campaigns:percentageDiscount`}
            </MenuItem>
          </Select>
        </Box>
      )}
      {actions[promotionActionTypes.FIXED_DISCOUNT] && <ActionEdit name="fixedDiscount" />}
      {actions[promotionActionTypes.PERCENTAGE_DISCOUNT] && (
        <ActionEdit name="percentageDiscount" label="campaigns:discountAmountPercent" />
      )}
      {actions[promotionActionTypes.FIN_DISCOUNT] && <ActionEdit name="finDiscount" hideDropdown />}
      {actions[promotionActionTypes.FIXED_TOTAL_DISCOUNT] && (
        <ActionEdit name="fixedTotalDiscount" />
      )}
      {actions[promotionActionTypes.DYNAMIC_DISCOUNT] && (
        <PromotionTotalDependentAction promotion={promotion} />
      )}
    </Panel>
  );
};

export default React.memo(PromotionEditAction);
