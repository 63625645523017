import React, { memo } from 'react';

import { AmountField } from 'components';

const DiscountAmountField = memo((props) => <AmountField {...props} />);

DiscountAmountField.propTypes = {
  ...AmountField.propTypes,
};

DiscountAmountField.defaultProps = {
  ...AmountField.defaultProps,
  label: 'campaigns:discountAmountEuro',
};

export default DiscountAmountField;
