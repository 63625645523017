import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

const EditButton = memo(({ title, ...rest }) => {
  const { t } = useTranslation();
  return (
    <IconButton title={t(title)} {...rest}>
      <EditIcon />
    </IconButton>
  );
});

EditButton.propTypes = {
  ...IconButton.propTypes,
};

EditButton.defaultProps = {
  ...IconButton.defaultProps,
  title: 'app:edit',
};

export default EditButton;
