import React, { memo } from 'react';

import { TextField } from 'components';

const EanField = memo((props) => <TextField {...props} />);

EanField.propTypes = {
  ...TextField.propTypes,
};

EanField.defaultProps = {
  ...TextField.defaultProps,
  name: 'ean',
  label: 'products:ean',
};

export default EanField;
