import React, { memo, useEffect, useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { useAction } from 'app/hooks';
import { formatDateTime, formatEuro } from 'app/formatters';
import { selectors as clientsSelectors, actions as clientsActions } from 'ducks/clients';
import { entryTypes } from 'ducks/clients/enums';
import { ViewButton, Button } from 'components';
import Box from '@material-ui/core/Box';
import EntryAmountCell from './EntryAmountCell';
import BalanceCell from './BalanceCell';

export const calculateHistory = (data, accountBalance) => {
  const history = cloneDeep(data);
  let prevItem = null;
  let newBalance = accountBalance;

  history.forEach((historyItem, i) => {
    if (historyItem.type === entryTypes.ENTRY) {
      newBalance -= historyItem.item.amount;

      if (prevItem) {
        const order = history[i].item.orders[0];
        const prevOrder = prevItem.item.orders[0];

        if (order && prevOrder && order.id === prevOrder.id) {
          const prevHistory = prevItem.item.entryHistory;
          prevHistory.balance = newBalance;
          prevHistory.amount.push(historyItem.item.amount);
          history[i].ignore = true;
          return;
        }
      }

      history[i].item.entryHistory = {
        balance: newBalance,
        amount: [historyItem.item.amount],
        id: historyItem.item.id,
      };
      prevItem = history[i];
    } else if (historyItem.type === entryTypes.ORDER) {
      history[i].item.createdAt = history[i].item.completedAt;
      history[i].item.entryHistory = {
        balance: newBalance,
        amount: [0],
        id: historyItem.item.id,
      };
    }
  });

  return history.filter(({ ignore }) => !ignore);
};

const EntryTable = memo((props) => {
  const { t } = useTranslation();
  const { show, account, setDetailEntry } = props;
  const {
    meta: { page, pages },
    items,
  } = useSelector(clientsSelectors.accountBalanceHistory)(account.id);
  const fetchBalanceHistory = useAction(clientsActions.fetchBalanceHistory);
  const [currentPage, setCurrentPage] = useState(1);
  const isLastPage = currentPage === pages;

  const getExpiredBalance = useAction(clientsActions.getExpiredBalance);
  const expiredBalance = useSelector(clientsSelectors.accountExpiredBalance)(account.id);

  useEffect(() => {
    fetchBalanceHistory(account.id, currentPage);
  }, [account.id, currentPage, fetchBalanceHistory]);

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  const history = useMemo(() => {
    return calculateHistory(items, account.balance);
  }, [items, account.balance]);

  const renderItem = useCallback(
    (data) => {
      const { type, item } = data;
      const { id, createdAt } = item;
      const canShowDetail = type === entryTypes.ORDER || !isEmpty(item.orders);
      return (
        <TableRow key={`${type}${id}`}>
          <TableCell>{formatDateTime(createdAt)}</TableCell>
          <EntryAmountCell entry={data} />
          <BalanceCell entry={data} />
          <TableCell>
            <ViewButton
              onClick={() => setDetailEntry(data)}
              style={{ visibility: canShowDetail ? null : 'hidden' }}
              size="small"
            />
          </TableCell>
        </TableRow>
      );
    },
    [setDetailEntry],
  );

  const fetchExpiredBalance = useCallback(() => {
    getExpiredBalance(account.id);
  }, [account.id]);

  const showMore = useCallback(() => {
    setCurrentPage(page + 1);
  }, [page]);

  return (
    <div style={{ display: show ? 'none' : null }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t('app:date')}</TableCell>
            <TableCell>{t('app:amount')}</TableCell>
            <TableCell>{t('clients:balance')}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>{history.map(renderItem)}</TableBody>
      </Table>

      <Box>
        {expiredBalance === null ? (
          <Button
            onClick={fetchExpiredBalance}
            label="clients:expiredBalanceButton"
            variant="outlined"
            size="small"
          />
        ) : (
          <p>
            {t('clients:expiredBalance')}: {formatEuro(expiredBalance)}
          </p>
        )}
      </Box>

      {!isLastPage && (
        <Button onClick={showMore} label="app:showMore" variant="outlined" size="small" />
      )}
    </div>
  );
});

EntryTable.propTypes = {
  account: PropTypes.object.isRequired,
  setDetailEntry: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default EntryTable;
