import React, { memo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useAction } from 'app/hooks';
import { actions as storesActions, selectors as storesSelectors } from 'ducks/stores';
import { selectors as authSelectors } from 'ducks/auth';
import { Breadcrumbs } from 'components';

import { StoreLoyaltyDisablePeriods } from 'components/stores/StoreView/StoreLoyaltyDisablePeriods';
import StoreForm from './StoreForm';
import StoreStatus from './StoreStatus';
import StoreReporting from './StoreReporting';
import validationSchema from './validationSchema';

const StoreEdit = memo(
  ({
    match: {
      params: { id },
    },
  }) => {
    const isSuperAdmin = useSelector(authSelectors.isSuperAdmin);
    const editingStore = useSelector(storesSelectors.editingStore);
    const fetchStore = useAction(storesActions.fetchStore);
    const updateStore = useAction(storesActions.updateStore);

    useEffect(() => {
      if (id) {
        fetchStore(id);
      }
    }, [id, fetchStore]);

    const onSubmit = useCallback(
      (values) => {
        updateStore(id, values);
      },
      [id, updateStore],
    );

    return (
      <>
        <Breadcrumbs />
        <StoreForm validationSchema={validationSchema} onSubmit={onSubmit} store={editingStore} />
        {isSuperAdmin && (
          <StoreLoyaltyDisablePeriods
            loyaltyDisabledPeriods={editingStore.loyaltyDisabledPeriods}
            storeId={editingStore.id}
          />
        )}
        {isSuperAdmin && <StoreReporting id={id} store={editingStore} />}
        {isSuperAdmin && <StoreStatus id={id} store={editingStore} />}
      </>
    );
  },
);

StoreEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default StoreEdit;
