import React, { memo } from 'react';

import { Panel, TargetDropdownField, StartDateField, EndDateField } from 'components';
import { useFormikContext } from 'formik';
import { promotionActionTypes } from '../../../ducks/campaigns/enums';

const PromotionEditGeneral = memo(() => {
  const {
    values: { actions },
  } = useFormikContext();

  return (
    <Panel title="app:general" defaultExpanded>
      {!actions[promotionActionTypes.DYNAMIC_DISCOUNT] && <TargetDropdownField />}
      <StartDateField />
      <EndDateField />
    </Panel>
  );
});

export default PromotionEditGeneral;
