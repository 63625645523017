import React from 'react';

import Modal from './Modal';

const ConfirmationModal = function (props) {
  return <Modal {...props} />;
};

ConfirmationModal.propTypes = {
  ...Modal.propTypes,
};

ConfirmationModal.defaultProps = {
  ...Modal.defaultProps,
  titleText: 'app:confirmation',
  bodyText: 'app:confirmationQuestion',
  hideAfterOk: true,
};

export default ConfirmationModal;
