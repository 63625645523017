import React from 'react';
import { Form, useFormik, FormikProvider } from 'formik';
import * as yup from 'yup';

import Modal from 'components/modals/Modal';
import { actions } from 'ducks/campaigns';
import { useAction } from 'app/hooks';
import { TextField } from 'components';

const validationSchema = yup.object({
  name: yup.string().required('app:required'),
  description: yup.string().required('app:required'),
});

const CreateCampaignModal = function (props) {
  const createCampaignAction = useAction(actions.createCampaign);

  const formik = useFormik({
    validationSchema,
    initialValues: {
      name: '',
      description: '',
    },
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: createCampaignAction,
  });

  return (
    <Modal titleText="campaigns:create" okText="app:create" onOk={formik.handleSubmit} {...props}>
      <FormikProvider value={formik}>
        <Form>
          <TextField name="name" label="campaigns:campaignName" />
          <TextField name="description" label="campaigns:campaignDescription" />
        </Form>
      </FormikProvider>
    </Modal>
  );
};

CreateCampaignModal.propTypes = {
  ...Modal.propTypes,
};

export default React.memo(CreateCampaignModal);
