import { Map } from 'immutable';

import { createObjectSelector, createPaginatedListSelector } from 'app/selectors';

export const adminList = createPaginatedListSelector((state) =>
  state.admins.get('adminList', Map()),
);

export const editingAdmin = createObjectSelector((state) =>
  state.admins.get('editingAdmin', Map()),
);

export const adminGroups = createObjectSelector((state) => state.admins.get('adminGroups', Map()));
