import React, { memo } from 'react';

import Button from './Button';

const SecondaryButton = memo((props) => <Button {...props} />);

SecondaryButton.propTypes = {
  ...Button.propTypes,
};

SecondaryButton.defaultProps = {
  ...Button.defaultProps,
  variant: 'contained',
  color: 'secondary',
};

export default SecondaryButton;
