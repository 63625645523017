import React, { memo, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import MaterialToolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { actions as modalsActions, modalTypes } from 'ducks/modals';
import { useAction } from 'app/hooks';
import { ListContext } from './_constants';
import BatchActions from './BatchActions';
import useStyles from './Toolbar.styles';

const Toolbar = memo(({ selectedItems }) => {
  const listContext = useContext(ListContext);
  const { toolbarActions } = listContext;
  const showModal = useAction(modalsActions.showModal);
  const classes = useStyles();
  const { t } = useTranslation();
  const selectedItemsCount = selectedItems ? selectedItems.length : 0;

  const performAction = useCallback(
    ({ confirm, label, callback }) => {
      if (confirm === false) {
        callback({ ...listContext });
      } else {
        showModal(modalTypes.CONFIRMATION_MODAL, {
          titleText: label,
          hideAfterOk: true,
          onOk: () => callback({ ...listContext }),
        });
      }
    },
    [showModal, listContext],
  );

  const renderToolbarAction = useCallback(
    (action) => {
      const { show, name, label, icon: Icon } = action;
      if (show === false) {
        return null;
      }
      return (
        <Tooltip key={name} title={t(label)}>
          <IconButton onClick={() => performAction(action)}>
            <Icon fontSize="large" />
          </IconButton>
        </Tooltip>
      );
    },
    [performAction, t],
  );

  return (
    <MaterialToolbar
      className={clsx(classes.root, {
        [classes.highlight]: selectedItemsCount > 0,
      })}
    >
      <div className={classes.batchActions}>
        {selectedItemsCount > 0 && <BatchActions selectedItems={selectedItems} />}
      </div>
      {toolbarActions && selectedItemsCount === 0 && toolbarActions.map(renderToolbarAction)}
    </MaterialToolbar>
  );
});

Toolbar.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.number),
};

Toolbar.defaultProps = {
  selectedItems: [],
};

export default Toolbar;
