import { schema } from 'normalizr';

import { mapListRequest, mapListResponse } from 'app/mappers';
import { errorTypes as appErrorTypes } from 'ducks/app';
import { errorTypes as authErrorTypes } from 'ducks/auth';
import { mapClient } from './entityMappers';

const clientSchema = new schema.Entity('clients');
const clientListSchema = [clientSchema];

export const mapFetchClientListRequest = (params) => {
  return mapListRequest(params, {
    scope: 'user_full,card_full,account_full,store_full',
    filterMap: {
      cardNumber: 'card',
    },
    sortSnakeCase: false,
  });
};

export const mapFetchClientListResponse = (data) => {
  return mapListResponse(data, {
    entityName: 'clients',
    entityMapper: mapClient,
    schema: clientListSchema,
  });
};

export const mapFetchBestClientsResponse = (dataString) => {
  const [, ...items] = dataString.split('\n');
  return items.filter((item) => item).join('\n');
};

export const mapFetchClientResponse = (data) => {
  return mapClient(data);
};

export const mapResetClientPasswordError = (e) => {
  e.type = authErrorTypes.CANNOT_RESET_PASSWORD;
  throw e;
};

export const mapChangeClientEmailError = (e) => {
  e.type = authErrorTypes.CANNOT_CHANGE_EMAIL;
  throw e;
};

export const mapUpdateClientProfileRequest = (data) => {
  const {
    gender,
    birthday,
    firstName,
    lastName,
    phoneNumber,
    houseNumber,
    street,
    postalCode,
    city,
    country,
  } = data;

  const primaryAddress = {
    houseNumber,
    streetName: street,
    postCode: postalCode,
    town: city,
    country,
  };

  return {
    gender,
    birthday,
    firstName,
    lastName,
    mobileNumber: phoneNumber,
    primaryAddress,
  };
};

export const mapUpdateClientProfileError = (e) => {
  e.type = appErrorTypes.CANNOT_UPDATE;
  throw e;
};

export const mapActivateClientError = (e) => {
  e.type = appErrorTypes.CANNOT_ACTIVATE;
  throw e;
};

export const mapDeactivateClientError = (e) => {
  e.type = appErrorTypes.CANNOT_DEACTIVATE;
  throw e;
};

export const mapActivateCardError = (e) => {
  e.type = appErrorTypes.CANNOT_ACTIVATE;
  throw e;
};

export const mapDeactivateCardError = (e) => {
  e.type = appErrorTypes.CANNOT_DEACTIVATE;
  throw e;
};

export const mapFetchBalanceHistoryResponse = (data, accountId) => {
  const { page, pages, embedded: { items = [] } = {} } = data;
  return {
    meta: {
      page,
      pages,
    },
    items,
    accountId,
  };
};

export const mapUpdateBalanceError = (e) => {
  e.type = appErrorTypes.CANNOT_UPDATE;
  throw e;
};

export const mapUploadCardsError = (error) => {
  error.type = appErrorTypes.CANNOT_UPLOAD;
  throw error;
};

export const mapSubtractExpiredBonusesError = (error) => {
  error.type = appErrorTypes.CANNOT_REMOVE;
  throw error;
};
