import React, { useCallback, useRef } from 'react';

import Modal from 'components/modals/Modal';
import { actions } from 'ducks/campaigns';
import { useAction } from 'app/hooks';

const UploadCampaignFileModal = function (props) {
  const uploadAction = useAction(actions.uploadSpecialCampaignFile);
  const file = useRef(null);

  const handleFileSelect = useCallback(
    ({ target }) => {
      [file.current] = target.files;
    },
    [file],
  );

  const handleFileSubmit = useCallback(() => {
    file.current && uploadAction(file.current);
  }, [file, uploadAction]);

  return (
    <Modal
      titleText="campaigns:uploadSpecialCampaignFile"
      okText="campaigns:uploadButton"
      onOk={handleFileSubmit}
      {...props}
    >
      <input type="file" accept=".xls,.xlsx" onChange={handleFileSelect} />
    </Modal>
  );
};

UploadCampaignFileModal.propTypes = {
  ...Modal.propTypes,
};

export default React.memo(UploadCampaignFileModal);
