import React, { memo } from 'react';

import { TextField } from 'components';

const StoreNumberField = memo((props) => <TextField {...props} />);

StoreNumberField.propTypes = {
  ...TextField.propTypes,
};

StoreNumberField.defaultProps = {
  ...TextField.defaultProps,
  name: 'storeNumber',
  label: 'stores:storeNumber',
};

export default StoreNumberField;
