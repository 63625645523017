import React, { memo } from 'react';

import TextField from './TextField';

const PostalCodeField = memo((props) => <TextField {...props} />);

PostalCodeField.propTypes = {
  ...TextField.propTypes,
};

PostalCodeField.defaultProps = {
  ...TextField.defaultProps,
  name: 'postalCode',
  label: 'app:postalCode',
};

export default PostalCodeField;
