import * as actionTypes from './actionTypes';

export const showModal = (name, props) => ({
  type: actionTypes.SHOW_MODAL,
  name,
  props,
});

export const hideModal = () => ({
  type: actionTypes.HIDE_MODAL,
});

export const clearModal = () => ({
  type: actionTypes.CLEAR_MODAL,
});
