import { call, all, takeLatest, put } from 'redux-saga/effects';
import { csv } from 'app/services';

import { IS_DEV } from 'app/config';
import { errorHandlers } from 'app/state';
import { showError } from 'ducks/app/actions';
import * as actionTypes from './actionTypes';

function* handleError({ error }) {
  const { message, type } = error;
  if (type) {
    const errorHandler = errorHandlers[type];
    if (errorHandler) {
      yield errorHandler(error);
    }
  } else if (IS_DEV) {
    yield put(showError(message));
    throw error;
  } else {
    yield put(showError('app:somethingWentWrong'));
  }
}

function* exportPreparedCsv({ filename, dataString }) {
  yield call(csv.exportPreparedData, filename, dataString);
}

function* exportRawCsv({ filename, rows, columnNames, rowHandler }) {
  yield call(csv.exportRawData, filename, rows, columnNames, rowHandler);
}

export default function* () {
  yield all([
    yield takeLatest(actionTypes.HANDLE_ERROR, handleError),
    yield takeLatest(actionTypes.EXPORT_PREPARED_CSV, exportPreparedCsv),
    yield takeLatest(actionTypes.EXPORT_RAW_CSV, exportRawCsv),
  ]);
}
