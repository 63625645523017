import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Panel } from 'components';
import { promotionRuleTypes } from 'ducks/campaigns/enums';

import PligsCell from '../PromotionList/PligsCell';

const PromotionDetailsPligs = memo(({ promotion }) => {
  const rule = promotion.rules[promotionRuleTypes.CART_HAS_ITEMS];

  if (!rule) return null;

  return (
    <Panel title="products:pligs" defaultExpanded>
      <PligsCell item={promotion} />
    </Panel>
  );
});

PromotionDetailsPligs.propTypes = {
  promotion: PropTypes.object.isRequired,
};

export default PromotionDetailsPligs;
