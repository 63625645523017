import { applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';

import { IS_DEV } from 'app/config';
import { history } from 'app/router';

const composeEnhancers = (IS_DEV && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const sagaMiddleware = createSagaMiddleware();

export default composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware));
