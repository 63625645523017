import React, { memo, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

import { ListContext } from './_constants';
import useStyles from './Pagination.styles';

const PaginationActions = memo(({ count, page, rowsPerPage, onPageChange: onChangePage }) => {
  const classes = useStyles();
  const theme = useTheme();

  const handleFirstPageButtonClick = useCallback(() => {
    onChangePage(0);
  }, [onChangePage]);

  const handleBackButtonClick = useCallback(() => {
    onChangePage(page - 1);
  }, [onChangePage, page]);

  const handleNextButtonClick = useCallback(() => {
    onChangePage(page + 1);
  }, [onChangePage, page]);

  const handleLastPageButtonClick = useCallback(() => {
    onChangePage(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }, [onChangePage, count, rowsPerPage]);

  return (
    <div className={classes.root}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0}>
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
});

PaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const Pagination = memo((props) => {
  const {
    meta: { page, limit, total },
    updatePage,
  } = useContext(ListContext);

  const changePage = useCallback(
    (value) => {
      updatePage(value + 1);
    },
    [updatePage],
  );

  return (
    <TablePagination
      ActionsComponent={PaginationActions}
      component="div"
      count={total}
      rowsPerPage={limit}
      page={page - 1}
      onPageChange={changePage}
      {...props}
    />
  );
});

Pagination.propTypes = {
  ...TablePagination.propTypes,
};

Pagination.defaultProps = {
  rowsPerPageOptions: [],
};

export default Pagination;
