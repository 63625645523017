import { put } from 'redux-saga/effects';

import { showError } from 'ducks/app/actions';
import * as errorTypes from './errorTypes';

export default {
  *[errorTypes.CANNOT_CREATE]() {
    yield put(showError('app:cannotCreate'));
  },
  *[errorTypes.CANNOT_UPDATE]() {
    yield put(showError('app:cannotUpdate'));
  },
  *[errorTypes.CANNOT_UPLOAD]() {
    yield put(showError('app:cannotUpload'));
  },
  *[errorTypes.CANNOT_REMOVE]() {
    yield put(showError('app:cannotRemove'));
  },
  *[errorTypes.CANNOT_ADD]() {
    yield put(showError('app:cannotAdd'));
  },
  *[errorTypes.CANNOT_ACTIVATE]() {
    yield put(showError('app:cannotActivate'));
  },
  *[errorTypes.CANNOT_DEACTIVATE]() {
    yield put(showError('app:cannotDeactivate'));
  },
};
