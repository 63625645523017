import { fromJS } from 'immutable';

import * as actionTypes from './actionTypes';

const initialState = fromJS({
  storeList: {
    order: [],
    items: {},
    meta: { page: 1, limit: 10, total: 0 },
  },
  editingStore: {},
  priceGroups: [],
  storeOptions: [],
});

export default (state = initialState, { type, data }) => {
  const copyState = state.toJS();

  switch (type) {
    case actionTypes.FETCH_STORE_LIST_SUCCESS:
      return state.merge(
        fromJS({
          storeList: data,
        }),
      );

    case actionTypes.FETCH_STORE_SUCCESS:
      return state.merge(
        fromJS({
          editingStore: data,
        }),
      );

    case actionTypes.FETCH_PRICE_GROUPS_SUCCESS:
      return state.merge(
        fromJS({
          priceGroups: data,
        }),
      );

    case actionTypes.FETCH_STORE_OPTIONS_SUCCESS:
      return state.merge(
        fromJS({
          storeOptions: data,
        }),
      );

    case actionTypes.CREATE_LOYALTY_DISABLED_PERIOD_SUCCESS:
      const { endsAt, startsAt, id } = data;

      copyState?.editingStore.loyaltyDisabledPeriods.push({
        id,
        startsAt,
        endsAt,
      });

      return fromJS(copyState);
    case actionTypes.UPDATE_LOYALTY_DISABLED_PERIOD_SUCCESS:
      const { endsAt: changedEndsAt, startsAt: changedStartsAt, id: changedId } = data;

      copyState.editingStore.loyaltyDisabledPeriods =
        copyState.editingStore.loyaltyDisabledPeriods.map((period) =>
          period.id !== changedId
            ? period
            : { id: changedId, startsAt: changedStartsAt, endsAt: changedEndsAt },
        );

      return fromJS(copyState);
    case actionTypes.REMOVE_LOYALTY_DISABLED_PERIOD_SUCCESS:
      const removedId = data;

      copyState.editingStore.loyaltyDisabledPeriods =
        copyState?.editingStore.loyaltyDisabledPeriods.filter(
          ({ id: periodId }) => periodId !== removedId,
        );

      return fromJS(copyState);
    default:
      return state;
  }
};
