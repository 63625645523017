import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AutoSizer, Column, Table } from 'react-virtualized';
import TableCell from '@material-ui/core/TableCell';

import { AddButton, RemoveButton } from 'components';
import useStyles from './AdminGroupTable.styles';

const AdminGroupTable = memo(({ groups, addGroup, removeGroup }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const headerRenderer = useCallback(
    ({ label }) => {
      return (
        <TableCell component="div" variant="head">
          {t(label)}
        </TableCell>
      );
    },
    [t],
  );

  const textCellRenderer = useCallback(
    ({ cellData }) => {
      return (
        <TableCell component="div" variant="body">
          {cellData || <em>{t('app:none')}</em>}
        </TableCell>
      );
    },
    [t],
  );

  const actionCellRenderer = useCallback(
    ({ rowData: { id, name, admins } }) => {
      return (
        <TableCell component="div" variant="body">
          {addGroup && <AddButton onClick={() => addGroup({ id, name, admins })} />}
          {removeGroup && <RemoveButton onClick={() => removeGroup({ id, name })} />}
        </TableCell>
      );
    },
    [addGroup, removeGroup],
  );

  const rowGetter = useCallback(
    ({ index }) => {
      return groups[index];
    },
    [groups],
  );

  return (
    <div className={classes.root}>
      <AutoSizer>
        {({ height, width }) => {
          const multiplier = Math.floor(width / 12);
          return (
            <Table
              height={height}
              width={width}
              headerHeight={40}
              rowHeight={40}
              rowClassName={classes.row}
              gridClassName={classes.grid}
              rowCount={groups.length}
              rowGetter={rowGetter}
            >
              <Column
                key="id"
                dataKey="id"
                label="app:id"
                headerRenderer={headerRenderer}
                cellRenderer={textCellRenderer}
                width={multiplier * 2}
              />
              <Column
                key="name"
                dataKey="name"
                label="admins:group"
                headerRenderer={headerRenderer}
                cellRenderer={textCellRenderer}
                width={multiplier * 10}
              />
              <Column
                key="action"
                dataKey="action"
                label="app:action"
                headerRenderer={headerRenderer}
                cellRenderer={actionCellRenderer}
                width={multiplier * 2}
              />
            </Table>
          );
        }}
      </AutoSizer>
    </div>
  );
});

AdminGroupTable.propTypes = {
  groups: PropTypes.array.isRequired,
  addGroup: PropTypes.func,
  removeGroup: PropTypes.func,
};

AdminGroupTable.defaultProps = {
  addGroup: undefined,
  removeGroup: undefined,
};

export default AdminGroupTable;
