import React, { memo } from 'react';

import { Filter, LabelField, PligField } from 'components';

const ProductFilter = memo(() => {
  return (
    <Filter
      initialValues={{
        label: '',
        plig: '',
      }}
      mainField={<LabelField />}
    >
      <LabelField />
      <PligField />
    </Filter>
  );
});

export default ProductFilter;
