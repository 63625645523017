import * as actionTypes from './actionTypes';

export const fetchStoreList = (params) => ({
  type: actionTypes.FETCH_STORE_LIST,
  params,
});

export const fetchStoreListSuccess = (data) => ({
  type: actionTypes.FETCH_STORE_LIST_SUCCESS,
  data,
});

export const fetchOnlinePercentage = (params) => ({
  type: actionTypes.FETCH_ONLINE_PERCENTAGE,
  params,
});

export const fetchStore = (id) => ({
  type: actionTypes.FETCH_STORE,
  id,
});

export const fetchStoreSuccess = (data) => ({
  type: actionTypes.FETCH_STORE_SUCCESS,
  data,
});

export const updateStore = (id, data) => ({
  type: actionTypes.UPDATE_STORE,
  id,
  data,
});

export const addStore = (data) => ({
  type: actionTypes.ADD_STORE,
  data,
});

export const activateStore = (id) => ({
  type: actionTypes.ACTIVATE_STORE,
  id,
});

export const deactivateStore = (id) => ({
  type: actionTypes.DEACTIVATE_STORE,
  id,
});

export const enableStoreReporting = (id) => ({
  type: actionTypes.ENABLE_STORE_REPORTING,
  id,
});

export const disableStoreReporting = (id) => ({
  type: actionTypes.DISABLE_STORE_REPORTING,
  id,
});

export const fetchPriceGroups = () => ({
  type: actionTypes.FETCH_PRICE_GROUPS,
});

export const fetchPriceGroupsSuccess = (data) => ({
  type: actionTypes.FETCH_PRICE_GROUPS_SUCCESS,
  data,
});

export const fetchStoreOptions = (actId, params) => ({
  type: actionTypes.FETCH_STORE_OPTIONS,
  actId,
  params,
});

export const fetchStoreOptionsSuccess = (data) => ({
  type: actionTypes.FETCH_STORE_OPTIONS_SUCCESS,
  data,
});

export const generateDailyReport = (storeNumber, data) => ({
  type: actionTypes.GENERATE_DAILY_REPORT,
  storeNumber,
  data,
});

export const generateMonthlyReport = (storeNumber, data) => ({
  type: actionTypes.GENERATE_MONTHLY_REPORT,
  storeNumber,
  data,
});

export const generateReportSuccess = () => ({
  type: actionTypes.GENERATE_REPORT_SUCCESS,
});

export const createLoyaltyDisabledPeriod = ({ storeId, startsAt, endsAt }) => ({
  type: actionTypes.CREATE_LOYALTY_DISABLED_PERIOD,
  storeId,
  startsAt,
  endsAt,
});

export const createLoyaltyDisabledPeriodSuccess = (data) => ({
  type: actionTypes.CREATE_LOYALTY_DISABLED_PERIOD_SUCCESS,
  data,
});

export const createLoyaltyDisabledPeriodFailure = (error) => ({
  type: actionTypes.CREATE_LOYALTY_DISABLED_PERIOD_FAILED,
  error,
});

export const updateLoyaltyDisabledPeriod = ({ startsAt, endsAt, periodId }) => ({
  type: actionTypes.UPDATE_LOYALTY_DISABLED_PERIOD,
  periodId,
  startsAt,
  endsAt,
});

export const updateLoyaltyDisabledPeriodSuccess = (data) => ({
  type: actionTypes.UPDATE_LOYALTY_DISABLED_PERIOD_SUCCESS,
  data,
});

export const updateLoyaltyDisabledPeriodFailed = (error) => ({
  type: actionTypes.UPDATE_LOYALTY_DISABLED_PERIOD_FAILED,
  error,
});

export const removeLoyaltyDisabledPeriod = (periodId) => ({
  type: actionTypes.REMOVE_LOYALTY_DISABLED_PERIOD,
  periodId,
});

export const removeLoyaltyDisabledPeriodSuccess = (data) => ({
  type: actionTypes.REMOVE_LOYALTY_DISABLED_PERIOD_SUCCESS,
  data,
});

export const removeLoyaltyDisabledPeriodFailed = (error) => ({
  type: actionTypes.REMOVE_LOYALTY_DISABLED_PERIOD_FAILED,
  error,
});

export const uploadCardsFileForStore = (file, storeNumber) => ({
  type: actionTypes.UPLOAD_CARDS_FILE_FOR_STORE,
  file,
  storeNumber,
});
