import React, { memo } from 'react';

import DateField from './DateField';

const StartDateField = memo((props) => <DateField {...props} />);

StartDateField.propTypes = {
  ...DateField.propTypes,
};

StartDateField.defaultProps = {
  ...DateField.defaultProps,
  name: 'startDate',
  label: 'app:startDate',
};

export default StartDateField;
