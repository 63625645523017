import PropTypes from 'prop-types';
import React, { memo, useCallback, useState } from 'react';
import { useFormikContext } from 'formik';
import Grid from '@material-ui/core/Grid';
import keys from 'lodash/keys';
import omit from 'lodash/omit';

import { Button, AddButton, PligActField, RemoveButton } from 'components';
import { useTranslation } from 'react-i18next';
import useStyles from './PromotionEditPligs.styles';

const PromotionEditPligs = memo(({ pligsKey }) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();
  const pligs = values[pligsKey];
  const [newPlig, setNewPlig] = useState({});
  const classes = useStyles();

  const removePligList = useCallback(
    (ruleId) => {
      setFieldValue('pligs', omit(pligs, ruleId));
    },
    [setFieldValue, pligs],
  );

  const removePlig = useCallback(
    (plig, ruleId) => {
      const fieldValue = {
        ...pligs,
        [ruleId]: pligs[ruleId].filter((item) => item !== plig),
      };
      setFieldValue(pligsKey, fieldValue);
    },
    [setFieldValue, pligs],
  );

  const addPlig = useCallback(
    (ruleId) => {
      const currentPlig = newPlig[ruleId];

      if (currentPlig && !pligs[ruleId].includes(currentPlig)) {
        const fieldValue = {
          ...pligs,
          [ruleId]: [...pligs[ruleId], currentPlig],
        };
        setFieldValue(pligsKey, fieldValue);
      }
    },
    [setFieldValue, pligs, newPlig],
  );

  const renderPlig = useCallback(
    (plig, ruleId) => {
      return (
        <div className={classes.eanRow} key={plig}>
          {plig}
          <RemoveButton
            className={classes.removeButton}
            onClick={() => removePlig(plig, ruleId)}
            size="small"
          />
        </div>
      );
    },
    [removePlig, classes],
  );

  const handlePligChange = useCallback(
    (plig, ruleId) => {
      setNewPlig((prev) => ({
        ...prev,
        [ruleId]: plig,
      }));
    },
    [setNewPlig],
  );

  const renderPligGroup = useCallback(
    (ruleId, index) => {
      return (
        <div key={ruleId}>
          {keys(pligs).length > 1 && (
            <Button variant="outlined" onClick={() => removePligList(ruleId)}>
              {t('campaigns:removePligsList', { n: index + 1 })}
            </Button>
          )}
          <Grid container>
            <Grid item xs={2}>
              <PligActField
                name="pligs"
                id={ruleId}
                onChange={(plig) => handlePligChange(plig, ruleId)}
              />
            </Grid>
            <Grid item xs={10}>
              <AddButton className={classes.addButton} onClick={() => addPlig(ruleId)} />
            </Grid>
          </Grid>
          {pligs[ruleId].map((p) => renderPlig(p, ruleId))}
        </div>
      );
    },
    [t, pligs, addPlig, handlePligChange, removePligList, renderPlig, classes],
  );

  return keys(pligs).map(renderPligGroup);
});

PromotionEditPligs.defaultProps = {
  pligsKey: 'pligs',
};

PromotionEditPligs.propTypes = {
  pligsKey: PropTypes.string,
};

export default PromotionEditPligs;
