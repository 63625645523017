import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useAction } from 'app/hooks';
import { selectors as authSelectors } from 'ducks/auth';
import { actions as clientsActions, selectors as clientsSelectors } from 'ducks/clients';
import { Breadcrumbs } from 'components';
import BalanceEdit from 'components/clients/BalanceEdit/BalanceEdit';
import ClientDeactivate from 'components/clients/ClientDeactivate/ClientDeactivate';
import DeletePersonalData from 'components/clients/DeletePersonalData/DeletePersonalData';
import ClientProfile from './ClientProfile/ClientProfile';
import { LoyaltyInfo } from './LoyaltyInfo';
import { BalanceHistory } from './BalanceHistory';

const ClientView = memo((props) => {
  const {
    match: {
      params: { id },
    },
  } = props;
  const isAppAdmin = useSelector(authSelectors.isAppAdmin);
  const fetchClient = useAction(clientsActions.fetchClient);
  const client = useSelector(clientsSelectors.viewingClient);
  const isSuperAdmin = useSelector(authSelectors.isSuperAdmin);
  const canDeactivate = isAppAdmin && client.isUserRelated && !client.user.expired;

  useEffect(() => {
    fetchClient(id);
  }, [id, fetchClient]);

  return (
    <>
      <Breadcrumbs />
      <ClientProfile client={client} />
      <LoyaltyInfo client={client} />
      <BalanceHistory />
      {!client.isDeleted && <BalanceEdit client={client} />}
      {isSuperAdmin && !client.isDeleted && <DeletePersonalData client={client} />}
      {canDeactivate && !client.isDeleted && <ClientDeactivate client={client} />}
    </>
  );
});

ClientView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default ClientView;
