import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useAction } from 'app/hooks';
import { formatDateTime, formatEuro } from 'app/formatters';
import { joinNotEmpty } from 'app/utils';
import {
  actions as transactionsActions,
  selectors as transactionsSelectors,
} from 'ducks/transactions';
import { PropertyRow, TextHeader } from 'components';
import BalanceRow from './BalanceRow';
import TransactionItems from './TransactionItems';
import Adjustments from './Adjustments';

const TransactionInfo = memo((props) => {
  const { transactionId, entry } = props;
  const fetchTransaction = useAction(transactionsActions.fetchTransaction);
  const transaction = useSelector(transactionsSelectors.viewingTransaction);
  const { store = {}, pos = {}, client = {}, card = {} } = transaction;
  const date = entry ? entry.item.createdAt : transaction.completedAt;

  useEffect(() => {
    fetchTransaction(transactionId);
  }, [transactionId, fetchTransaction]);

  return (
    <>
      <PropertyRow label="stores:store">{store.name}</PropertyRow>
      <PropertyRow label="stores:posNumber">{pos.number}</PropertyRow>
      <PropertyRow label="app:date">{formatDateTime(date)}</PropertyRow>
      <PropertyRow label="clients:client">
        {joinNotEmpty([client.firstName, client.lastName])}
      </PropertyRow>
      <PropertyRow label="clients:cardNumber">{card.number}</PropertyRow>
      <PropertyRow label="app:amount">{formatEuro(transaction.total)}</PropertyRow>
      {entry && <BalanceRow entry={entry} />}
      <TextHeader align="center" text="transactions:listOfArticles" />
      <TransactionItems items={transaction.items} transactionTotal={transaction.total} />
      <TextHeader align="center" text="campaigns:immediateDiscounts" />
      <Adjustments items={transaction.items} neutral={false} />
      <TextHeader align="center" text="transactions:accumulationOnLoyaltyAccount" />
      <Adjustments items={transaction.items} neutral />
    </>
  );
});

TransactionInfo.propTypes = {
  transactionId: PropTypes.number.isRequired,
  entry: PropTypes.object,
};

TransactionInfo.defaultProps = {
  entry: undefined,
};

export default TransactionInfo;
