export const MODAL = 'Modal';
export const CONFIRMATION_MODAL = 'ConfirmationModal';
export const CHANGE_PASSWORD_MODAL = 'ChangePasswordModal';
export const RESET_PASSWORD_MODAL = 'ResetPasswordModal';
export const BEST_CLIENTS_MODAL = 'BestClientsModal';
export const ONLINE_PERCENTAGE_MODAL = 'OnlinePercentageModal';
export const GENERATE_REPORT_MODAL = 'GenerateReportModal';
export const CHANGE_CLIENT_EMAIL_MODAL = 'ChangeClientEmailModal';
export const UPDATE_BALANCE_MODAL = 'UpdateBalanceModal';
export const UPLOAD_CAMPAIGN_MODAL = 'UploadCampaignFileModal';
export const CREATE_CAMPAIGN_MODAL = 'CreateCampaignModal';
export const PROMO_TYPE_SELECT_MODAL = 'PromotionTypeSelectModal';
export const UPLOAD_CARDS_MODAL = 'UploadCardsFileModal';
export const UPLOAD_CARDS_FOR_STORE_MODAL = 'UploadCardsFileForStoreModal';
export const SUBTRACT_EXPIRED_BONUSES_MODAL = 'SubtractExpiredBonusesModal';
export const ADD_ANOTHER_ONE_ADMIN_FOR_STORE_MODAL = 'AddAnotherOneAdminForStoreModal';
