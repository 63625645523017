import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Form } from 'formik';

import {
  Paper,
  StoreNumberActField,
  CardNumbersField,
  PromotionIdField,
  QuantityField,
  SaveButton,
} from 'components';

const CouponForm = memo(() => {
  return (
    <Paper>
      <Form>
        <StoreNumberActField />
        <CardNumbersField />
        <PromotionIdField />
        <QuantityField />
        <SaveButton />
      </Form>
    </Paper>
  );
});

const EnhancedCouponForm = withFormik({
  displayName: 'CouponForm',
  validationSchema: ({ validationSchema }) => validationSchema,
  enableReinitialize: true,
  validateOnBlur: false,
  mapPropsToValues: ({ promotionId = '' }) => ({
    storeNumber: '',
    cardNumbers: '',
    promotionId,
    quantity: '1',
  }),
  handleSubmit: (values, { props: { onSubmit } }) => {
    onSubmit(values);
  },
})(CouponForm);

EnhancedCouponForm.propTypes = {
  validationSchema: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  promotionId: PropTypes.string,
};

EnhancedCouponForm.defaultProps = {
  promotionId: undefined,
};

export default EnhancedCouponForm;
