export const FETCH_PRODUCT_LIST = 'products/FETCH_PRODUCT_LIST';
export const FETCH_PRODUCT_LIST_SUCCESS = 'products/FETCH_PRODUCT_LIST_SUCCESS';

export const FETCH_PRODUCT = 'products/FETCH_PRODUCT';
export const FETCH_PRODUCT_SUCCESS = 'products/FETCH_PRODUCT_SUCCESS';

export const FETCH_EAN_OPTIONS = 'products/FETCH_EAN_OPTIONS';
export const FETCH_EAN_OPTIONS_SUCCESS = 'products/FETCH_EAN_OPTIONS_SUCCESS';

export const FETCH_PLIG_OPTIONS = 'products/FETCH_PLIG_OPTIONS';
export const FETCH_PLIG_OPTIONS_SUCCESS = 'products/FETCH_PLIG_OPTIONS_SUCCESS';

export const UPDATE_EANS = 'products/UPDATE_EANS';
