import { all } from 'redux-saga/effects';
import { sagas as appSagas } from 'ducks/app';
import { sagas as authSagas } from 'ducks/auth';
import { sagas as clientsSagas } from 'ducks/clients';
import { sagas as adminsSagas } from 'ducks/admins';
import { sagas as storesSagas } from 'ducks/stores';
import { sagas as campaignsSagas } from 'ducks/campaigns';
import { sagas as productsSagas } from 'ducks/products';
import { sagas as transactionsSagas } from 'ducks/transactions';
import { sagas as couponsSagas } from 'ducks/coupons';

export default function* () {
  yield all([
    appSagas(),
    authSagas(),
    clientsSagas(),
    adminsSagas(),
    storesSagas(),
    campaignsSagas(),
    productsSagas(),
    transactionsSagas(),
    couponsSagas(),
  ]);
}
