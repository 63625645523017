import axios from 'axios';

import * as mappers from './serviceMappers';

export function fetchProductList(params) {
  return axios
    .get('pligs/search', { params: mappers.mapFetchProductListRequest(params) })
    .then((response) => mappers.mapFetchProductListResponse(response.data));
}

export function fetchProduct(id) {
  const scope = 'plig_full,ean_full,product_full';
  return axios
    .get(`pligs/${id}`, { params: { scope } })
    .then((response) => mappers.mapFetchProductResponse(response.data));
}

export function fetchEans(params) {
  return axios
    .get('eans/search', { params: mappers.mapFetchEansRequest(params) })
    .then((response) => mappers.mapFetchEansResponse(response.data));
}

export function updateEans(id, data) {
  return axios
    .patch(`pligs/${id}`, mappers.mapUpdateEansRequest(data))
    .catch(mappers.mapUpdateEansError);
}
