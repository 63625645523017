import { mapAccount } from 'ducks/clients/entityMappers';
import { mapPromotion } from 'ducks/campaigns/entityMappers';

export const mapCoupon = (data) => {
  const { id, code, isCashed, usableAt, expiresAt, account = {}, promotion = {} } = data;
  return {
    id,
    barcode: code,
    isCashed,
    usableAt,
    expiresAt,
    account: mapAccount(account),
    promotion: mapPromotion(promotion),
  };
};
