import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import paths from 'app/paths';
import { useAction, usePushAction } from 'app/hooks';
import { selectors as authSelectors } from 'ducks/auth';
import { actions as adminsActions, selectors as adminsSelectors } from 'ducks/admins';
import { List, Breadcrumbs } from 'components';
import AdminFilter from './AdminFilter';
import AdminRoleCell from './AdminRoleCell';
import AdminActionsCell from './AdminActionsCell';

const rowConfig = [
  {
    name: 'id',
    label: 'app:id',
    source: 'id',
  },
  {
    name: 'username',
    label: 'app:username',
    source: 'username',
    sortable: true,
  },
  {
    name: 'email',
    label: 'auth:email',
    source: 'email',
    sortable: true,
  },
  {
    name: 'roles',
    label: 'auth:role',
    component: AdminRoleCell,
    sortable: true,
  },
  {
    name: 'actions',
    label: 'app:actions',
    component: AdminActionsCell,
  },
];

const AdminList = memo(() => {
  const isSuperAdmin = useSelector(authSelectors.isSuperAdmin);
  const { items, meta } = useSelector(adminsSelectors.adminList);
  const fetchAdminList = useAction(adminsActions.fetchAdminList);
  const push = usePushAction();

  const toolbarActions = useMemo(() => {
    return [
      {
        name: 'add',
        label: 'app:add',
        icon: AddCircleIcon,
        show: isSuperAdmin,
        confirm: false,
        callback: () => push(paths.adminsAdd.path),
      },
    ];
  }, [isSuperAdmin, push]);

  return (
    <>
      <Breadcrumbs />
      <List
        data={items}
        meta={meta}
        onFetchData={fetchAdminList}
        filterComponent={AdminFilter}
        rowConfig={rowConfig}
        toolbarActions={toolbarActions}
      />
    </>
  );
});

export default AdminList;
