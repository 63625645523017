import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { useAction } from 'app/hooks';
import { actions, selectors } from 'ducks/app';
import useStyles from './Notification.styles';

const Notification = memo(() => {
  const classes = useStyles();
  const { type, message } = useSelector(selectors.notification);
  const clearNotification = useAction(actions.clearNotification);
  const { t } = useTranslation();

  const handleClose = useCallback(
    (event, reason) => {
      if (reason !== 'clickaway') {
        clearNotification();
      }
    },
    [clearNotification],
  );

  return (
    <Snackbar
      className={classes.root}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={!!message}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <SnackbarContent
        className={classes[type]}
        message={t(message)}
        action={[
          <IconButton key="close" color="inherit" className={classes.close} onClick={handleClose}>
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
});

export default Notification;
