import React from 'react';
import PropTypes from 'prop-types';
import { Form, useFormik, FormikProvider } from 'formik';

import paths from 'app/paths';
import { actions } from 'ducks/campaigns';
import { useAction } from 'app/hooks';
import { RedirectButton, SaveButton } from 'components/shared';

import { PromotionEditGeneral } from '../PromotionEdit';
import PromotionSpecialFields from './PromotionSpecialFields';
import PromotionSpecialRules from './PromotionSpecialRules';
import { validationSchema } from './specialValidation';

const PromotionCreateSpecial = function ({ promotion }) {
  const createSpecialPromotion = useAction(actions.createSpecialPromotion);

  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      campaign: promotion.id,
      pligs: { 0: [] },
    },
    onSubmit: createSpecialPromotion,
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <PromotionEditGeneral promotion={promotion} />
        <PromotionSpecialRules promotion={promotion} />
        <PromotionSpecialFields />
        <SaveButton label="app:create" />
        <RedirectButton marginLeft label="app:cancel" path={paths.promotions.path} />
      </Form>
    </FormikProvider>
  );
};

PromotionCreateSpecial.propTypes = {
  promotion: PropTypes.object.isRequired,
};

export default React.memo(PromotionCreateSpecial);
