import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import RemoveButton from 'components/shared/buttons/RemoveButton';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { EditInlineData } from 'components/stores/StoreView/EditInlineData';
import { EditButton } from 'components/shared';
import CheckButton from 'components/shared/buttons/CheckButton';
import { actions } from 'ducks/stores';
import { useAction } from 'app/hooks';
import moment from 'moment';

const useStyles = makeStyles({
  rootRow: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    '&>*[disabled]': {
      visibility: 'hidden',
    },
  },
  column: {
    minWidth: 300,
    '&>strong': {
      textTransform: 'capitalize',
      fontSize: 14,
    },
    '&>*:last-child': {
      marginTop: 5,
    },
  },
  buttonsBlock: {
    minWidth: 100,
  },
});

const getPermissionsFromValue = ({ startsAt, endsAt, id }) => {
  const currentDate = new Date().getTime();

  let isEditableStart;
  let isEditableEnd;
  let isDisabledRemoveButton;

  if (!id) {
    isEditableStart = true;
    isEditableEnd = true;
    isDisabledRemoveButton = false;
  } else if (startsAt.getTime() < currentDate && endsAt.getTime() < currentDate) {
    isEditableStart = false;
    isEditableEnd = false;
    isDisabledRemoveButton = true;
  } else if (startsAt.getTime() < currentDate) {
    isEditableStart = false;
    isEditableEnd = true;
    isDisabledRemoveButton = true;
  } else {
    isEditableStart = true;
    isEditableEnd = true;
    isDisabledRemoveButton = false;
  }

  return { isEditableStart, isEditableEnd, isDisabledRemoveButton };
};

export const LoyaltyDisablePeriod = memo(({ id, onDelete, value, errors, isNew, storeId }) => {
  const [isEditMode, setIsEditMode] = useState(isNew);
  const { isEditableStart, isEditableEnd, isDisabledRemoveButton } = getPermissionsFromValue(value);
  const createPeriod = useAction(actions.createLoyaltyDisabledPeriod);
  const updatePeriod = useAction(actions.updateLoyaltyDisabledPeriod);
  const removePeriod = useAction(actions.removeLoyaltyDisabledPeriod);
  const { t } = useTranslation();
  const styles = useStyles();

  const { startsAt, endsAt, id: periodId } = value;

  const handleOnClick = () => setIsEditMode((prevIsEdit) => !prevIsEdit);

  const handleOnSubmit = () => {
    handleOnClick();

    if (isNew) {
      createPeriod({
        startsAt: moment(startsAt).format('YYYY-MM-DD'),
        endsAt: moment(endsAt).format('YYYY-MM-DD'),
        storeId,
      });
    } else {
      updatePeriod({
        startsAt: moment(startsAt).format('YYYY-MM-DD'),
        endsAt: moment(endsAt).format('YYYY-MM-DD'),
        periodId,
      });
    }
  };

  const handleOnDelete = () => {
    if (!isNew) {
      removePeriod(periodId);
    }
    onDelete();
  };

  const isSubmitDisabled = () => {
    if (errors.commonError) {
      return true;
    }

    if (isNew) {
      if (errors?.loyaltyPeriods && errors.loyaltyPeriods[id]) {
        return true;
      }
    } else if (errors?.loyaltyPeriods && errors.loyaltyPeriods[id]?.endsAt) return true;

    return false;
  };

  return (
    <div className={styles.rootRow}>
      <div className={styles.column}>
        <strong>{t(`stores:startAt`)}</strong>
        <br />
        <EditInlineData isEditMode={isEditMode} isEditable={isEditableStart} isStart index={id} />
      </div>
      <div className={styles.column}>
        <strong>{t(`stores:endAt`)}</strong>
        <br />
        <EditInlineData
          isEditMode={isEditMode}
          isEditable={isEditableEnd}
          isStart={false}
          index={id}
        />
      </div>
      <div className={styles.buttonsBlock}>
        {isEditMode && <CheckButton onClick={handleOnSubmit} disabled={isSubmitDisabled()} />}
        {!isEditMode && (isEditableStart || isEditableEnd) && (
          <EditButton onClick={handleOnClick} />
        )}
        {!isDisabledRemoveButton && (
          <RemoveButton disabled={isDisabledRemoveButton} onClick={handleOnDelete} />
        )}
      </div>
    </div>
  );
});

LoyaltyDisablePeriod.propTypes = {
  onDelete: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isNew: PropTypes.bool,
  errors: PropTypes.object.isRequired,
  storeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

LoyaltyDisablePeriod.defaultProps = {
  isNew: true,
  storeId: '',
};
