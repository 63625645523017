import * as actionTypes from './actionTypes';

export const fetchAdminList = (params) => ({
  type: actionTypes.FETCH_ADMIN_LIST,
  params,
});

export const fetchAdminListSuccess = (data) => ({
  type: actionTypes.FETCH_ADMIN_LIST_SUCCESS,
  data,
});

export const removeAdmin = (params, onRemoved) => ({
  type: actionTypes.REMOVE_ADMIN,
  params,
  onRemoved,
});

export const removeAdminSuccess = () => ({
  type: actionTypes.REMOVE_ADMIN_SUCCESS,
});

export const createAdmin = (data) => ({
  type: actionTypes.CREATE_ADMIN,
  data,
});

export const createAdminSuccess = () => ({
  type: actionTypes.CREATE_ADMIN_SUCCESS,
});

export const fetchAdmin = (id) => ({
  type: actionTypes.FETCH_ADMIN,
  id,
});

export const fetchAdminSuccess = (data) => ({
  type: actionTypes.FETCH_ADMIN_SUCCESS,
  data,
});

export const updateAdmin = (id, data) => ({
  type: actionTypes.UPDATE_ADMIN,
  id,
  data,
});

export const fetchAdminGroups = (id) => ({
  type: actionTypes.FETCH_ADMIN_GROUPS,
  id,
});

export const fetchAdminGroupsSuccess = (data) => ({
  type: actionTypes.FETCH_ADMIN_GROUPS_SUCCESS,
  data,
});

export const restoreAdmin = (params) => ({
  type: actionTypes.RESTORE_ADMIN,
  params,
});

export const restoreAdminSuccess = (data) => ({
  type: actionTypes.RESTORE_ADMIN_SUCCESS,
  data,
});
