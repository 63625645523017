import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useAction, useListContext } from 'app/hooks';
import { actions as modalsActions, modalTypes } from 'ducks/modals';

const ManipulationRowAction = memo((props) => {
  const { component: Component, componentProps, action, params, confirm, toFirstPage } = props;
  const showModal = useAction(modalsActions.showModal);
  const performAction = useAction(action);
  const { refreshData, forceFirstPage } = useListContext();
  const callback = toFirstPage ? forceFirstPage : refreshData;

  const preparedAction = useCallback(() => {
    performAction(params, callback);
  }, [performAction, params, callback]);

  const onClick = useCallback(() => {
    if (confirm) {
      showModal(modalTypes.CONFIRMATION_MODAL, {
        onOk: () => preparedAction(),
      });
    } else {
      preparedAction();
    }
  }, [confirm, showModal, preparedAction]);

  return <Component onClick={onClick} size="small" {...componentProps} />;
});

ManipulationRowAction.propTypes = {
  component: PropTypes.object.isRequired,
  componentProps: PropTypes.object,
  action: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  confirm: PropTypes.bool,
  toFirstPage: PropTypes.bool,
};

ManipulationRowAction.defaultProps = {
  componentProps: {},
  confirm: true,
  toFirstPage: false,
};

export default ManipulationRowAction;
