const prepareData = (rows, columnNames, rowHandler) => {
  const data = [];
  if (columnNames) {
    data.push(columnNames.join(';'));
  }
  for (const row of rows) {
    const formattedRow = rowHandler ? rowHandler(row) : row;
    data.push(formattedRow.join(';'));
  }
  return data.join('\n');
};

const exportPreparedData = (filename, dataString) => {
  const bom = '\ufeff';
  const file = new Blob([[bom + dataString]], { type: 'application/csv' });
  const a = document.createElement('a');
  a.href = URL.createObjectURL(file);
  a.target = '_blank';
  a.download = `${filename}.csv`;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

const exportRawData = (filename, rows, columnNames, rowHandler) => {
  exportPreparedData(filename, prepareData(rows, columnNames, rowHandler));
};

export default {
  prepareData,
  exportPreparedData,
  exportRawData,
};
