import { fromJS } from 'immutable';

import * as actionTypes from './actionTypes';

const initialState = fromJS({
  productList: {
    order: [],
    items: {},
    meta: { page: 1, limit: 10, total: 0 },
  },
  viewingProduct: {},
  eanOptions: [],
  pligOptions: {},
});

export default (state = initialState, { type, data, id }) => {
  switch (type) {
    case actionTypes.FETCH_PRODUCT_LIST_SUCCESS:
      return state.merge(
        fromJS({
          productList: data,
        }),
      );

    case actionTypes.FETCH_PRODUCT_SUCCESS:
      return state.merge(
        fromJS({
          viewingProduct: data,
        }),
      );

    case actionTypes.FETCH_EAN_OPTIONS_SUCCESS:
      return state.merge(
        fromJS({
          eanOptions: data,
        }),
      );

    case actionTypes.FETCH_PLIG_OPTIONS_SUCCESS:
      return state.mergeIn(
        ['pligOptions'],
        fromJS({
          [id]: data,
        }),
      );

    default:
      return state;
  }
};
