import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';

const ViewButton = memo(({ title, ...rest }) => {
  const { t } = useTranslation();
  return (
    <IconButton title={t(title)} {...rest}>
      <VisibilityIcon />
    </IconButton>
  );
});

ViewButton.propTypes = {
  ...IconButton.propTypes,
};

ViewButton.defaultProps = {
  ...IconButton.defaultProps,
  title: 'app:view',
};

export default ViewButton;
