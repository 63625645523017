import React, { memo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import map from 'lodash/map';

import { useAction } from 'app/hooks';
import { formatDateTime, formatEuro } from 'app/formatters';
import { actions as productsActions, selectors as productsSelectors } from 'ducks/products';
import { Breadcrumbs, Panel, PropertyRow } from 'components';
import ProductEans from './ProductEans';

const ProductView = memo(
  ({
    match: {
      params: { id },
    },
  }) => {
    const fetchProduct = useAction(productsActions.fetchProduct);
    const product = useSelector(productsSelectors.viewingProduct);

    useEffect(() => {
      fetchProduct(id);
    }, [id, fetchProduct]);

    const renderReferencePrice = useCallback((data) => {
      const {
        id: priceId,
        priceCategory: { label },
        value: amount,
      } = data;
      return (
        <PropertyRow key={priceId} label={label}>
          {formatEuro(amount)}
        </PropertyRow>
      );
    }, []);

    return (
      <>
        <Breadcrumbs />
        <Panel title="app:general" defaultExpanded>
          <PropertyRow label="products:plig">{product.value}</PropertyRow>
          <PropertyRow label="app:label">{product.label}</PropertyRow>
          <PropertyRow label="app:lastModification">
            {formatDateTime(product.updatedAt)}
          </PropertyRow>
        </Panel>
        <Panel title="products:eans" defaultExpanded>
          <ProductEans productId={product.id} productEans={product.eans} />
        </Panel>
        <Panel title="products:referencePrices" defaultExpanded>
          {map(product.referencePrices, renderReferencePrice)}
        </Panel>
      </>
    );
  },
);

ProductView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default ProductView;
