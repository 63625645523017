import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: 40,
    height: 40,
    position: 'fixed',
    top: '50%',
    left: '50%',
    marginTop: -20,
    marginLeft: -20,
    zIndex: 2000,
  },
  first: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    backgroundColor: '#333',
    opacity: '0.6',
    position: 'absolute',
    top: 0,
    left: 0,
    animation: '$bounce 2.0s infinite ease-in-out',
  },
  second: {
    animationDelay: '-1.0s',
  },
  '@keyframes bounce': {
    '0%, 100%': {
      transform: 'scale(0.0)',
    },
    '50%': {
      transform: 'scale(1.0)',
    },
  },
}));

export default useStyles;
