import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Form } from 'formik';

import { STORE_ADMIN } from 'app/roles';
import { Paper, UsernameField, EmailField, PasswordField, RoleField, SaveButton } from 'components';
import { AdminGroups } from '../AdminGroups';

const AdminForm = memo(({ admin: { id }, values: { role } }) => {
  return (
    <Paper>
      <Form>
        <UsernameField />
        <EmailField />
        <PasswordField />
        <PasswordField name="passwordConfirmation" label="auth:passwordConfirmation" />
        <RoleField />
        {role === STORE_ADMIN && <AdminGroups adminId={id} />}
        <SaveButton />
      </Form>
    </Paper>
  );
});

AdminForm.propTypes = {
  admin: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};

const EnhancedAdminForm = withFormik({
  displayName: 'AdminForm',
  validationSchema: ({ validationSchema }) => validationSchema,
  enableReinitialize: true,
  validateOnBlur: false,
  mapPropsToValues: ({ admin: { username = '', email = '', roles = [''] } }) => ({
    username,
    email,
    password: '',
    passwordConfirmation: '',
    role: roles[0],
    groups: [],
  }),
  handleSubmit: (values, { props: { onSubmit } }) => {
    onSubmit(values);
  },
})(AdminForm);

EnhancedAdminForm.propTypes = {
  admin: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

EnhancedAdminForm.defaultProps = {
  admin: {},
};

export default EnhancedAdminForm;
