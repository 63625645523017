import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Form } from 'formik';

import { useAction } from 'app/hooks';
import { actions as campaignsActions } from 'ducks/campaigns';
import { promotionFormValidationSchema } from 'ducks/campaigns/validation';
import { mapPromotionToValues } from 'ducks/campaigns/helpers';
import { SaveButton } from 'components';

import PromotionEditGeneral from './PromotionEditGeneral';
import PromotionEditAction from './PromotionEditAction';
import PromotionEditDeactivate from './PromotionEditDeactivate';
import PromotionEditRules from './PromotionEditRules';

const PromotionForm = memo(({ promotion }) => {
  return (
    <Form>
      <PromotionEditGeneral promotion={promotion} />
      <PromotionEditRules promotion={promotion} />
      <PromotionEditAction promotion={promotion} />
      <PromotionEditDeactivate />
      <SaveButton />
    </Form>
  );
});

PromotionForm.propTypes = {
  promotion: PropTypes.object.isRequired,
};

const EnhancedPromotionForm = withFormik({
  displayName: 'PromotionForm',
  validationSchema: promotionFormValidationSchema,
  enableReinitialize: true,
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues: mapPromotionToValues,
  handleSubmit: (values, { props }) => {
    const { onSubmit, promotion } = props;
    onSubmit(promotion.id, values);
  },
})(PromotionForm);

EnhancedPromotionForm.propTypes = {
  promotion: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const PromotionEdit = memo((props) => {
  const updatePromotion = useAction(campaignsActions.updatePromotion);
  return <EnhancedPromotionForm {...props} onSubmit={updatePromotion} />;
});

PromotionEdit.propTypes = {
  promotion: PropTypes.object.isRequired,
};

export default PromotionEdit;
