import PropTypes from 'prop-types';
import React from 'react';

import { getRuleName } from 'ducks/campaigns/helpers';

import { promotionRuleTypes } from 'ducks/campaigns/enums';
import { Panel, Caption } from 'components/index';

import { PromotionEditPligs } from '../PromotionEdit';

const PromotionEditRules = function ({ promotion }) {
  return (
    <>
      <Panel title="campaigns:rules" defaultExpanded>
        <Caption text={getRuleName(promotion.rules[promotionRuleTypes.CART_HAS_ITEMS])} />
        <PromotionEditPligs promotion={promotion} />
      </Panel>
    </>
  );
};

PromotionEditRules.propTypes = {
  promotion: PropTypes.object.isRequired,
};

export default React.memo(PromotionEditRules);
